import React, { Component } from 'react';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import '../Assets/css/Addcollege.css';
import axios from 'axios';
import { Redirect } from 'react-router';
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";

export default class LDPInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            college_code: localStorage.getItem("collegeCode") ? localStorage.getItem("collegeCode") : "",
            ldp_start_date: '',
            ldp_end_date: '',
            ldp_start_time: '',
            ldp_end_time: '',
            ldp_status: '',
            startTime: "12:00:00",
            endTime: "12:00:00",
            showTime: true,
            showEndTime: true,
            changeToView: false,
            errors: {},
            multipleLdp: localStorage.getItem("multipleLDP") === "1" ? localStorage.getItem("multipleLDP") : 0,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setLDPStartTime = this.setLDPStartTime.bind(this);
        this.setShowLDPStartTime = this.setShowLDPStartTime.bind(this);
        this.setLDPEndTime = this.setLDPEndTime.bind(this);
        this.setShowLDPEndTime = this.setShowLDPEndTime.bind(this);
        this.getAllData = this.getAllData.bind(this);
    };

    UNSAFE_componentWillMount() {
        let CollegeCode = localStorage.getItem("collegeCode");
        this.getAllData(CollegeCode);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    //get ldp information e.g.ldp start date,end date,start time,end time
    getAllData(college_code) {
        //axios.get(process.env.REACT_APP_APIURL + `/college/college_code/COLPUNE000`)
        axios.get(process.env.REACT_APP_APIURL + `/college/college_code/` + this.state.college_code)
            .then(res => {
                let LDPData = res.data;
                this.setState({
                    college_code: LDPData[0].college_code,
                    ldp_start_date: LDPData[0].ldp_start_date,
                    ldp_end_date: LDPData[0].ldp_end_date,
                    ldp_start_time: LDPData[0].ldp_start_time,
                    ldp_end_time: LDPData[0].ldp_end_time,
                    ldp_status: LDPData[0].ldp_status
                });

            });
    }

    //code for form validation
    handleFormValidation = () => {
        const { ldp_start_date, ldp_end_date, ldp_start_time, ldp_end_time } = this.state;
        let errors = {};
        let formIsValid = true;


        if (!ldp_start_date) {
            formIsValid = false;
            errors["ldp_start_date"] = "*Please Enter Ldp Start Date.";
        }
        if (!ldp_end_date) {
            formIsValid = false;
            errors["ldp_end_date"] = "*Please Enter Ldp End Date.";
        }
        if (!ldp_start_time) {
            formIsValid = false;
            errors["ldp_start_time"] = "*Please Enter Ldp Start Time.";
        }
        if (!ldp_end_time) {
            formIsValid = false;
            errors["ldp_end_time"] = "*Please Enter Ldp End Time.";
        }

        this.setState({
            errors: errors
        });
        return formIsValid;

    }

    //save form data
    handleSubmit(event) {
        event.preventDefault();
        if (this.handleFormValidation()) {
            let a = localStorage.getItem("collegeCode");
            let collegeCodes = "";

            let formData = new FormData();
            formData.append("college_code", a.split(","));
            formData.append("ldp_start_date", this.state.ldp_start_date);
            formData.append("ldp_end_date", this.state.ldp_end_date);
            formData.append("ldp_start_time", this.state.ldp_start_time);
            formData.append("ldp_end_time", this.state.ldp_end_time);
            formData.append("ldp_status", this.state.ldp_status);

            let statesSet = this;
            //post vendor data

            axios.post(process.env.REACT_APP_APIURL + `/coordinator/election/ldp/control`, formData)
                .then(res => {
                    if (res.status === 200) {
                        alert('ldp information added successfully.')

                        statesSet.setState({
                            changeToView: true,
                        })
                    }
                })
        }
    }

    setLDPStartTime(newTimes) {
        this.setState({
            time: newTimes,
            ldp_start_time: newTimes
        })
    }

    setShowLDPStartTime(show) {
        this.setState({
            showTime: show
        })
    }

    setLDPEndTime(newTimes) {
        this.setState({
            endTime: newTimes,
            ldp_end_time: newTimes
        })
    }

    setShowLDPEndTime(show) {
        this.setState({
            showEndTime: show
        })
    }

    render() {
        if (this.state.changeToView) {
            return <Redirect to="/view-card" />;
        }
        return (
            <>
                <Sidebar />
                <div className="main">
                    <div className="topbar">
                        <Navbar />
                    </div>
                    <div className="container">
                        {this.state.multipleLdp ? <BackAndBreadCrmOneParent backLink="/collegeList" currentTabName="LDP" /> : <BackAndBreadCrmOneParent backLink="/view-card" backName="View card" currentTabName="LDP" />}

                        <div className="row mt-1">
                            <div className="col-12 col-md-5 m-auto">
                                <div className="card rounded shadow shadow-sm">
                                    <div className="card-header">
                                        <h3 className="mb-0">LDP Information</h3>
                                    </div>
                                    <div className="card-body">
                                        <form id="formElection" onSubmit={this.handleSubmit}>

                                            <div className="form-group electionCollegeCodeDiv">
                                                <input type="hidden" id="college_code" name="college_code" value={this.state.college_code} className="form-control rounded-1" placeholder="Enter Election Start Date" />
                                            </div>

                                            <div className="form-group electionStartDateDiv">
                                                <label htmlFor="ldp_start_date" className="control-label">LDP Start Date</label>
                                                <input type="date" id="ldp_start_date" name="ldp_start_date" value={this.state.ldp_start_date} onChange={this.handleChange} className="form-control rounded-1" placeholder="Enter LDP Start Date" />
                                                <div className="errorMsg">{this.state.errors.ldp_start_date}</div>
                                            </div>
                                            <div className="form-group electionEndDateDiv">
                                                <label htmlFor="ldp_end_date" className="control-label">LDP End Date</label>
                                                <input type="date" id="ldp_end_date" name="ldp_end_date" value={this.state.ldp_end_date} onChange={this.handleChange} className="form-control rounded-1" placeholder="Enter LDP End Date" />
                                                <div className="errorMsg">{this.state.errors.ldp_end_date}</div>
                                            </div>


                                            <div className="form-group electionEndDateDiv">
                                                {/* {this.state.showTime &&
                                                        <Timekeeper
                                                            time={this.state.time}
                                                            onChange={(newTime) => this.setElectionStartTime(newTime.formatted24)}
                                                            onDoneClick={() => this.setShowElectionStartTime(false)}
                                                            switchToMinuteOnHourSelect
                                                            hour24Mode
                                                        />
                                                    }
                                                    <span>Election Start Time is {this.state.time} </span>
                                                    {!this.state.showTime &&
                                                        <button onClick={() => this.setShowElectionStartTime(true)} className="btn btn-sm btn-primary"> Show Clock</button>
                                                    } */}
                                                <label htmlFor="ldp_start_time" className="control-label">LDP Start Time</label>
                                                <input type="text" id="ldp_start_time" name="ldp_start_time" value={this.state.ldp_start_time} onChange={this.handleChange} className="form-control rounded-1" placeholder="Enter LDP Start Time" />
                                                <small>Ex. 18:30:00</small>
                                                <div className="errorMsg">{this.state.errors.ldp_start_time}</div>
                                            </div>


                                            <div className="form-group electionEndDateDiv">
                                                {/* {this.state.showEndTime &&
                                                        <Timekeeper
                                                            endTime={this.state.endTime}
                                                            onChange={(newTime) => this.setElectionEndTime(newTime.formatted24)}
                                                            onDoneClick={() => this.setShowElectionEndTime(false)}
                                                            switchToMinuteOnHourSelect
                                                            hour24Mode
                                                        />
                                                    }
                                                    <span>Election End Time is {this.state.endTime} </span>
                                                    {!this.state.showEndTime &&
                                                        <button onClick={() => this.setShowElectionEndTime(true)} className="btn btn-sm btn-primary"> Show Clock</button>
                                                    } */}
                                                <label htmlFor="ldp_end_time" className="control-label">LDP End Time</label>
                                                <input type="text" id="ldp_end_time" name="ldp_end_time" value={this.state.ldp_end_time} onChange={this.handleChange} className="form-control rounded-1" placeholder="Enter LDP end Time" />
                                                <small>Ex. 18:30:00</small>
                                                <div className="errorMsg">{this.state.errors.ldp_end_time}</div>
                                            </div>


                                            {/* <div className="form-group electionStartTimeDiv">
                                                <label htmlFor="ElectionStartTime" className="control-label">Election Start Time</label>
                                                <input type="text" id="ElectionStartTime" name="election_start_time" onChange={this.handleChange} className="form-control rounded-1" placeholder="9:30" />
                                            </div> */}



                                            {/* <div className="form-group electionEndTimeDiv">
                                                <label htmlFor="Election End Time" className="control-label">Election End Time</label>
                                                <input type="text" id="ElectionEndTime" name="election_end_time" onChange={this.handleChange} className="form-control rounded-1" placeholder="18.30" />
                                            </div> */}

                                            <div className="form-group electionStatusDiv">
                                                <label htmlFor="LDP Status" className="control-label">LDP Status</label>
                                                <select type="button" className="form-control  rounded-1" onChange={this.handleChange} name="ldp_status">
                                                    <option value={null}> -- Select-- </option>
                                                    <option value="1">TRUE</option>
                                                    <option value="0">FALSE</option>
                                                </select>
                                            </div>
                                            <button type="submit" id="btnSubmitElection" className="btn btn-primary btn-md btn-block mt-4 btnElection">Submit</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
