import axios from 'axios';
import React, { useState } from 'react';
import "../Assets/css/Operator.css";
export const OperatorLogin = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleSubmit = async (e) => {
    setError('');
    setSuccess('');
    e.preventDefault();
    let response;
    try {
      response = await axios.post(process.env.REACT_APP_APIURL + '/audit/ops/login', { email, password }).then(r => r.data);
      setSuccess('Login Successful');
      localStorage.setItem('operator', JSON.stringify(response.data));
       window.location.href = "/voter/verify";
      return;
    } catch (e) {
      console.error(e);
      setError(e.message)
      return;
    }
  }

  return (
    <div>
      <div className="sidenavc">
        <div className="login-main-textc">
          <h2>Operator<br/> Login Page</h2>
          <p>Login with your assigned email address and password.</p>
        </div>
      </div>
      <div className="mainc">
        <div className="col-md-6 col-sm-12">
          <div className="login-formc">
            <form onSubmit={handleSubmit}>
              <div className="form-groupc mb-3">
                <label>Email address</label>
                <input required type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" name="email" placeholder="Email address" />
              </div>
              <div className="form-groupc">
                <label>Password</label>
                <input required type="password" value={password} onChange={(e) => setPassword(e.target.value)} name="password" className="form-control" placeholder="Password" />
              </div>
              <div className="text-danger py-3">{error}</div>
              <div className="text-success py-3">{success}</div>
              <div className="mt-3 mr-0 d-flex flex-grow">
                <button type="submit" className="flex-grow btn btn-black">Login</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
