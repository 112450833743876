import React from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { Edit } from '@material-ui/icons';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import candidate_old from "../data/static-candidate";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import LoaderRounded from "./LoaderRounded";
import ReactPaginate from 'react-paginate';

class MergeCollegeList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentFilter: null,
            candidate_old: candidate_old,
            selectValue: "all",
            redirect: false,
            candidate: [],
            collegeCodesForMerge: '',
            mergeError: "",
            loader: false,
            currentPage: '',
            counts: '',
            totalPages: '',
            previousLimits: 100,
            renderPageLimit: 20,
            selectedPage: 0,

        };
        this.getData = this.getData.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.handleMergeCollege = this.handleMergeCollege.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
    }
    handlePageChange(page) {
        this.setState({ selectedPage: page.selected })
    }

    UNSAFE_componentWillMount() {
        this.getData();
    }
    componentWillMount() {

        setTimeout(() => this.setState({
            loader: false, msg: false
        }), 2500);
        setTimeout(() => this.setState({ loading: false }), 1000);


    }

    getData() {
        // let currentComponent = this;
        let a = JSON.parse(localStorage.getItem('mergeCollegeDetails'));
        this.setState({
            candidate: a
        })
        // axios.get(process.env.REACT_APP_APIURL + `/candidate/list/city/`+a)
        //     // axios.get(process.env.REACT_APP_APIURL + `/candidate/list?city=` + city)
        //     .then(res => {
        //         console.log("pagination data", res);
        //         let responses = res.data;
        //         console.log("pagination data responses.response --- ", responses);
        //         currentComponent.setState({
        //             candidate: responses,
        //         });
        //         console.log("candidate list", this.state.candidate);

        //     });
    }

    handleCheckbox(event) {
        this.setState({
            collegeCodesForMerge: event.target.value
        })
    }

    handleMergeCollege() {
        if (this.state.collegeCodesForMerge === "") {
            this.setState({ mergeError: "*Please select correct college to merge" });
        } else {
            this.setState({ mergeError: "" });
            let collegeArray = localStorage.getItem("collegeCode").split(",");
            let indexForDelete = collegeArray.indexOf(this.state.collegeCodesForMerge);
            let afterDeleteArray = collegeArray.splice(indexForDelete, 1);
            let collegecodes = {
                merge_college_code: this.state.collegeCodesForMerge,
                all_college_Code: collegeArray
            }
            axios.post(process.env.REACT_APP_APIURL + `/coordinator/college/merge`, collegecodes)
                .then(res => {
                    let responses = res.data;
                    this.setState({
                        redirect: true,
                    });
                    alert("Successfully merge college!!!")
                }).catch((error) => {
                    console.log("Error", error);
                });
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to="/collegeList" />
        }

        if (!localStorage.getItem("mobile")) {
            return <Redirect to="/login" />
        }

        let candidateList = '';
        if (this.state.candidate.length > 0) {
            candidateList = this.state.candidate.map((candidates, id) => (
                <tr key={id} >
                    <td><input type="radio" name="merge_college" className="form-check-input" onChange={this.handleCheckbox} value={candidates.college_code} /></td>
                    <td>{id + 1}</td>
                    <td>{candidates.college_code}</td>
                    <td>{candidates.college_name}</td>
                    <td>{candidates.city}</td>
                </tr>
            ));
        } else {
            candidateList = "No data found";
        }


        return (
            <>
                <div>
                    <Sidebar />
                    <div className="main">
                        <div className="topbar">
                            <Navbar />
                        </div>

                        <div className="container">
                            <BackAndBreadCrmOneParent backLink="/collegeList" backName="College List" currentTabName="Merge College List" />
                            <h2 className="text-center">Merge College List</h2>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="table-responsive-sm">
                                            <table className="table table-bordered table-responsive">
                                                <thead>
                                                    <tr>
                                                        <th>No</th>
                                                        <th>ID</th>
                                                        <th>College Code</th>
                                                        <th>College Name</th>
                                                        <th>City</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.loader ? <LoaderRounded /> : candidateList.slice(this.state.selectedPage * this.state.renderPageLimit, this.state.renderPageLimit + (this.state.selectedPage * this.state.renderPageLimit))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <ReactPaginate
                                        nextLabel="Next"
                                        previousLabel="Previous"
                                        pageCount={candidateList.length / this.state.renderPageLimit}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        containerClassName="pagination"
                                        activeClassName="btn btn-primary"
                                        pageClassName="btn"
                                        previousClassName="btn"
                                        nextClassName="btn"
                                        onPageChange={this.handlePageChange}
                                    />



                                    <div className="col-12 mt-5">
                                        <div className="text-center">
                                            <div><small className="errorMsg">{this.state.mergeError}</small></div>
                                            <button className="btn btn-warning btn-lg mt-3" onClick={this.handleMergeCollege}>Merge colleges</button>
                                        </div>
                                        <div className="mt-5"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}
export default MergeCollegeList;