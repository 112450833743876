import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../Assets/css/card.css";
import axios from "axios";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import LoaderRounded from "./LoaderRounded";
import LoaderRoundedWithText from "./LoaderRoundedWithText";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Hidden from "@material-ui/core/Hidden";
import makeStyles from "@material-ui/core/styles/makeStyles";

import { TbBuildingCommunity } from "react-icons/tb";
import { FaUserClock } from "react-icons/fa";
import { GoVerified } from "react-icons/go";
import { MdRemoveDone } from "react-icons/md";
import ElectionDetails from "./ElectionDetails";
import { Add } from "@material-ui/icons";
import { Col, Form, Row } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    position: "relative",
    border: "none",
    borderRadius: "15px",
    padding: theme.spacing(1, 2),
    margin: theme.spacing(2),
  },
  title: {
    padding: theme.spacing(1),
    margin: theme.spacing(1, 0),
  },
  persentage: {
    padding: theme.spacing(1),
    // border: "1px solid #000",
    margin: theme.spacing(1),
  },
  detail: {
    padding: theme.spacing(1),
    margin: theme.spacing(2, 0, 2, 0),
  },
  boxIcon: {
    height: "100px",
    width: "100px",
    display: "flex",
    position: "absolute",
    zIndex: 1,
    top: -20,
    left: 20,
    justifyContent: "center",
    alignItems: "center",
  },

  icons: {
    minWidth: 60,
    minHeight: 60,
    color: "#ffffff",
  },

  userBoxColor: {
    background:
      "linear-gradient(60deg, rgba(94,53,177,1) 0%, rgba(3,155,229,1) 100%)",
    padding: 20,
    borderRadius: 25,
  },

  allCollegeBoxColor: {
    background:
      "linear-gradient(60deg, rgba(67,160,71,1) 0%, rgba(255,235,59,1) 100%)",
    padding: 20,
    borderRadius: 25,
  },

  eventBoxColor: {
    background:
      "linear-gradient(60deg, rgba(251,140,0,1) 0%, rgba(255,202,41,1) 100%)",
    padding: 20,
    borderRadius: 25,
  },
  allCandidatesBoxColor: {
    background:
      "linear-gradient(60deg, rgba(34,193,195,1) 0%, rgba(40,162,247,0.9473039215686274) 100%)",
    padding: 20,
    borderRadius: 25,
  },
  newCandidateBoxColor: {
    background:
      "linear-gradient(60deg, rgba(58,79,180,1) 0%, rgba(252,176,69,1) 100%)",
    padding: 20,
    borderRadius: 25,
  },
  rejectedCandidateBoxColor: {
    background:
      "linear-gradient(60deg, rgba(247,40,40,0.9837184873949579) 0%, rgba(255, 87, 87) 64%)",
    padding: 20,
    borderRadius: 25,
  },
  ApproveBoxColor: {
    background:
      "linear-gradient(60deg, rgba(112,177,178,1) 0%, rgba(57,242,10,0.9837184873949579) 64%)",
    padding: 20,
    borderRadius: 25,
  },
}));

export default class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      newCandidate: "",
      allCollege: "",
      approvedCandidate: "",
      pendingCollege: "",
      rejectedCandidate: "",
      allRegistration: "",
      allCandidates: "",
      allVoters: "",
      AllVotes: "",
      role: "",
      AllPhase1Candidate: "",
      AllPhase1User: "",
      AllPhase2Candidate: "",
      AllPhase2User: "",
      AllPhase1Colleges: "",
      AllPhase2Colleges: "",
      AllPhase3Candidate: "",
      AllPhase3User: "",
      AllPhase3Colleges: "",
      loader: true,
      loading: false,
      year: "",
      years: [],
    };
    this.getElectionCountWithYear = this.getElectionCountWithYear.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.getData();
    this.setState({ role: localStorage.getItem("role") });
    // setTimeout(() => this.setState({ loading: false }), 1000);
  }

  // handle year onChange
  getElectionCountWithYear = (event) => {
    this.setState({
      year: event.target.value,
    });
    this.getData(event.target.value);
  };

  // initializeData(selectedYear) {
  //   this.setState({
  //     year: selectedYear,
  //   });

  //   this.getData(selectedYear);
  // }

  // year dropdown code
  componentDidMount() {
    const currentYear = new Date().getFullYear();
    const initialYears = this.generateYears(2021, currentYear + 1);
    this.setState({ years: initialYears });
    // this.getData(this.state.year);
    // const initialSelectedYear = this.state.year || new Date().getFullYear();
    // this.initializeData(initialSelectedYear);
  }

  generateYears(start, end) {
    const yearsArray = [];
    for (let year = end; year >= start; year--) {
      yearsArray.push(year);
    }
    return yearsArray;
  }

  handleCompleteYear = () => {
    const currentYear = new Date().getFullYear();
    this.setState((prevState) => ({
      years: [...prevState.years, currentYear + 1],
    }));
  };

  getData(selectedYear) {
    let currentComponent = this;
    this.setState({
      loader: true,
    });

    let districts = JSON.parse(localStorage.getItem("districts"));
    const payload = {
      districts: districts,
      year: selectedYear ? selectedYear : "",
    };
    axios
      .post(
        process.env.REACT_APP_APIURL + `/coordinator/dashboard/count/election`,
        payload
      )
      .then((res) => {
        let responses = res.data;

        currentComponent.setState({
          newCandidate: responses.pendingCandidate,
          allCollege: responses.approvedCollege,
          approvedCandidate: responses.approvedCandidate,
          pendingCollege: responses.pendingCollege,
          rejectedCandidate: responses.rejectCandidate,
          allRegistration: responses.allRegistration,
          allCandidates: responses.allCandidates,
          allVoters: responses.allVoter,
          AllVotes: responses.AllVote,
          AllPhase1Candidate: responses.AllPhase1Candidate,
          AllPhase1User: responses.AllPhase1User,
          AllPhase2Candidate: responses.AllPhase2Candidate,
          AllPhase2User: responses.AllPhase2User,
          AllPhase1Colleges: responses.AllPhase1Colleges,
          AllPhase2Colleges: responses.AllPhase2Colleges,
          AllPhase3Candidate: responses.AllPhase3Candidate,
          AllPhase3User: responses.AllPhase3User,
          AllPhase3Colleges: responses.AllPhase3Colleges,
          loader: false,
        });
      })
      .catch((error) => {
        this.setState({
          loader: true,
        });
        console.log("error in get dashboard count", error);
      });
  }

  render() {
    return (
      <>
        {this.state.loading ? (
          <LoaderRoundedWithText />
        ) : (
          <div>
            <Sidebar />
            <div className="main">
              <div className="topbar">
                <Navbar title="Election Count" />
              </div>

              <Row md={12} className="mx-4 mt-4">
                <Col
                  md={6}
                  className={`justify-content-end w-100 ${"d-sm-flex"}`}
                >
                  <Col className="me-2" md={2} sm={12}>
                    <select
                      name="year"
                      className="btn dropdown-toggle mb-2 w-100"
                      style={{ backgroundColor: "#003147", color: "white" }}
                      onChange={this.getElectionCountWithYear}
                      value={this.state.year}
                    >
                      <option value="">All Years</option>
                      {this.state.years.map((year) => (
                        <>
                          <option key={year} value={year}>
                            {year}
                          </option>
                        </>
                      ))}
                    </select>
                  </Col>

                  <Col className="" md={2} sm={12}>
                    <Link
                      to="/ElectionDetails"
                      className="btn btn-secondary w-100"
                      style={{ backgroundColor: "#003147", color: "white" }}
                    >
                      <Add className="me-1" />
                      Election Details
                    </Link>
                  </Col>
                </Col>
              </Row>

              <RenderComponent {...this.state} />
              <button
                className="reaload btn btn-success btn-sm mt-3"
                onClick={() => window.location.reload(true)}
              >
                <Icon.ArrowClockwise size={18} className="me-2" />
                Refresh
              </button>
            </div>
          </div>
        )}
      </>
    );
  }
}

function RenderComponent(props) {
  const classes = useStyles();
  return (
    <div style={{ marginTop: 50 }}>
      <Grid container spacing={2}>
        <Grid  item xs={12} sm={12} md={3} lg={3}>
          <Link to="/view-user">
            <Paper className={classes.card}>
              <Grid container>
                <span className={classes.boxIcon}>
                  <Grid className={classes.userBoxColor}>
                    <span className={classes.icons}>
                      <i className="fa fa-users" style={{ fontSize: 60 }}></i>
                    </span>
                  </Grid>
                </span>
                <Grid
                  container
                  className={classes.persentage}
                  justifyContent="flex-end"
                >
                  <Typography variant="h4" style={{ fontWeight: "600" }}>
                    {props.loader ? (
                      <LoaderRounded />
                    ) : (
                      props.allRegistration
                    )}{" "}
                  </Typography>
                </Grid>
              </Grid>
              <hr />
              <Grid container item xs className={classes.title}>
                <Typography>
                  <h5 className="text-dark">All YIN Members</h5>
                </Typography>
              </Grid>
            </Paper>
          </Link>
        </Grid>

        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Link to="/candidateList">
            <Paper className={classes.card}>
              <Grid container>
                <span className={classes.boxIcon}>
                  <Grid className={classes.allCandidatesBoxColor}>
                    <span className={classes.icons}>
                      <i className="fa fa-users" style={{ fontSize: 60 }}></i>
                    </span>
                  </Grid>
                </span>
                <Grid
                  container
                  className={classes.persentage}
                  justifyContent="flex-end"
                >
                  <Typography variant="h4" style={{ fontWeight: "600" }}>
                    {props.loader ? (
                      <LoaderRounded />
                    ) : (
                      props.allCandidates
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <hr />
              <Grid container item xs className={classes.title}>
                <Typography>
                  <h5 className="text-dark">All Candidates</h5>
                </Typography>
              </Grid>
            </Paper>
          </Link>
        </Grid>

        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Link to="/collegeList">
            <Paper className={classes.card}>
              <Grid container>
                <span className={classes.boxIcon}>
                  <Grid className={classes.allCollegeBoxColor}>
                    <span className={classes.icons}>
                      <i>
                        <TbBuildingCommunity
                          style={{
                            fontSize: "60px",
                            color: "white",
                          }}
                        />
                      </i>
                    </span>
                  </Grid>
                </span>

                <Grid
                  container
                  className={classes.persentage}
                  justifyContent="flex-end"
                >
                  <Typography variant="h4" style={{ fontWeight: "600" }}>
                    {props.loader ? <LoaderRounded /> : props.allCollege}{" "}
                  </Typography>
                </Grid>
              </Grid>
              <hr />
              <Grid container item xs className={classes.title}>
                <Typography>
                  <h5 className="text-dark">All Colleges</h5>
                </Typography>
              </Grid>
            </Paper>
          </Link>
        </Grid>

        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Link to="/pending-candidate">
            <Paper className={classes.card}>
              <Grid container>
                <span className={classes.boxIcon}>
                  <Grid className={classes.newCandidateBoxColor}>
                    <span className={classes.icons}>
                      <i>
                        <FaUserClock
                          style={{
                            fontSize: "60px",
                          }}
                        />
                      </i>
                    </span>
                  </Grid>
                </span>
                <Grid
                  container
                  className={classes.persentage}
                  justifyContent="flex-end"
                >
                  <Typography variant="h4" style={{ fontWeight: "600" }}>
                    {props.loader ? <LoaderRounded /> : props.newCandidate}{" "}
                  </Typography>
                </Grid>
              </Grid>
              <hr />
              <Grid container item xs className={classes.title}>
                <Typography>
                  <h5 className="text-dark">New Candidate</h5>
                </Typography>
              </Grid>
            </Paper>
          </Link>
        </Grid>

        {/* <Grid xs={12} sm={12} md={3} lg={3}>
          {props.role === 'COORDINATOR' ? "" :
            <Link to="/coordinator/all/count/list">
              <Paper className={classes.card}>
                <Grid container>
                  <span className={classes.boxIcon}>
                    <Grid className={classes.eventBoxColor}>
                      <span className={classes.icons}>
                        <i className="fa fa-users" style={{ fontSize: 60 }}></i>
                      </span>

                    </Grid>
                  </span>
                  <Grid
                    container
                    className={classes.persentage}
                    justifyContent="flex-end"
                  >
                    <Typography variant="h4" style={{fontWeight:'600'}}> {props.loader ? <LoaderRounded /> : props.allCandidates} </Typography>
                  </Grid>
                </Grid>
                <hr />
                <Grid container xs className={classes.title}>
                  <Typography>
                    <h5 className="text-dark">Coordinator Count</h5>
                  </Typography>
                </Grid>
              </Paper>
            </Link>
          }
        </Grid> */}

        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Link to="/rejected/candidate/list">
            <Paper className={classes.card}>
              <Grid container>
                <span className={classes.boxIcon}>
                  <Grid className={classes.rejectedCandidateBoxColor}>
                    <span className={classes.icons}>
                      <i>
                        <MdRemoveDone
                          style={{
                            fontSize: "60px",
                          }}
                        />
                      </i>
                    </span>
                  </Grid>
                </span>
                <Grid
                  container
                  className={classes.persentage}
                  justifyContent="flex-end"
                >
                  <Typography variant="h4" style={{ fontWeight: "600" }}>
                    {" "}
                    {props.loader ? (
                      <LoaderRounded />
                    ) : (
                      props.rejectedCandidate
                    )}{" "}
                  </Typography>
                </Grid>
              </Grid>
              <hr />
              <Grid container item xs className={classes.title}>
                <Typography>
                  <h5 className="text-dark">Rejected Candidate</h5>
                </Typography>
              </Grid>
            </Paper>
          </Link>
        </Grid>

        <Grid  item xs={12} sm={12} md={3} lg={3}>
          <Link to="/approve-candidate">
            <Paper className={classes.card}>
              <Grid container>
                <span className={classes.boxIcon}>
                  <Grid className={classes.ApproveBoxColor}>
                    <span className={classes.icons}>
                      <i>
                        <GoVerified
                          style={{
                            fontSize: "60px",
                          }}
                        />
                      </i>
                    </span>
                  </Grid>
                </span>
                <Grid
                  container
                  className={classes.persentage}
                  justifyContent="flex-end"
                >
                  <Typography variant="h4" style={{ fontWeight: "600" }}>
                    {" "}
                    {props.loader ? (
                      <LoaderRounded />
                    ) : (
                      props.approvedCandidate
                    )}{" "}
                  </Typography>
                </Grid>
              </Grid>
              <hr />
              <Grid container item xs className={classes.title}>
                <Typography>
                  <h5 className="text-dark">Approved Candidate</h5>
                </Typography>
              </Grid>
            </Paper>
          </Link>
        </Grid>
      </Grid>
    </div>
  );
}
