import React, { Component } from "react";
import { BsFilterLeft } from "react-icons/bs";
import "font-awesome/css/font-awesome.min.css";
import "../Assets/css/Dashboard.css";
import "../Assets/css/style.css";
import { CgUserList } from "react-icons/cg";
import { GiThink } from "react-icons/gi";
import { MdEventNote } from "react-icons/md";
import { TbBuildingCommunity } from "react-icons/tb";
import yinimg from "../Assets/images/yin.png";
import { useNavigate, Link } from "react-router-dom";

export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: "",
      id: "",
    };
  }
  componentDidMount() {
    let role = localStorage.getItem("role");
    this.setState({ role });
  }

  render() {
    let elemet = document.getElementById("active");
    if (elemet) {
      elemet.focus();
    }
    return (
      <>
        <div className="containers">
          <div className="navigation">
            <ul>
              <div className="divider-10">
                <img src={yinimg} alt="yinimages" width="50px" height="50px" />
              </div>
              <li
                className="divider-20"
                id={window.location.pathname === "/dashboard" ? "active" : ""}
              >
                <Link to="/dashboard">
                  <span className="icon">
                    <i className="fa fa-home"></i>
                  </span>
                  <span className="title">Dashboard</span>
                </Link>
              </li>

              {this.state.role === "COORDINATOR" ? (
                ""
              ) : (
                <>
                  <li
                    className="divider-10"
                    id={
                      window.location.pathname === "/electioncountcards"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/electioncountcards">
                      <span className="icon">
                        <i className="fa fa-building"></i>
                      </span>
                      <span className="title">Election Stats</span>
                    </Link>
                  </li>

                  {/* <li
                    className="divider-10"
                    id={
                      window.location.pathname === "/electionPage"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/electionPage">
                      <span className="icon">
                        <i className="fa fa-address-card"></i>
                      </span>
                      <span className="title">Election</span>
                    </Link>
                  </li> */}



                  {/* <li
                  className="divider-10"
                  id={
                    window.location.pathname === "/ElectionPhaseShow"
                      ? "active"
                      : ""
                  }>
                  <Link to="/ElectionPhaseShow">
                    <span className="icon">
                      <i className="fa fa-list"></i>
                    </span>
                    <span className="title">Election Phase</span>
                  </Link>
                </li> */}
                </>
              )}

              <li
                className="divider-10"
                id={window.location.pathname === "/eventlist" ? "active" : ""}
              >
                <Link to="/eventlist">
                  <span className="icon">
                    <i>
                      <MdEventNote
                        style={{
                          fontSize: "30px",
                          color: "white",

                          marginLeft: "-30px",
                        }}
                      />
                    </i>
                  </span>
                  <span className="title">Event List</span>
                </Link>
              </li>

              {/* <li
                className="divider-10"
                id={
                  window.location.pathname === "/electioncounttable"
                    ? "active"
                    : ""
                }
              >
                <Link to="/electioncounttable">
                  <span className="icon">
                    <i className="fa fa-user"></i>
                  </span>
                  <span className="title">Voter ID list</span>
                </Link>
              </li> */}

              {this.state.role === "COORDINATOR" ? (
                ""
              ) : (
                <>
                  {/* <li
                className="divider-10"
                id={
                  window.location.pathname === "/eventcoordinatorcount"
                    ? "active"
                    : ""
                }
              >
                <Link to="/eventcoordinatorcount">
                  <span className="icon">
                    <i>
                      <MdEventNote
                        style={{
                          fontSize: "30px",
                          color: "white",

                          marginLeft: "-30px",
                        }}
                      />
                    </i>
                  </span>
                  <span className="title">Event Cor. Count</span>
                </Link>
              </li> */}

                  <li
                    className="divider-10"
                    id={
                      window.location.pathname === "/allTransactionList"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/allTransactionList">
                      <span className="icon">
                        <i className="fa fa-list"></i>
                      </span>
                      <span className="title">Razorpay Trans List</span>
                    </Link>
                  </li>
                </>
              )}

              {/* {this.state.role === "COORDINATOR" ? (
                ""
              ) : (
                <li
                  className="divider-10"
                  id={
                    window.location.pathname === "/Citywisecount"
                      ? "active"
                      : ""
                  }
                >
                  <Link to="/Citywisecount">
                    <span className="icon">
                      <i className="fa fa-user"></i>
                    </span>
                    <span className="title">Coord Voter Count</span>
                  </Link>
                </li>
              )} */}

              {/* {this.state.role === "COORDINATOR" ? (
                ""
              ) : (
                <li
                  className="divider-10"
                  id={
                    window.location.pathname === "/Coordinatorwisecandidatecount"
                      ? "active"
                      : ""
                  }
                >
                  <Link to="/Coordinatorwisecandidatecount">
                    <span className="icon">
                      <i className="fa fa-user"></i>
                    </span>
                    <span className="title">Coord Candidate Count</span>
                  </Link>
                </li>
              )} */}


              {/* {this.state.role === "COORDINATOR" ? (
                ""
              ) : (<li
                className="divider-10"
                id={
                  window.location.pathname === "/ElectionLoginCount"
                    ? "active"
                    : ""
                }
              >
                <Link to="/ElectionLoginCount">
                  <span className="icon">
                    <i className="fa fa-users"></i>
                  </span>
                  <span className="title">Live Election Count</span>
                </Link>
              </li>)} */}


              <li
                className="divider-10"
                id={window.location.pathname === "/view-user" ? "active" : ""}
              >
                <Link to="/view-user">
                  <span className="icon">
                    <CgUserList
                      style={{
                        fontSize: "30px",
                        color: "white",

                        marginLeft: "-30px",
                      }}
                    />
                  </span>
                  <span className="title">YIN Member List</span>
                </Link>
              </li>

              <li
                className="divider-10"
                id={
                  window.location.pathname === "/candidateList" ? "active" : ""
                }
              >
                <Link to="/candidateList">
                  <span className="icon">
                    <i className="fa fa-user"></i>
                  </span>
                  <span className="title">Candidate</span>
                </Link>
              </li>

              {
                this.state.role === "COORDINATOR" ? "" : 
                <li
                className="divider-10"
                id={
                  window.location.pathname === "/CollegeWiseCandidateCount" ? "active" : ""
                }
              >
                <Link to="/CollegeWiseCandidateCount">
                  <span className="icon">
                    <i className="fa fa-users"></i>
                  </span>
                  <span className="title">Candidate Count</span>
                </Link>
              </li>
              }

              <li
                className="divider-10"
                id={
                  window.location.pathname === "/coordinatorEventCount" ? "active" : ""
                }
              >
                <Link to="/coordinatorEventCount">
                  <span className="icon">
                    <i className="fa fa-users"></i>
                  </span>
                  <span className="title">Coord Event Count</span>
                </Link>
              </li>
              

              <li
                className="divider-10"
                id={window.location.pathname === "/collegeList" ? "active" : ""}
              >
                <Link to="/collegeList">
                  <span className="icon">
                    <i>
                      <TbBuildingCommunity
                        style={{
                          fontSize: "30px",
                          color: "white",

                          marginLeft: "-30px",
                        }}
                      />
                    </i>
                  </span>
                  <span className="title">College List</span>
                </Link>
              </li>

              {this.state.role === "COORDINATOR" ? (
                ""
              ) : (
                <>
                  {/* <li
                  className="divider-10"
                  id={
                    window.location.pathname === "/notificationlist"
                      ? "active"
                      : ""
                  }
                >
                  <Link to="/notificationlist">
                    <span className="icon">
                      <i>
                        <TbBuildingCommunity
                          style={{
                            fontSize: "30px",
                            color: "white",

                            marginLeft: "-30px",
                          }}
                        />
                      </i>
                    </span>
                    <span className="title">Notification List</span>
                  </Link>
                </li> */}

                  <li
                    className="divider-10"
                    id={
                      window.location.pathname === "/operator/login"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/operator/login">
                      <span className="icon">
                        <i className="fa fa-qrcode"></i>
                      </span>
                      <span className="title">Operator Login</span>
                    </Link>
                  </li>
                </>
              )}

              <li
                className="divider-10"
                id={window.location.pathname === "/ForumList" ? "active" : ""}
              >
                <Link to="/ForumList">
                  <span className="icon">
                    <i className="fa fa-list"></i>
                  </span>
                  <span className="title">Forum List</span>
                </Link>
              </li>

              <li
                className="divider-10"
                id={window.location.pathname === "/AmplifyList" ? "active" : ""}
              >
                <Link to="/AmplifyList">
                  <span className="icon">
                    <i className="fa fa-list"></i>
                  </span>
                  <span className="title">Amplify List</span>
                </Link>
              </li>

              {this.state.role === "COORDINATOR" ? (
                ""
              ) : (
                <>
                  <li
                    className="divider-10"
                    id={
                      window.location.pathname === "/contentlist"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/contentlist">
                      <span className="icon">
                        <GiThink
                          style={{
                            fontSize: "30px",
                            color: "white",

                            marginLeft: "-30px",
                          }}
                        />
                      </span>
                      <span className="title">ThinkList</span>
                    </Link>
                  </li>

                  <li
                    className="divider-10"
                    id={
                      window.location.pathname === "/coordinator"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/coordinator">
                      <span className="icon">
                        <CgUserList
                          style={{
                            fontSize: "30px",
                            color: "white",

                            marginLeft: "-30px",
                          }}
                        />
                      </span>
                      <span className="title">Coordinator List</span>
                    </Link>
                  </li>

                  <li
                    className="divider-10"
                    id={
                      window.location.pathname === "/TabDetails" ? "active" : ""
                    }
                  >
                    <Link to="/TabDetails">
                      <span className="icon">
                        <MdEventNote
                          style={{
                            fontSize: "30px",
                            color: "white",

                            marginLeft: "-30px",
                          }}
                        />
                      </span>
                      <span className="title">Tab Details</span>
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </>
    );
  }
}
