import React, { Component } from 'react';
import { Button, Dropdown, FormControl, Modal, Toast } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import LoaderRounded from './LoaderRounded';

export default class EventTransfer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            searchText: '',
            subSearchText: '',
            selectedItem: null,
            subEventItem: null,
            dropdownItems: [
                { value: 1, label: 'Option 1' },
                { value: 2, label: 'Option 2' },
                { value: 3, label: 'Option 3' },
            ],
            modalShow: false,
            user: this.props.tranferFrom,
            current_event_id: this.props.eventId,
            eventList: this.props.eventList,
            loader: false
        };
    }

    handleSelect = (item) => {
        this.setState({ selectedItem: JSON.parse(item) });
    };

    handleSearch = (e) => {
        this.setState({ searchText: e.target.value });
    };

    handleSubmit = () => {
        this.setState({
            loader: true
        })
        if (this.state.selectedItem) {
            if (this.state.selectedItem.SubEvents.length > 0) {
                if (this.state.subEventItem) {
                    this.TranferStudent(this.state.subEventItem.event_id)
                } else {
                    alert("Select Sub Event.")
                }
            } else {
                this.TranferStudent(this.state.selectedItem.event_id)
            }
        } else {
            alert("Select Event.")
        }
    }

    TranferStudent = async (new_event_id) => {
        const res = await axios.post(process.env.REACT_APP_APIURL + '/eventpdf/transfer/member', {
            currentEventId: this.state.current_event_id,
            nextEventId: new_event_id,
            mobile: `${this.state.user.mobile}`
        })

        if (res) {
            this.setState({
                loader: false
            })
            alert("Student Tranfer Success")
            window.history.back()
        } else {
            alert("Student Tranfer Failure")
        }
    }

    handleSubEvent = (item) => {
        this.setState({
            subEventItem: JSON.parse(item)
        })
    }

    handleSubSearch = (e) => {
        this.setState({
            subSearchText: e.target.value
        })
    }

    isEventOver(event) {
        // Convert event end date and time to a JavaScript Date object
        const eventEndDate = new Date(event.event_end_date);
        const eventEndTime = event.event_end_time;

        // Combine the event end date and time into a single Date object
        const eventEndDateTime = new Date(
            eventEndDate.getFullYear(),
            eventEndDate.getMonth(),
            eventEndDate.getDate(),
            parseInt(eventEndTime.split(':')[0], 10),
            parseInt(eventEndTime.split(':')[1], 10)
        );

        // Get the current date and time
        const currentDateTime = new Date();

        // Check if the event end date and time is in the past
        return currentDateTime > eventEndDateTime;
    }


    render() {
        const filterList = this.state.eventList?.filter((event) => this.isEventOver(event))
        const AllEvent = filterList.filter((event) => event.event_id !== this.state.current_event_id)

        const { searchText, selectedItem, subSearchText, subEventItem } = this.state;

        const filteredItems = AllEvent.filter((item) =>
            item.event_title.toLowerCase().includes(searchText.toLowerCase())
        );

        const filteredSubItems = selectedItem && selectedItem.SubEvents.filter((item) =>
            item.event_title.toLowerCase().includes(subSearchText.toLowerCase()) && item.event_id !== this.state.current_event_id
        );

        return (
            <div>
                <Button variant='success' onClick={() => this.setState({
                    modalShow: true
                })}>
                    Transfer
                </Button>
                <Modal
                    show={this.state.modalShow}
                    onHide={() => this.setState({
                        modalShow: false
                    })}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Transfer Student
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Dropdown onSelect={this.handleSelect}>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                {selectedItem ? selectedItem.event_title : 'Select an event'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ height: 200, overflowY: 'scroll' }}>
                                <FormControl
                                    autoFocus
                                    placeholder="Search..."
                                    onChange={this.handleSearch}
                                />
                                {filteredItems.map((item) => (
                                    <Dropdown.Item key={item.event_id} eventKey={JSON.stringify(item)}>
                                        {item.event_title}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>

                        {
                            selectedItem && selectedItem.SubEvents.length > 0 ?
                                <Dropdown onSelect={this.handleSubEvent} style={{ margin: 5 }}>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        {subEventItem ? subEventItem.event_title : 'Select Subevent'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu style={{ height: 200, overflowY: 'scroll' }}>
                                        <FormControl
                                            autoFocus
                                            placeholder="Search..."
                                            onChange={this.handleSubSearch}
                                        />
                                        {filteredSubItems.map((item) => (
                                            <Dropdown.Item key={item.event_id} eventKey={JSON.stringify(item)}>
                                                {item.event_title}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                                : null
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        {this.state.loader ? <LoaderRounded /> : <Button onClick={this.handleSubmit}>
                            Submit
                        </Button>}
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}
