import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ArrowBack } from "@material-ui/icons";

class BackAndBreadCumOneParent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { backLink, backName, currentTabName, param1, param2, param3 } = this.props;
    return (
      <div className="row" style={{marginLeft:'10px', marginTop:'10px'}}>
        <div className="col-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="">Home</Link>
              </li>
              {this.props.backName ? (
                <li className="breadcrumb-item">
                  <Link to={backLink ? backLink : ""}>
                  {backName ? backName : "_"}
                  </Link>
                </li>
              ) : (
                ""
              )}
              <li className="breadcrumb-item active" aria-current="page">
              {currentTabName ? currentTabName : "Current Tab"}
              </li>
            </ol>
          </nav>
        </div>
        <div className="col-12">
          <div
            style={{
              color:'#003147',
              border: "none",
              display: "inline-block",
              borderRadius: "6px",
              padding: "4px 10px",
              backgroundColor: "#ffffff",
              boxShadow:'rgba(149, 157, 165, 0.2) 0px 8px 24px'
            }}
          >
           <Link
              to={{
                pathname: backLink ? backLink : "/electioncountcards",
                state: { param1: param1, param2: param2, param3: param3 }, // Add your parameters here
              }}
            ><ArrowBack /> Back</Link>
            {/* <Link
              to={
                this.props.backLink
                  ? this.props.backLink
                  : "/electioncountcards"
              }
            > 
              <ArrowBack /> Back
            </Link> */}
          </div>
        </div>
      </div>
    );
  }
}

export default BackAndBreadCumOneParent;
