
import React, { Component } from 'react'
import 'font-awesome/css/font-awesome.min.css';
import '../Assets/css/style.css';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import { Redirect } from 'react-router';

 class Viewcandidate extends Component {

        constructor(pops)
         {
          super(pops);
      
          this.state = {
            candidate_name:"",
            mobile: "",
            Email: "",
            date_of_birth: "",
            gender:"",
            college_name: "",
            Address1:"",
            Address2:"",    
            state:"",
            city: "",
            pincode: "",
            college_Id_photo: "",
            YIN_ID: "",
            Payment_status: "",
            image:'',
            
          }
         }
    render() {
      
      if(!localStorage.getItem("mobile")){
        return <Redirect to="/login"/>
    }

        return (
          <>
                <Sidebar />
                

                <div className="main">
                    <div className="topbar">
                        <Navbar />
                       
                    </div>
                    <div className="container">
   
        <div className="row mt-5">
          <div className="col-lg-5 col-md-5 mx-auto">
              <div className="card rounded shadow shadow-sm"/>
              <div className="card-header">
                <h3 className="mb-0">View Candidate</h3>
              </div>
              <div className="card-body">
                  <center>
                    <img src={this.state.college_Id_photo} width="100px" height="100px" alt="avtar" className="rounded-circle" />
                  </center>
                  <form id="formViewcandidate" onSubmit={this.handleSubmit}>
                <div>
               <label htmlFor="candidate_name"  style={{ textAlign:"center"}}>{this.state.candidate_name}</label>
                </div>
                  <div>
                    <span className="fa fa-phone icon"></span>
                    <label htmlFor="mobile" style={{marginLeft:"30px"}}>{this.state.mobile}9763908091</label>
                  </div><br/>
                  <div>
                    <span className="fa fa-envelope icon"></span>
                    <label htmlFor="Email" style={{marginLeft:"30px"}}>{this.state.Email}abc@gmail.com</label>
                  </div><br/>
                  <div>
                    <span className="fa fa-birthday-cake icon" style={{alignItems:"center"}} ></span>
                    <label htmlFor="date_of_birth" style={{marginLeft:"30px"}}>{this.state.date_of_birth}1 june 2001</label>
                  </div><br/>
                  <div>
                    <span className="fa fa-university icon"></span>
                    <label htmlFor="college_name" style={{marginLeft:"30px"}}>{this.state.college_name}Dr.d.y.patil</label>
                  </div> <br/>
                  <button type="submit" id="btnemp" className="btn btn-dark ">Update Profile</button>
              </form>
              </div>
              </div>
              </div>
              </div>
                </div>
            </>
);
}
}

export default Viewcandidate;