import React from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import { Add } from "@material-ui/icons";
import { Edit, Delete, Visibility } from "@material-ui/icons";
import "../Assets/css/Dashboard.css";
import ReactPaginate from "react-paginate";
import LoaderRounded from "./LoaderRounded";
import LoaderRoundedWithText from "./LoaderRoundedWithText";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../Assets/css/style.css";
import { GiCancel } from "react-icons/gi";
import { Col, Row, Form, } from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';
class AmplifyList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      AmplifyList: [],
      category: this.props?.location?.state?.param2 || "",
      // categories: this.props?.location?.state?.param2 || "",
      currentPage: "",
      counts: "",
      totalPages: "",
      previousLimits: 100,
      renderPageLimit: 10,
      selectedPage: 0,
      filterString: "",
      filterdata: [],
      dropDownOptCategoryList: [],
      setStartDate: null,
      createdAt: "",
      year: this.props?.location?.state?.param1 || "",
      years: [],
      loader: false,
      loading: true,
    };
    this.getData = this.getData.bind(this);
    this.searchList = this.searchList.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.getAmplifyByCategory = this.getAmplifyByCategory.bind(this);
    this.getAmplifyByDate = this.getAmplifyByDate.bind(this);
    this.getAmplifyListWithYear = this.getAmplifyListWithYear.bind(this);
  }

  handlePageChange(page) {
    this.setState({ selectedPage: page.selected });
  }

  UNSAFE_componentWillMount() {
    this.getData();
    this.getCategory();
  }

  componentWillMount() {
    setTimeout(() => this.setState({ loading: false }), 1000);
  }

  // year dropdown code
  componentDidMount() {
    const currentYear = new Date().getFullYear();
    const initialYears = this.generateYears(2021, currentYear);
    this.setState({ years: initialYears });
  }

  generateYears(start, end) {
    const yearsArray = [];
    for (let year = end; year >= start; year--) {
      yearsArray.push(year);
    }
    return yearsArray;
  }

  handleCompleteYear = () => {
    const currentYear = new Date().getFullYear();
    this.setState((prevState) => ({
      years: [...prevState.years, currentYear + 1],
    }));
  };

  // handle year onChange
  getAmplifyListWithYear = (event) => {
    this.setState({
      year: event.target.value,
    });
    this.getData(event.target.value)
  }

  getData(selectedYear) {
    this.setState({
      loader: true,
    });
    let currentComponent = this;

    axios.get(process.env.REACT_APP_APIURL + `/amplifier/getall?year=${selectedYear ? selectedYear : ""}`)
      .then((res) => {
        let AmplifyList = res.data;
        if (Array.isArray(AmplifyList)) {
          currentComponent.setState({
            AmplifyList: AmplifyList,
            filterdata: AmplifyList,
            loader: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          loader: false,
        });
        console.log("get data error", err);
      });
  }

  searchList(char) {
    let f = this.state.filterdata?.filter(
      (item) =>
        item?.title
          ?.toString()
          .toLowerCase()
          ?.startsWith(char?.toLowerCase()) ||
        item?.category
          ?.toString()
          .toLowerCase()
          ?.startsWith(char?.toLowerCase()) ||
        item?.amplifierDate
          ?.toString()
          .toLowerCase()
          ?.startsWith(char?.toLowerCase())
    );
    this.setState({
      AmplifyList: f,
    });
  }

  getCategory = async () => {
    const API = await axios.get(process.env.REACT_APP_APIURL + `/Contents/get/category/all`);
    if (API.data && Array.isArray(API.data)) {
      const serverResponse = API.data;

      const dropDownValue = serverResponse?.map((response) => ({
        value: response.title,
        label: response.title,
        name: "category",
      }));

      this.setState({
        dropDownOptCategoryList: dropDownValue,
      });
    }
  };

  getAmplifyByCategory(event) {
    this.setState({
      loader: true,
    });
    let currentComponent = this;

    let category = event.target.value;
    if (category == "Filter by Category") {
      this.getData();
    } else {
      axios.get(process.env.REACT_APP_APIURL + `/amplifier/filter?category=${category}`)
        .then((res) => {
          if (res.data) {
            currentComponent.setState({
              AmplifyList: res.data,
              // filterdata: res.data,
              loader: false,
            });
          }
        })
        .catch((err) => {
          this.setState({
            loader: false,
          });
          console.log("response error", err);
        });
    }
  }

  // getAmplifyByDate(event) {
  //   let selectedDate = moment(event.target.value).format("YYYY-MM-DD");
  //   this.setState({
  //     setStartDate: selectedDate,
  //   });
  //   let currentComponent = this;
  //   axios.get(process.env.REACT_APP_APIURL + `/amplifier/filter?createdAt=${selectedDate}`)
  //     .then((res) => {
  //       if (res.data) {
  //         currentComponent.setState({
  //           AmplifyList: res.data,
  //           //filterdata : res.data,
  //           loader: false,
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("response error", err);
  //     });
  // }


  getAmplifyByDate(event) {
    this.setState({
      loader: true,
    });

    let selectedDate = moment(event.target.value).format("YYYY-MM-DD");
    this.setState({
      setStartDate: selectedDate,
    });
    let currentComponent = this;
    axios.get(process.env.REACT_APP_APIURL + `/amplifier/filter?createdAt=${selectedDate}`)
      .then((res) => {
        if (res.data) {
          currentComponent.setState({
            AmplifyList: res.data,
            loader: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          loader: false,
        });
        console.log("response error", err);
      });
  }
  //Handle to delete amplify
  deleteAmplifierData(id) {
    let result = window.confirm("Are sure want to delete Amplifier");
    if (result) {
      const amplifierid = {
        amplifier_id: id,
      };
      axios.put(process.env.REACT_APP_APIURL + "/amplifier/delete", amplifierid)
        .then((res) => {
          let responses = res.data;
          if (responses) {
            this.getData();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  render() {
    let Amplify_list = "";
    if (this.state?.AmplifyList !== undefined && this.state?.AmplifyList !== null && this.state?.AmplifyList?.length > 0) {
      Amplify_list = this.state.AmplifyList.map((amplifytlist, id) => {
        let data = id + 1;
        return (
          <tr key={id}>
            <td>{data}</td>
            <td> {amplifytlist.title} </td>
            <td>{amplifytlist.category} </td>
            <td>{new Date(amplifytlist.amplifierDate).toDateString()} </td>
            <td className="d-flex ">
              <Link to={{ pathname: "./view", data: amplifytlist, state: { year: this?.state?.year } }}>
                <button className="btn btn-outline-success btn-sm  ">
                  <Visibility />
                </button>
              </Link>
              &nbsp;&nbsp;&nbsp;
              <Link to={{ pathname: "./EditAmplifier", data: amplifytlist, state: { year: this?.state?.year } }}>
                <button className="btn btn-outline-info btn-sm">
                  <Edit />
                </button>
              </Link>
              &nbsp;&nbsp;&nbsp;
              <button
                className="btn btn-sm btn-outline-danger"
                onClick={() => {
                  this.deleteAmplifierData(amplifytlist._id);
                }}
              >
                <Delete />
              </button>
            </td>
          </tr>
        );
      });
    }
    // else {
    //   Amplify_list = (
    //     <tr>
    //       <td colSpan="6" className="text-center">No Data Found....</td>
    //     </tr>
    //   );
    // }

    return (
      <>
        {this.state.loading ? (
          <LoaderRoundedWithText />
        ) : (
          <div>
            <Sidebar />
            <div className="main">
              <div className="topbar">
                <Navbar title="Amplify List" />
              </div>

              <div className="container mt-4">
                <Row className="mx-3" md={12}>
                  <Col md={5} className=" mb-sm-0 mt-2">
                    <input
                      type="text"
                      placeholder="Search Here"
                      name="search"
                      value={this.state.filterString}
                      onChange={(e) => {
                        this.setState({ filterString: e.target.value });
                        this.searchList(e.target.value);
                      }}
                      className="form-control w-75"
                    ></input>
                  </Col>

                  <Col md={7}>
                    <Row md={12}>
                      <Col md={3} className="mb-2 mb-sm-0 mt-2">
                        <select
                          name="year"
                          className="btn dropdown-toggle mb-2 w-100"
                          style={{ backgroundColor: "#003147", color: "white" }}
                          onChange={this.getAmplifyListWithYear}
                          value={this.state.year}
                        >
                          <option value="">All Years</option>
                          {this.state.years.map((year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))}
                        </select>
                      </Col>

                      <Col md={3} className="mb-2 mb-sm-0 mt-2">
                        <select
                          type="button"
                          name={this.state.dropDownOptCategoryList.title}
                          className="btn btn-md dropdown-toggle w-100"
                          style={{ backgroundColor: "#003147", color: "white" }}
                          id="category"
                          onChange={this.getAmplifyByCategory}
                          value={this.state.category}
                        >
                          <option value={null}> Filter by Category </option>
                          {this.state.dropDownOptCategoryList.map((e, key) => {
                            return (
                              <option key={key} value={e.label}>
                                {e.label}
                              </option>
                            );
                          })}
                        </select>
                      </Col>

                      <Col md={3} className="mb-2 mb-sm-0 mt-2">
                        <input
                          type="date"
                          name="amplifierDate"
                          className="btn btn-md w-100"
                          style={{ backgroundColor: "#003147", color: "white" }}
                          id="datepicker"
                          value={
                            this.state.setStartDate == "Filter by Date"
                              ? "Filter by Date"
                              : this.state.setStartDate
                          }
                          selected={new Date()}
                          onChange={this.getAmplifyByDate}
                        />
                        <style>
                          {`
                          #datepicker::-webkit-calendar-picker-indicator {
                            filter: invert(1);
                          }
                        `}
                        </style>
                      </Col>

                      <Col md={3} className="mb-2 mb-sm-0 mt-2">
                        <Link to="/addAmplify"
                          className="btn btn-secondary w-100"
                          style={{ backgroundColor: "#003147", color: "white" }}>
                          <Add />
                          Add New
                        </Link>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {/* custom pagination  */}
                <Row className="mx-4 mb-5 mt-4 justify-content-end" md={12}>
                  <Col md={6} className="d-flex justify-content-end align-items-center ">
                    <span>Records Per Page:</span>
                    <Form.Control
                      className="w-50 ms-1"
                      id="pageselect"
                      as="select"
                      onChange={(event) => this.hanldeRowchange(event.target.value)}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="40">40</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </Form.Control>
                  </Col>
                </Row>

                {/* <div
                className="mt-3 mb-3"
                style={{
                  display: "flex",

                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <input
                  type="text"
                  placeholder="Search Here"
                  name="search"
                  value={this.state.filterString}
                  onChange={(e) => {
                    this.setState({ filterString: e.target.value });
                    this.searchList(e.target.value);
                  }}
                  className="form-control"
                  style={{
                    width: "10%",
                  }}
                ></input>

                <select
                  style={{ width: "15%" }}
                  type="button"
                  name={this.state.dropDownOptCategoryList.title}
                  className="btn btn-secondary btn-md dropdown-toggle"
                  id="category"
                  onChange={this.getAmplifyByCategory}
                >
                  <option value={null}> Filter by Category </option>
                  {this.state.dropDownOptCategoryList.map((e, key) => {
                    return (
                      <option key={key} value={e.label}>
                        {e.label}
                      </option>
                    );
                  })}
                </select>

                <div
                  style={{
                    width: "13%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    style={{ marginRight: "4%" }}
                    type="date"
                    name="amplifierDate"
                    className="btn btn-secondary btn-md"
                    id="datepicker"
                    value={
                      this.state.setStartDate == "Filter by Date"
                        ? "Filter by Date"
                        : this.state.setStartDate
                    }
                    selected={new Date()}
                    onChange={this.getAmplifyByDate}
                  />
                  <GiCancel
                    style={
                      this.state.setStartDate
                        ? { display: "block" }
                        : { display: "none" }
                    }
                    size={50}
                    color="red"
                    onClick={() => window.location.reload(true)}
                  />
                </div>
                <Link to="/addAmplify" className="btn bg-secondary text-light">
                  <Add />
                  Add New
                </Link>
              </div> */}

                <div className="container" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                  <div className="row">
                    <div className="col-12">
                      <div style={{ overflowx: "auto" }}>
                        <div className="table-responsive-sm">
                          <table className="table table-bordered table-responsive table-hover">
                            <thead>
                              <tr>
                                <th>ID</th>
                                <th>Title</th>
                                <th>Category</th>
                                <th>Amplifier Date</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.loader ? (
                                <tr>
                                  <td colSpan={5}>
                                    <LoaderRounded />
                                  </td>
                                </tr>
                              ) : (Amplify_list.length > 0 ? Amplify_list?.slice(
                                this.state.selectedPage *
                                this.state.renderPageLimit,
                                this.state.renderPageLimit +
                                this.state.selectedPage *
                                this.state.renderPageLimit
                              ) :
                                <tr>
                                  <td colSpan="6" className="text-center">No Data Found....</td>
                                </tr>)}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="overflow-auto mt-2">
                  <ReactPaginate
                    nextLabel="Next"
                    previousLabel="Previous"
                    pageCount={Math.ceil(Amplify_list.length / this.state.renderPageLimit)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    containerClassName="pagination"
                    activeClassName="btn btn-primary"
                    pageClassName="btn  mr-3"
                    previousClassName="btn"
                    nextClassName="btn"
                    onPageChange={this.handlePageChange}
                  />
                </div>
                <button
                  className=" reaload btn btn-success btn-sm mt-3 mb-3"
                  onClick={() => window.location.reload(true)}
                >
                  <Icon.ArrowClockwise size={18} className="me-2" />
                  Refresh
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default AmplifyList;
