import React, { useEffect } from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import { Row, Col, Card, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useState } from "react";

const TransactionViewDetails = () => {
  const location = useLocation();
  const [dataT, setData] = useState("");

  useEffect(() => {
    if (location.data) {
      localStorage.setItem(
        "RazorTransactionDataStatusOURDB",
        JSON.stringify(location.data.item)
      );
    }
    let trData = localStorage.getItem("RazorTransactionDataStatusOURDB");
    setData(JSON.parse(trData));
  }, [dataT]);

  const toastNotify = () => {
    toast.error("Add Data Dashboard Successfully ", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const addToDashboard = async (mobile, eventID) => {
    const data = {
      mobile: mobile,
      event_id: eventID,
    };
    await axios
      .post(
        process.env.REACT_APP_APIURL +
        "/eventpdf/user/registeration/pdf/create/mobile",
        data
      )
      .then((res) => {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((err) => console.log(err));
  };
  const cheackStatus = async (mobile, eventID) => {
    const data = {
      mobile: mobile,
      event_id: eventID,
    };
    await axios
      .post(
        process.env.REACT_APP_APIURL +
        "/eventpdf/user/registeration/pdf/create/mobile",
        data
      )
      .then((res) => {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <ToastContainer autoClose={2000} />

      <div>
        <Sidebar />
        <div className="main">
          <div className="topbar">
            <Navbar />
          </div>
          <BackAndBreadCrmOneParent
            backLink="transactionlist"
            backName="Transactionlist"
            currentTabName="View Transition"
          />

          <div style={{ display: "flex", justifyContent: "center" }}>
            <Card className="w-50 mt-5 p-5">
              <Card.Header>{dataT?.event_id}</Card.Header>
              <Row>
                <Col xs={5}>
                  <h5>Order Id</h5>
                </Col>
                <Col xs={2}>-</Col>
                <Col xs={5}>{dataT?.order_id}</Col>
              </Row>
              <Row>
                <Col xs={5}>
                  <h5>Mobile</h5>
                </Col>
                <Col xs={2}>-</Col>
                <Col xs={5}>
                  <p>{dataT?.mobile}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={5}>
                  <h5>Ammount</h5>
                </Col>
                <Col xs={2}>-</Col>
                <Col xs={5}>{dataT?.amount}</Col>
              </Row>
              <Row>
                <Col xs={5}>
                  <h5>Status</h5>
                </Col>
                <Col xs={2}>-</Col>
                {dataT?.orderStatus === "SUCCESS" ? (
                  <Col style={{ color: "green" }} xs={5}>
                    {dataT?.orderStatus}
                  </Col>
                ) : (
                  <Col style={{ color: "red" }} xs={5}>
                    {dataT?.orderStatus}
                  </Col>
                )}
              </Row>
              <Row style={{ display: "flex", justifyContent: "center" }}>
                {
                  dataT?.orderStatus === "SUCCESS" ? (
                    <Button
                      onClick={() =>
                        addToDashboard(dataT?.mobile, dataT?.event_id)
                      }
                    >
                      Add To Dashboard
                    </Button>
                  ) : (
                    ""
                  )
                  // <Button  onClick={()=>cheackStatus(dataT?.mobile,dataT?.event_id)} >check Status</Button>
                }
              </Row>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionViewDetails;
