import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Edit, Add, Delete, Cancel } from "@material-ui/icons";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import moment from "moment";
import LoaderRounded from "./LoaderRounded";
import LoaderRoundedWithText from "./LoaderRoundedWithText";
import ReactPaginate from "react-paginate";
import { Col, Row } from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';
// import { Collapse } from "bootstrap";

class Contentlist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      indexno: "",
      title: "",
      createdAt: "",
      status: "",
      contents: [],
      currentPage: "",
      counts: "",
      totalPages: "",
      allpage: "",
      isChecked: false,
      errors: {},
      category: "",
      filterdata: [],
      year: "",
      years: [],
      currentPage: "",
      counts: "",
      totalPages: "",
      previousLimits: 100,
      renderPageLimit: 10,
      selectedPage: 0,
      loader: false,
      loading: true,
    };
    this.getData = this.getData.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.rejectContent = this.rejectContent.bind(this);
    this.getContentListWithYear = this.getContentListWithYear.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.getData();
    this.getCategory();
  }
  componentWillMount() {
    setTimeout(() => this.setState({ loading: false }), 1000);
  }

  handlePageChange(page) {
    this.setState({ selectedPage: page.selected });
  }

  // year dropdown code
  componentDidMount() {
    const currentYear = new Date().getFullYear();
    const initialYears = this.generateYears(2021, currentYear);
    this.setState({ years: initialYears });
  }

  generateYears(start, end) {
    const yearsArray = [];
    for (let year = end; year >= start; year--) {
      yearsArray.push(year);
    }
    return yearsArray;
  }

  handleCompleteYear = () => {
    const currentYear = new Date().getFullYear();
    this.setState((prevState) => ({
      years: [...prevState.years, currentYear + 1],
    }));
  };

  // handle year onChange
  getContentListWithYear = (event) => {
    const selectedYear = event.target.value;

    this.setState({
      year: selectedYear,
    });

    this.getData(selectedYear, this.state.category, this.state.status);
  };

  componentDidMount() {
    const currentYear = new Date().getFullYear();
    const initialYears = this.generateYears(2021, currentYear);

    this.setState({
      years: initialYears,
      year: "", // Initialize year to an empty string or a default value
    });

    this.getData("", "", "");
  }

  getData(selectedYear, category, status) {

    this.setState({
      loader: true,
      year: selectedYear,
      category: category,
      status: status,
    });

    let apiUrl = process.env.REACT_APP_APIURL + '/contents/get/article/all';

    // Check if any filter is selected
    if (selectedYear || category !== "Filter by Category" || status !== "Filter by Status") {
      apiUrl += `?year=${selectedYear}&category=${category !== "Filter by Category" ? category : ""}&status=${status !== "Filter by Status" ? status : ""}`;
    }

    axios.get(apiUrl)
      .then((res) => {
        let responses = res.data.data;

        // Check if 'responses' is defined before updating the state
        if (responses !== undefined) {
          this.setState({
            contents: responses,
            loader: false,
          });
        } else {
          this.setState({
            loader: false,
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        this.setState({
          loader: false,
        });
      })
      .finally(() => {
        // console.log("After API Call - Year:", selectedYear, "Category:", category, "Status:", status);
      });
  }

  rejectContent(id) {
    let result = window.confirm("Are sure want to reject content");
    if (result) {
      const deleteid = {
        id: id,
        status: "REJECTED",
      };
      axios.put(process.env.REACT_APP_APIURL + `/Contents/updateStatus`, deleteid)
        .then((res) => {
          if (res.data) {
            this.getData();
          }
        })
        .catch((error) => {
          console.log("error", error)
        })
    }
  }

  getCategory = async () => {
    const API = await axios.get(process.env.REACT_APP_APIURL + `/Contents/get/category/all`);

    if (Array.isArray(API.data) && API.data.length > 0) {
      const serverResponse = API.data;
      const dropDownValue = serverResponse.map((response) => ({
        value: response.title,
        label: response.title,
        name: "category",
      }));
      this.setState({
        dropDownOptCategoryList: dropDownValue,
      });
    }
  };

  render() {
    let content_list = "";


    if (this.state.contents.length > 0 && Array.isArray(this.state.contents)) {
      console.log("content", this.state.contents);
      content_list = this.state.contents.map((contents, index) => (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{contents.title}</td>
          <td>{contents.category}</td>
          <td>{contents.status}</td>
          <td>{moment(contents.createdAt).format("yyyy-MM-DD")}</td>
          <td className="text-center">
            <Link to={{ pathname: "./editcontent", data: contents }}>
              <button className="btn btn-outline-info btn-sm ">
                {" "}
                <Edit />
              </button>
            </Link>
            &nbsp;&nbsp;&nbsp;
            <span>
              <button
                className="btn btn-outline-danger btn-sm "
                onClick={() => {
                  this.rejectContent(contents._id);
                }}
              >
                {" "}
                <Cancel />
              </button>
            </span>
            {/* </Link> */}
          </td>
        </tr>
      ));
    } else {
      content_list = (
        <tr>
          <td colSpan="6" className="text-center">No Data Found....</td>
        </tr>
      );
    }

    return (
      <>
        {this.state.loading ? (
          <LoaderRoundedWithText />
        ) : (
          <div>
            <Sidebar />

            <div className="main">
              <div className="topbar">
                <Navbar title="Content List" />
              </div>

              <Row className="mx-4 mt-4 mb-4 justify-content-end">
                <Col md={8} >
                  <Row>
                    <Col md={3} className="mt-1 mb-sm-0">
                      <select
                        name="year"
                        className="btn dropdown-toggle mb-2 w-100"
                        style={{ backgroundColor: "#003147", color: "white" }}
                        onChange={this.getContentListWithYear}
                        value={this.state.year}
                      >
                        <option value="">All Years</option>
                        {this.state.years.map((year) => (
                          <>
                            <option key={year} value={year}>
                              {year}
                            </option>
                          </>

                        ))}
                      </select>
                    </Col>

                    <Col md={3} className="mt-1 mb-sm-0">
                      <select
                        type="button"
                        className="btn dropdown-toggle w-100"
                        style={{ backgroundColor: "#003147", color: "white" }}
                        id="category"
                        onChange={(e) =>
                          this.getData(
                            this.state.year,
                            e.target.value,
                            this.state.status
                          )
                        }
                      // onChange={this.getData}
                      >
                        <option value={null}> Filter by Category </option>
                        {this.state.dropDownOptCategoryList?.map((e, key) => {
                          return (
                            <option
                              style={{ textAlign: "left" }}
                              key={key}
                              value={e.label}
                            >
                              {e.label}
                            </option>
                          );
                        })}
                      </select>
                    </Col>

                    <Col md={3} className="mt-1 mb-sm-0">
                      <select
                        type="button"
                        name="status"
                        className="btn dropdown-toggle w-100"
                        style={{ backgroundColor: "#003147", color: "white" }}
                        id="status"
                        onChange={(e) =>
                          this.getData(
                            this.state.year,
                            this.state.category,
                            e.target.value
                          )
                        }
                      // onChange={this.getData}
                      >
                        <option value={""}> Filter by Status </option>
                        <option value="APPROVED"> Approved </option>
                        <option value="REJECTED"> Rejected </option>
                      </select>
                    </Col>

                    <Col md={3} className="mt-1 mb-sm-0">
                      <Link to="/content" className="btn btn-secondary w-100" style={{ backgroundColor: "#003147", color: "white" }}>
                        <Add className="me-1" />
                        Add New
                      </Link>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <div className="mx-4">
                {this.state.loader ? (
                  <LoaderRounded />
                ) : (
                  <div className="row">
                    <div className="col-12">
                      <div className="table-responsive-sm">
                        <table className="table table-bordered table-responsive">
                          <thead>
                            <tr>
                              <th>No.</th>
                              <th>Title</th>
                              <th>Category</th>
                              <th>Status</th>
                              <th>Created Date</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.loader ?
                              <tr>
                                <td>
                                  <LoaderRounded />
                                </td>
                              </tr> :
                              (content_list.length > 0 && content_list.slice(
                                this.state.selectedPage *
                                this.state.renderPageLimit,
                                this.state.renderPageLimit +
                                this.state.selectedPage *
                                this.state.renderPageLimit
                              ))
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="overflow-auto mt-2">
                      <ReactPaginate
                        nextLabel="Next"
                        previousLabel="Previous"
                        pageCount={Math.ceil(content_list.length / this.state.renderPageLimit)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        containerClassName="pagination"
                        activeClassName="btn btn-primary"
                        pageClassName="btn"
                        previousClassName="btn"
                        nextClassName="btn"
                        onPageChange={this.handlePageChange}
                      />
                    </div>

                  </div>
                )}
              </div>
              <button
                className=" reaload btn btn-success btn-sm mt-3 mb-3"
                onClick={() => window.location.reload(true)}
              >
                <Icon.ArrowClockwise size={18} className="me-2" />
                Refresh
              </button>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Contentlist;
