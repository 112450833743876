import React from 'react'
import Sidebar from '../../../Sidebar'
import Navbar from '../../../Navbar'

const ElectionOverAllClg = () => {
  return (
    <>
      <Sidebar/>
        <div className="main">
          <div className="topbar">
            <Navbar title="Election Over for All College"/>
          </div>
        </div>
    </>
  )
}

export default ElectionOverAllClg
