import React, { Component } from "react";
import axios from "axios";
import Sidebar from './SidebarScanner';
import Navbar from './Navbar';

export default class SendEventPDFSMS extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobile: '',
            disabledd: false,
            errors: "",
            pdf_link: '',
            pdf_available: false,
            mobileErrors: "",
        };
        this.handleFormChange = this.handleFormChange.bind(this);
    }

    handleFormChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    sendSMSEventPDF = async (mobiles) => {
        if (mobiles === undefined || mobiles === '' || mobiles === null) {
            this.setState({
                mobileErrors: "Please enter mobile number",
            });
        } else {
            try {
                axios.post(process.env.REACT_APP_APIURL + `/eventpdf/send/event/registration/success/message/mobile`, { mobile: mobiles }
                ).then((res) => {
                    if (res.status === 200) {
                        if (res.data.message === "Message send successfully!!!") {
                            this.setState({
                                pdf_available: true,
                                pdf_link: res.data.pdf_link,
                                mobile: "",
                                errors: '',
                                mobileErrors: '',
                            });
                        } else if (res.data === "Sorry your not registrated for any YOUTH SUMMIT") {
                            this.setState({
                                pdf_available: false,
                                errors: res.data,
                                mobileErrors: '',
                            });
                        } else if (res.data == "Registration success message not send") {
                            this.setState({
                                pdf_available: false,
                                errors: res.data,
                                mobileErrors: '',
                            });
                        } else {
                            this.setState({
                                pdf_available: false,
                                errors: "Registration message not send",
                                mobileErrors: '',
                            });
                        }

                    } else {
                        this.setState({
                            pdf_available: false,
                            errors: "SMS not send",
                            mobileErrors: '',
                        });
                    }
                })
                    .catch((error) => {
                        this.setState({
                            pdf_available: false,
                            errors: "SMS not send",
                            mobileErrors: '',
                        });
                    });

            } catch (error) {
                this.setState({
                    pdf_available: false,
                    errors: "SMS not send",
                    mobileErrors: '',
                });
            }
        }
    }


    render() {
        return (
            <>
                <Sidebar />
                <div className="main">
                    <div className="topbar">
                        <Navbar />
                    </div>

                    <div className="container">
                        <div className="row mt-4">
                            <div
                                style={{
                                    heigh: "800vh",
                                    overflow: "hidden",
                                }}
                                className="col-lg-8 col-md-8 col-12 paddingAll10 mx-auto ">

                                <div className="card rounded shadow shadow-sm " >
                                    <div className="card-header">
                                        <h3 className="mb-0 center">Send PDF sms to registered user</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label htmlFor="mobile" className="control-label"><span className="errorMsg">*</span>Enter mobile number</label>
                                            <input type="text" name="mobile" value={this.state.mobile} onChange={this.handleFormChange} className="form-control" placeholder="Type here" required={true}></input>
                                            <div className="text-center errorMsg">{this.state.mobileErrors}</div>

                                        </div>
                                        <div className="text-center mt-3 mb-3">
                                            <button onClick={() => this.sendSMSEventPDF(this.state.mobile)} id="btnSubmitSignup" className="btn btn-primary btn-lg btn-block">Send SMS</button>
                                        </div>
                                        <div className="text-center errorMsg">{this.state.errors}</div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-12">
                                {this.state.pdf_available ? <iframe width="100%" height="900px" src={this.state.pdf_link} /> : ""}

                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}