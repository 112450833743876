import React, { Component } from 'react';
import axios from 'axios';
import ResultChildCom from './DisputeListCount';
// import Select from 'react-select';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import LoaderRounded from "./LoaderRounded";

export default class DisputeList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            college_code: localStorage.getItem("collegeCode") ? localStorage.getItem("collegeCode") : '',
            yin_id: '',
            first_name: '',
            last_name: '',
            votecount: '',
            profile_image: [],
            candidateData: [],
            state_code: '',
            city_code: '',
            state_name: '',
            city_name: '',
            StateData: [],
            CityData: [],
            country: "IN",
            loader: true,
            id: "",
            email: ''
        };
        this.candidates = this.candidates.bind(this);
    }



    UNSAFE_componentWillMount() {
        let CollegeCode = localStorage.getItem("collegeCode");
        this.candidates(CollegeCode);
    }


    async candidates(college_code) {
        // axios.get(process.env.REACT_APP_APIURL+ `/voting/disputesCountByCandidate/` + this.state.college_code)
        axios.get(process.env.REACT_APP_VOTINGAPI + `/voting/resultByCollegeCode/` + this.state.college_code)
            .then(res => {
                let candidates = res.data;
                let a = [];
                if (candidates.length > 0) {
                    a.push(candidates);
                    this.setState({
                        candidateData: candidates,
                        loader: false
                    });
                } else {
                    let error = "No Data Found"
                    console.log("error in result----", error);
                }

            });
    }



    // {this.state.candidateData.map((candidates, index) => (
    //     <div key={index}>
    //         <ResultChildCom profile_image={candidates.profile_image[0]} first_name={candidates.first_name} last_name={candidates.last_name} votecount={candidates.votecount} yin_id={candidates.yin_id} />
    //     </div>
    // ))}


    render() {


        let candidate_data_list = '';
        if (this.state.candidateData.length > 0) {
            candidate_data_list = this.state.candidateData.map((candidates, index) => (

                <div key={index}>
                    <ResultChildCom profile_image={candidates.profile_image[0]} first_name={candidates.first_name} last_name={candidates.last_name} votecount={candidates.disputes} yin_id={candidates.yin_id} />
                </div>
            ))
        }

        else {
            candidate_data_list =
                <tr>
                    <td>No Data Found....</td>
                </tr>
        }

        return (
            <>
                <div>


                    <Sidebar />
                    <div className="main">
                        <div className="topbar">
                            <Navbar />
                        </div>
                        <div className="container divider-12">
                            <BackAndBreadCrmOneParent backLink="/view-card" backName="View card" currentTabName="Dispute list" />

                            <h3 className="divider-20">Dispute list candidate wise</h3>
                            <div className="col-12 col-md-6 col-lg-6">
                                {
                                    this.state.loader ? <LoaderRounded /> : candidate_data_list
                                }
                            </div>


                            <div>

                            </div>



                        </div>

                    </div>
                </div>
            </>
        )
    }
}
