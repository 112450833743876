import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import "../Assets/css/card.css";


export default class CardCount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };
  }

  render() {
    return (
            <div className="col-md-3 col-lg-3 col-12">
              <div className="card   mb-3 shadow-lg" style={{ maxwidth: "18rem", marginTop: "45px" }}>
                <div className="card text-center">
                  <Link to={this.props.cardLink}>
                    <div className="card-header ">
                      <div className="row align-items-center">
                        <div className="col ">
                          <div className={this.props.colorCardBackground}>{this.props.cardCount}</div>
                        </div>
                      </div>
                    </div>
                  </Link>
                  <div className="card-footer bg-light">
                    <h5><a href={this.props.cardLink} className="text-dark">{this.props.cardName}</a></h5>

                  </div>
                </div>
              </div>
            </div>
    )
  }
}
