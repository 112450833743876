import React, { Component } from 'react';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import '../Assets/css/Addcollege.css';
import axios from 'axios';
import { Redirect } from 'react-router';
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import LoaderRounded from './LoaderRounded';



export default class Election extends Component {
    constructor(props) {
        super(props);
        this.state = {
            college_code: localStorage.getItem("collegeCode") ? localStorage.getItem("collegeCode") : "",
            election_start_date: '',
            election_end_date: '',
            election_start_time: '',
            election_end_time: '',
            election_status: '',
            startTime: "12:00:00",
            endTime: "12:00:00",
            showTime: true,
            showEndTime: true,
            changeToView: false,
            multipleElection: localStorage.getItem("multipleElection") === "1" ? localStorage.getItem("multipleElection") : 0,
            errors: {},
            loader: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setElectionStartTime = this.setElectionStartTime.bind(this);
        this.setShowElectionStartTime = this.setShowElectionStartTime.bind(this);
        this.setElectionEndTime = this.setElectionEndTime.bind(this);
        this.setShowElectionEndTime = this.setShowElectionEndTime.bind(this);
        this.getAllData = this.getAllData.bind(this);
    };


    UNSAFE_componentWillMount() {
        let CollegeCode = localStorage.getItem("collegeCode");
        this.getAllData(CollegeCode);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    getAllData(college_code) {
        //axios.get(process.env.REACT_APP_APIURL + `/college/college_code/COLPUNE000`)
        axios.get(process.env.REACT_APP_APIURL + `/college/college_code/` + this.state.college_code)
            .then(res => {
                let electionData = res.data;
                if (electionData.length > 0) {
                    this.setState({
                        college_code: electionData[0].college_code,
                        election_start_date: electionData[0].election_start_date,
                        election_end_date: electionData[0].election_end_date,
                        election_start_time: electionData[0].election_start_time,
                        election_end_time: electionData[0].election_end_time,
                        election_status: electionData[0].election_status
                    });
                }
                else {
                    console.log("no data");
                }

            });
    }

    //code for form validation
    handleFormValidation = () => {
        const { election_start_date, election_end_date, election_start_time, election_end_time } = this.state;
        let errors = {};
        let formIsValid = true;


        if (!election_start_date) {
            formIsValid = false;
            errors["election_start_date"] = "*Please Enter Election Start Date.";
        }
        if (!election_end_date) {
            formIsValid = false;
            errors["election_end_date"] = "*Please Enter Election End Date.";
        }
        if (!election_start_time) {
            formIsValid = false;
            errors["election_start_time"] = "*Please Enter Election Start Time.";
        }
        if (!election_end_time) {
            formIsValid = false;
            errors["election_end_time"] = "*Please Enter Election End Time.";
        }

        this.setState({
            errors: errors
        });
        return formIsValid;

    }

    //save form data
    handleSubmit(event) {
        event.preventDefault();
        if (this.handleFormValidation()) {
            let a = localStorage.getItem("collegeCode");
            let collegeCodes = "";

            let formData = new FormData();
            formData.append("college_code", a.split(","));
            formData.append("election_start_date", this.state.election_start_date);
            formData.append("election_end_date", this.state.election_end_date);
            formData.append("election_start_time", this.state.election_start_time);
            formData.append("election_end_time", this.state.election_end_time);
            formData.append("election_status", this.state.election_status);

            let statesSet = this;
            //post vendor data

            axios.post(process.env.REACT_APP_APIURL + `/coordinator/election/start/selected/colleges`, formData)
                .then(res => {
                    if (res.status === 200) {
                        alert('election information added successfully.')
                        statesSet.setState({
                            changeToView: true,
                            loader: true,
                        })
                    }
                })
        }
    }

    setElectionStartTime(newTimes) {
        this.setState({
            time: newTimes,
            election_start_time: newTimes
        })
    }

    setShowElectionStartTime(show) {
        this.setState({
            showTime: show
        })
    }

    setElectionEndTime(newTimes) {
        this.setState({
            endTime: newTimes,
            election_end_time: newTimes
        })
    }

    setShowElectionEndTime(show) {
        this.setState({
            showEndTime: show
        })
    }

    render() {
        if (this.state.changeToView) {
            return <Redirect to="/view-card" />;
        }
        return (
            <>
                <Sidebar />
                <div className="main">
                    <div className="topbar">
                        <Navbar />
                    </div>
                    <div className="container">
                        {this.state.multipleElection ? <BackAndBreadCrmOneParent backLink="/collegeList" currentTabName="Election List" /> : <BackAndBreadCrmOneParent backLink="/view-card" backName="View card" currentTabName="Election List" />}

                        <div className="row mt-2">
                            <div className="col-12 col-md-5 m-auto">
                                <div className="card rounded shadow shadow-sm">
                                    <div className="card-header">
                                        <h3 className="mb-0">Election Information</h3>
                                    </div>
                                    <div className="card-body">
                                        <form id="formElection" onSubmit={this.handleSubmit}>

                                            <div className="form-group electionCollegeCodeDiv">
                                                <input type="hidden" id="college_code" name="college_code" value={this.state.college_code} className="form-control rounded-1" placeholder="Enter Election Start Date" />
                                            </div>

                                            <div className="form-group electionStartDateDiv">
                                                <label htmlFor="election_start_date" className="control-label">Election Start Date</label>
                                                <input type="date" id="election_start_date" name="election_start_date" value={this.state.election_start_date} onChange={this.handleChange} className="form-control rounded-1" placeholder="Enter Election Start Date" />
                                                <div className="errorMsg">{this.state.errors.election_start_date}</div>
                                            </div>

                                            <div className="form-group electionEndDateDiv">
                                                <label htmlFor="election_end_date" className="control-label">Election End Date</label>
                                                <input type="date" id="election_end_date" name="election_end_date" value={this.state.election_end_date} onChange={this.handleChange} className="form-control rounded-1" placeholder="Enter Election End Date" />
                                                <div className="errorMsg">{this.state.errors.election_end_date}</div>
                                            </div>


                                            <div className="form-group electionEndDateDiv">
                                                {/* {this.state.showTime &&
                                                        <Timekeeper
                                                            time={this.state.time}
                                                            onChange={(newTime) => this.setElectionStartTime(newTime.formatted24)}
                                                            onDoneClick={() => this.setShowElectionStartTime(false)}
                                                            switchToMinuteOnHourSelect
                                                            hour24Mode
                                                        />
                                                    }
                                                    <span>Election Start Time is {this.state.time} </span>
                                                    {!this.state.showTime &&
                                                        <button onClick={() => this.setShowElectionStartTime(true)} className="btn btn-sm btn-primary"> Show Clock</button>
                                                    } */}
                                                <label htmlFor="election_start_time" className="control-label">Election Start Time</label>
                                                <input type="text" id="election_start_time" name="election_start_time" value={this.state.election_start_time} onChange={this.handleChange} className="form-control rounded-1" placeholder="Enter Election Start Time" />
                                                <small>Ex. 18:30:00</small>
                                                <div className="errorMsg">{this.state.errors.election_start_time}</div>
                                            </div>


                                            <div className="form-group electionEndDateDiv">
                                                {/* {this.state.showEndTime &&
                                                        <Timekeeper
                                                            endTime={this.state.endTime}
                                                            onChange={(newTime) => this.setElectionEndTime(newTime.formatted24)}
                                                            onDoneClick={() => this.setShowElectionEndTime(false)}
                                                            switchToMinuteOnHourSelect
                                                            hour24Mode
                                                        />
                                                    }
                                                    <span>Election End Time is {this.state.endTime} </span>
                                                    {!this.state.showEndTime &&
                                                        <button onClick={() => this.setShowElectionEndTime(true)} className="btn btn-sm btn-primary"> Show Clock</button>
                                                    } */}
                                                <label htmlFor="election_end_time" className="control-label">Election End Time</label>
                                                <input type="text" id="election_end_time" name="election_end_time" value={this.state.election_end_time} onChange={this.handleChange} className="form-control rounded-1" placeholder="Enter Election end Time" />
                                                <small>Ex. 18:30:00</small>
                                                <div className="errorMsg">{this.state.errors.election_end_time}</div>
                                            </div>


                                            {/* <div className="form-group electionStartTimeDiv">
                                                <label htmlFor="ElectionStartTime" className="control-label">Election Start Time</label>
                                                <input type="text" id="ElectionStartTime" name="election_start_time" onChange={this.handleChange} className="form-control rounded-1" placeholder="9:30" />
                                            </div> */}



                                            {/* <div className="form-group electionEndTimeDiv">
                                                <label htmlFor="Election End Time" className="control-label">Election End Time</label>
                                                <input type="text" id="ElectionEndTime" name="election_end_time" onChange={this.handleChange} className="form-control rounded-1" placeholder="18.30" />
                                            </div> */}

                                            <div className="form-group electionStatusDiv">
                                                <label htmlFor="Election Status" className="control-label">Election Status</label>
                                                <select type="button" className="form-control  rounded-1" onChange={this.handleChange} name="election_status">
                                                    <option value={null}> -- Select-- </option>
                                                    <option value="1">TRUE</option>
                                                    <option value="0">FALSE</option>
                                                </select>
                                            </div>
                                            <button type="submit" id="btnSubmitElection" className="btn btn-primary btn-md btn-block mt-4 btnElection">{this.state.loader ? <LoaderRounded /> : "Submit"}</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
