import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import S3 from "react-aws-s3";
import Compressor from "compressorjs";
import SearchLocationInput from "./SearchLocationInput";
import moment from "moment";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import { Col, Row, Button, Modal } from "react-bootstrap";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

import LoaderRounded from "./LoaderRounded";

const SendNotification = () => {
  const [title, setTitle] = useState("");
  const [cityData, setCityData] = useState([]);
  const [collegeData, setCollegeData] = useState([]);
  const [des, setDes] = useState("");
  const [city, setCity] = useState([]);
  const [college, setCollege] = useState([]);
  const [userType, setUserType] = useState("");
  const [push, setPush] = useState(true);
  const [sms, setSms] = useState(false);
  const [email, setEmail] = useState();
  const [yinId, setYinId] = useState("");
  const [selectedImage, setSelectedImage] = useState("");

  // Modal State
  const [show, setShow] = useState(false);
  const [msg, setMsg] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Validation Error
  const [titleError, setTitleError] = useState(false);
  const [desError, setDesError] = useState(false);
  const [yinIdError, setYinIdError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [collegeError, setCollegeError] = useState(false);
  const [commonError, setCommonError] = useState(false);

  const titleRef = useRef(null);
  const desRef = useRef(null);
  const usertypeRef = useRef(null);
  const yinIdRef = useRef(null);
  const cityRef = useRef(null);
  const collegeRef = useRef(null);

  const focusOnErrorField = (errorFieldRef) => {
    if (errorFieldRef && errorFieldRef.current) {
      errorFieldRef.current.focus();
    }
  };


  const validate = () => {

    let FormValid = true;
    if (title.length === 0) {
      FormValid = false;
      setTitleError(true);
      focusOnErrorField(titleRef);
    } else if (des.length === 0) {
      FormValid = false;
      setDesError(true);
      focusOnErrorField(desRef);
    } else if (userType === "") {
      FormValid = false;
      setCommonError(true);
      focusOnErrorField(usertypeRef);
    } else if (userType === "YINID" && yinId.length=="" && city.length === 0 && college.length === 0) {
      FormValid = false;
      setYinIdError(true);
      focusOnErrorField(yinIdRef);

    }else if ( userType === "CITY" && yinId=== 0 && city.length == '' && college.length === 0) {
      FormValid = false;
      setCityError(true);
      focusOnErrorField(cityRef);
    }else if( userType === "COLLEGE" && yinId=== 0 && city.length === 0  && college.length == '')  {
      FormValid = false;
      setCollegeError(true);
      focusOnErrorField(collegeRef);
    }
    
    return FormValid;
    
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(URL.createObjectURL(file));
  };

  const toastSuccess = () => {
    toast.success("Record Found", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const toastError = () => {
    toast.error("Record Not Found", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const getAllCity = async () => {
    await axios
      .get(process.env.REACT_APP_APIURL + "/address/city/MH")
      .then((res) => {
        setCityData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCollge = async () => {
    await axios
      .get(process.env.REACT_APP_APIURL + "/coordinator/college/all")
      .then((res) => {
        setCollegeData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [deptNm, setDepNm] = useState("");
  const options =
    cityData?.length > 0 &&
    cityData?.map((emp) => {
      return { value: emp._id, label: emp.name };
    });
  const [colleges, setColleges] = useState("");
  const optionsss =
    collegeData?.length > 0 &&
    collegeData?.map((emp) => {
      return { value: emp.college_key, label: emp.college_name };
    });

  const [selected, setSelected] = useState(null);

  const handleChange = (selectedOption) => {
    setCity(selectedOption);

  };
  const handleChanges = (selectedOption) => {
    setCollege(selectedOption);

  };


  const addNotificationData = async () => {
    setTitleError(false);
    setDesError(false);
    setYinIdError(false);
    // setCityError(false)
    // setCollegeError(false)
    setCommonError(false)

    if (validate()) {
      let data = {
        title: title,
        message: des,
        image: selectedImage,
        yinIds: yinId,
        city: city,
        college_name: college,
        isSMS: false,
        isEmail: false,
        isNotification: push,
      };
      axios
        .post(
          process.env.REACT_APP_APIURL +
            "/notificationCtrl/sendNotificationToUser",
          data
        )
        .then((res) => {
          setMsg(res.data.message);
          handleShow();
          // toastSuccess();
          // {
          //   res.data.message === "sendNotificationToUser"
          //     ? toastSuccess()
          //     : toastError();
          // }
        })
        .catch((err) => {
          console.log("--api error---", err);
        });
    }
  };
  const forEmpty =(e)=>{
    setUserType(e)
    setYinId("")
    setCity([])
    setCollege([])
  }

  useEffect(() => {
    getAllCity();
    getCollge();
  }, []);

  return (
    <>
      <div style={{ minWidth: "100" }}>
        <ToastContainer autoClose={2000} />

        <Sidebar />
        <div className="main">
          <div className="topbar">
            <Navbar />
          </div>
          <div className="container">
            <BackAndBreadCrmOneParent
              backLink="/notificationlist"
              backName="Notification list"
              currentTabName="SendNotification"
            />
            <div className="row mt-4">
              <div
                style={{
                  heigh: "800vh",
                  overflow: "hidden",
                }}
                className="col-lg-8 col-md-8 col-12 paddingAll10 mx-auto "
              >
                <div className="card rounded shadow shadow-sm ">
                  <div className="card-header">
                    <h3
                      style={{ display: "flex", justifyContent: "center" }}
                      className="mb-0 center"
                    >
                      Send Notification
                    </h3>
                  </div>
                  <div className="card-body">
                    <form
                      id="candidate"
                    >
                      <Row className="m-2">
                        <Col xs={4}>
                          <span>Notification Title</span>
                        </Col>
                        <Col xs={8}>
                          <input
                            type="text"
                            name="event_title"
                            value={title}
                            onChange={(e) => {
                              setTitle(e.target.value);
                            }}
                            ref={titleRef}
                            className={`form-control ${titleError ? "is-invalid" : ""}`}
                            placeholder="Type here"
                            required
                          ></input>
                          {titleError && <span className="invalid-feedback">Field Required</span>}
                        </Col>
                      </Row>
                      

                      <Row className="m-2">
                        <Col xs={4}>
                          <span>Description</span>
                        </Col>
                        <Col xs={8}>
                          <input
                            type="text"
                            name="event_title"
                            value={des}
                            onChange={(e) => {
                              setDes(e.target.value);
                            }}
                            ref={desRef}
                            className={`form-control ${desError ? "is-invalid" : ""}`}
                            placeholder="Type here"
                            required
                          ></input>
                          {desError && <span className="invalid-feedback">Field Required</span>}
                        </Col>
                      </Row>
                      
                      <Row className="m-2">
                        <Col xs={4}>
                          <span>User Type</span>
                        </Col>
                        <Col xs={8}>
                          <Form.Select
                            aria-label="Default select example"
                            name="userType"
                            value={userType}
                            ref={usertypeRef}
                            className={`form-control ${commonError ? "is-invalid" : ""}`}
                            onChange={(e) => forEmpty(e.target.value)}
                          >
                            <option value="">Open this select menu</option>
                            <option value="YINID">yin id</option>
                            <option value="CITY">City</option>
                            <option value="COLLEGE">College</option>
                          </Form.Select>
                          {/*commonError && <span className="invalid-feedback">Field Required</span>*/}
                         
                                  {/* <Select
                            isMulti={true}
                            options={options}
                            value={deptNm}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Select City"
                            onChange={handleChange}
                            isSearchable={true}
                          /> */}
                          </Col>
                      </Row>

                      {userType === "YINID" ? (
                        <Row className="m-2">
                          <Col xs={4}>
                            <span>Yin id</span>
                          </Col>
                          <Col xs={8}>
                            <input
                              type="text"
                              name="event_title"
                              value={yinId}
                              onChange={(e) => {
                                setYinId(e.target.value.split(","));
                              }}
                              className={`form-control ${yinIdError ? "is-invalid" : ""}`}
                              ref={yinIdRef}
                              placeholder="Type here"
                               required
                            ></input>
                          </Col>
                          {yinIdError && <span className="invalid-feedback">Field Required</span>}
                        </Row>
                        
                        
                        
                      ) : userType === "CITY" ? (
                        <Row className="m-2">
                          <Col xs={4}>
                            <span>City</span>
                          </Col>
                          <Col xs={8}>
                            <Select
                              isMulti={true}
                              options={options}
                              //value={city}
                              ref={cityRef}
                              className={`basic-multi-select ${cityError ? "is-invalid" : ""}`}
                              classNamePrefix="select"
                              placeholder="Select City"
                              onChange={handleChange}
                              isSearchable={true}
                              required
                            />
                          </Col>
                          {yinIdError && <span className="invalid-feedback">Field Required</span>}
                        </Row>
                      ) : userType === "COLLEGE" ? (
                        <Row className="m-2">
                          <Col xs={4}>
                            <span>College</span>
                          </Col>
                          <Col xs={8}>
                            <Select
                              isMulti={true}
                               //value={college}

                              options={optionsss}
                              ref={collegeRef}
                              className={`basic-multi-select ${collegeError ? "is-invalid" : ""}`}
                              classNamePrefix="select"
                              placeholder="Select College"
                              onChange={handleChanges}
                              isSearchable={true}
                              required
                            />
                          </Col>
                          {collegeError && <span className="invalid-feedback">Field Required</span>}
                        </Row>
                      ) : (
                        ""
                      )}
                      <Row
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Col
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {yinIdError ? (
                            <span style={{ color: "red" }}>Field Required</span>
                          ) : null}

                          {commonError ? (
                            <span style={{ color: "red" }}>Field Required</span>
                          ) : null}
                        </Col>
                      </Row>

                      {/* <Row style={{ display: "flex", justifyContent: "center" }}>
                      <Col
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {yinIdError ? (
                          <span style={{ color: "red" }}>Field Required</span>
                        ) : null}
                      </Col>
                    </Row> */}

                      {/* {userType === city ? ( */}

                      {/* // ):""} */}

                      <Row className="m-2" style={{ justifyContent: "center" }}>
                        <Col>
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                          />
                          {selectedImage && (
                            <img
                              src={selectedImage}
                              style={{ width: "50px", height: "50px" }}
                              alt="Selected"
                            />
                          )}
                        </Col>
                      </Row>
                      <Row className="m-2" style={{ justifyContent: "center" }}>
                        <Col className="d-flex">
                          <Button
                            variant="primary"
                            type="button"
                            className="btn btn-dark btn-lg btn-block mt-4 m-auto"
                            onClick={() => addNotificationData()}
                          >
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    </form>

                    <Modal show={show} onHide={handleClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>Modal heading</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>{msg}</Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Close
                        </Button>
                        <Link
                          to="/notificationlist"
                          // className="btn btn-secondary btn-sm"
                          style={
                            {
                              // float: "right",
                              // marginRight: "40px",
                              // marginTop: "20px",
                            }
                          }
                        >
                          <Button
                            style={{ backgroundColor: "none" }}
                            onClick={handleClose}
                          >
                            Done
                          </Button>
                        </Link>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SendNotification;
