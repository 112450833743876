import React, { useEffect, useState } from 'react'
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import { Row, Col, Card, Button, Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import BackAndBreadCrmOneParent from "../BackAndBreadCumOneParent";
import axios from "axios";
import LoaderRounded from '../LoaderRounded';
import { ToastContainer, toast } from "react-toastify";
import Moment from 'react-moment';
import ReactPaginate from 'react-paginate';
Moment.globalFormat = 'D MMM YYYY HH:MM:ss';

const CheckStatus = () => {
  const location = useLocation();
  const [TransactionData, setState] = useState('')
  const [paymentStatus, setPayStatus] = useState();
  const [loader, setLoader] = useState(false);
  const [showStatus, setStatus] = useState(false);

  useEffect(() => {
    if (location.data) {
      localStorage.setItem("RazorTransactionDataStatus", JSON.stringify(location.data));
    }
    let trData = localStorage.getItem("RazorTransactionDataStatus");
    setState(JSON.parse(trData));
  }, [])

  const getData = (data) => {
    let payData = {
      "PaymentId": data
    }
    setLoader(true);

    axios.post(process.env.REACT_APP_APIURL + "/pay/paymentid", payData)
      .then((res) => {
        setPayStatus(res.data);
        setLoader(false);
        setStatus(true)
      })
      .catch((err) => {
        console.log(err);
        setLoader(false)
      })
  }

  const addToDashboard = async (UserMobile, title) => {
    setLoader(true)
    const data = {
      mobile: UserMobile.substring(3, 13),
      event_title: title,
    };
    await axios.post(process.env.REACT_APP_APIURL + "/eventpdf/user/registeration/pdf/create/mobile/razorpay", data)
      .then((res) => {
        setLoader(false)
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <ToastContainer autoClose={2000} />
      <div>
        <Sidebar />
        <div className="main">
          <div className="topbar">
            <Navbar />
          </div>
          <BackAndBreadCrmOneParent
            backLink="allTransactionList"
            backName="allTransactionList"
            currentTabName="Trasaction List"
          />

          <div style={{ display: "flex", justifyContent: "center" }}>
            <Card className="w-90 mt-3 p-5 mb-5">
              <Card.Header className='mb-2 text-center'><b>{TransactionData?.description}</b></Card.Header>
              <Row>
                <Col xs={3}>
                  <h5>Order Id</h5>
                </Col>
                <Col xs={1}>-</Col>
                <Col xs={8}>{TransactionData?.order_id}</Col>
              </Row>
              <Row>
                <Col xs={3}>
                  <h5>Mobile</h5>
                </Col>
                <Col xs={1}>-</Col>
                <Col xs={8}>
                  <p>{TransactionData?.contact}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={3}>
                  <h5>Email</h5>
                </Col>
                <Col xs={1}>-</Col>
                <Col xs={8}>
                  <p>{TransactionData?.email}</p>
                </Col>
              </Row>

              <Row>
                <Col xs={3}>
                  <h5>Ammount</h5>
                </Col>
                <Col xs={1}>-</Col>
                <Col xs={8}><span>&#8377;</span> {TransactionData?.amount / 100} </Col>
              </Row>
              <Row>
                <Col xs={3}>
                  <h5>Status</h5>
                </Col>
                <Col xs={1}>-</Col>
                <Col xs={8}>{TransactionData?.status}</Col>
              </Row>

              <Row>
                <Col xs={3}>
                  <h5>Payment ID</h5>
                </Col>
                <Col xs={1}>-</Col>
                <Col xs={8}>{TransactionData?.id}</Col>
              </Row>

              <Row>
                <Col xs={3}>
                  <h5>Payment Date</h5>
                </Col>
                <Col xs={1}>-</Col>
                <Col xs={8}><Moment unix>{TransactionData.created_at}</Moment></Col>
              </Row>

              <Row>
                {TransactionData?.status === "captured" ? (loader ? <center> <Spinner /> </center> :
                  <Button onClick={() => addToDashboard(TransactionData?.contact, TransactionData?.description)}>Add To Dashboard</Button>
                ) : (
                  <Button onClick={() => getData(TransactionData?.id)
                  }>Check Status</Button>
                )}
                {/* <Button>Add To Dashboard</Button> */}
              </Row>
              <Row>
                {showStatus ? <div className='mt-3 text-center'>
                  {paymentStatus.error_description ? <span>Error: <b> {paymentStatus.error_description}</b><br /></span> : ""}
                  {paymentStatus.error_reason ? <span>Error Reason: <b> {paymentStatus.error_reason}</b><br /></span> : ""}
                  {paymentStatus.error_source ? <span>Error Source: <b> {paymentStatus.error_source}</b><br /></span> : ""}
                  Payment Status:<b>{paymentStatus.status === "captured" ? <span className="text-center text-success">{paymentStatus.status}</span> : <span className="text-center text-danger">{paymentStatus.status}</span>}</b><br />
                </div> : ""}
              </Row>
            </Card>
          </div>
        </div>
      </div>
    </>
  )
}

export default CheckStatus