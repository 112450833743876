import React from "react";
import axios from "axios";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import BackAndBreadCrmOneParent from "../BackAndBreadCumOneParent";
import ReactPaginate from "react-paginate";
import LoaderRounded from "../LoaderRounded";
import { Button, Col, Form, Nav, Row, Tab } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";

class CoordinatorwiseCandidatecount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      colleges: [],
      errors: {},
      CityData: [],
      districtsLocal: JSON.parse(localStorage.getItem("districts")),
      currentPage: "",
      counts: "",
      totalPages: "",
      year: "",
      years: [],
      role: "",
      rejectCandidateList: "",
      rejectCandidateCounts: "",
      selectedYear: null,
      previousLimits: 100,
      renderPageLimit: 10,
      selectedPage: 0,
      CandidateListCounts: 0,
      loader: false,
      loading: true,
      key: "AllCandidate",
    };

    this.getData = this.getData.bind(this);
  }

  componentWillMount() {
    setTimeout(() => this.setState({ loading: false }), 1000);
  }

  UNSAFE_componentWillMount() {
    this.getData();
  }

  // get college data
  getData() {
    let currentComponent = this;
    this.setState({
      loader: true,
    });

    axios
      .get(
        process.env.REACT_APP_APIURL + `/coordinator/get/coordinator/candidate/list`
      )
      .then((res) => {
        let CandidateList = res?.data;
        let CandidateListCount = res?.data?.length;

        currentComponent.setState({
          colleges: CandidateList,
          loader: false,
          CandidateListCounts: CandidateListCount,
        });
      })
      .catch((error) => {
        currentComponent.setState({ loader: false });
        console.log("error", error);
      });
  }

  render() {
    let election_list = "";

    if (this.state.colleges?.length > 0) {
      election_list = this.state.colleges?.map((college, index) => (
        <tr key={college._id}>
          <td>{index + 1}</td>
          <td>{college.coordinatorName}</td>
          <td>
            <ul>
              {college.city.map((item, index) =>
                <li key={index} style={{ listStyleType: "none" }}>{item}</li>
              )}
            </ul>
          </td>
          <td>
            <span className="circle">{college.candidate} </span>
          </td>
        </tr>
      ));
    } else {
      election_list = (
        <tr>
          <td colSpan="6" className="text-center">No Data Found....</td>
        </tr>
      );
    }

    return (
      <>
        <Sidebar />
        <div className="main">
          <div className="topbar">
            <Navbar />
          </div>
          <div className="container">
            <BackAndBreadCrmOneParent
              backLink=""
              backName=""
              currentTabName="Election List"
            />
            <h2 className="text-center mb-0">Coordinator Wise Candidate Count</h2>
          </div>

          <br></br>

          <>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive-sm">
                    <table className="table table-bordered table-responsive">
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>Coordinator Name</th>
                          <th>City Name</th>
                          <th>Candidate Count</th>
                        </tr>
                      </thead>

                      <tbody>
                        {this.state.loader ? (
                          <LoaderRounded />
                        ) : election_list.length > 0 ? (
                          election_list
                        ) : (
                          <tr>
                            <td colSpan="6" className="text-center">
                              No Data Found ...
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </>
    );
  }
}

export default CoordinatorwiseCandidatecount;
