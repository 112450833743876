import React, { Component } from 'react'
import axios from 'axios';
import Navbar from './NavbarScanner';
import Sidebar from './SidebarScanner';
import LoaderRounded from "./LoaderRounded";
import { connect } from "react-redux";
import ReactPaginate from 'react-paginate';

import * as actions from "./../actions/authAction";
class ScannedList extends Component {
    constructor(pops) {
        super(pops);

        this.state = {
            allpaymentData: [],
            errors: [],
            address_line_1: "",
            images: "",
            address_line_2: "",
            application_status: "",
            yin_id: "",
            redirect: false,
            showModalPopup: false,
            loader: true,
        }
        this.handlePageChange = this.handlePageChange.bind(this);
    }




    handlePageChange(page) {
        this.setState({ selectedPage: page.selected })
    }


    isShowPopup = (status, yinId, Mobile, qr_code_upi_id, qr_code_transaction_id, qr_code_transaction_photo) => {
        localStorage.setItem("approved_qr_code_transaction_id", qr_code_transaction_id);
        localStorage.setItem("approved_qr_code_transaction_photo", qr_code_transaction_photo);
        this.setState({ showModalPopup: status, disabled: true });
    }

    isClosePopup = () => {
        this.setState({ showModalPopup: false });
    }

    UNSAFE_componentWillMount() {
        this.getData();
    }

    getData() {


        let data = JSON.parse(localStorage.getItem("operatorrrr"));
        let sendData = {
            "event_id": data.event_id,
            "operator": data.email

        }
        axios.post(process.env.REACT_APP_TALK_API + `/registerevent/get/attendance/event/all`, sendData)
            .then(res => {
                this.setState({
                    allpaymentData: res.data.data,
                    loader: false,
                });

            });
    }



    render() {

        return (
            <>
                <Sidebar />
                <div className="main">
                    <div className="topbar">
                        <Navbar />
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <u><h2 className="text-center divider-20">Scanned List</h2></u>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="table-responsive-sm">
                                    <table className="table table-bordered table-responsive">
                                        <thead>
                                            <tr>
                                                <th>Student Name</th>
                                                <th>Email</th>
                                                <th>Mobile</th>
                                                <th>College Name</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.loader ? <LoaderRounded /> :
                                                this.state.allpaymentData != undefined && this.state.allpaymentData.length > 0 ? this.state.allpaymentData.map((payments, index) => (
                                                    <tr key={index} >
                                                        <td>{payments.first_name} {payments.last_name}</td>
                                                        <td>{payments.email}</td>
                                                        <td className="noselect">{payments.mobile}</td>
                                                        <td>{payments.college_name}</td>
                                                        <td>
                                                            <span className="btn btn-sm btn-success border" disabled={true}>{payments.is_event_kit_taken ? "KIT Taken" : "KIT Not Taken"}</span>
                                                        </td>
                                                    </tr>
                                                )) : "No data found..."

                                            }
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                            <ReactPaginate
                                nextLabel="Next"
                                previousLabel="Previous"
                                pageCount={this.state.allpaymentData.length / this.state.renderPageLimit}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                containerClassName="pagination"
                                activeClassName="btn btn-primary"
                                pageClassName="btn"
                                previousClassName="btn"
                                nextClassName="btn"
                                onPageChange={this.handlePageChange}
                            />

                        </div>
                    </div>
                </div>

            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        //   userDetails: state.auth.userDetails,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        login: (data) => dispatch(actions.loginSuccess(data)),
        mobile: (data) => dispatch(actions.adminmobile(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScannedList);