import React, { useEffect, useState } from 'react';
import LoaderRoundedWithText from './LoaderRoundedWithText';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import axios from 'axios';
import LoaderRounded from './LoaderRounded';
import * as Icon from "react-bootstrap-icons";
import { Col, Row } from 'react-bootstrap';

const ElectionLoginCount = () => {
  const [loading, setLoading] = useState(true)
  const [loader, setLoader] = useState(false)
  const [electionLoginData, setElectionLoginData] = useState([])
  const [ElectionAllVoteData, setElectionAllVoteData] = useState(0)
  const [votingCount, setVotingCount] = useState("")
  const [page_selected, setPage_selected] = useState("")
  const [limitVal, setLimitVal] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1000);
    getLiveElectionCount();
    // // Set a timeout to make the API call after 1 minute (60,000 milliseconds)
    // const apiCallTimeout = setTimeout(() => {
    //   getLiveElectionCount();
    // }, 6000);
    // // Cleanup function to clear the timeout if the component is unmounted before the timeout
    // return () => clearTimeout(apiCallTimeout);
  }, [])

  const getLiveElectionCount = () => {
    axios.get(process.env.REACT_APP_VOTINGAPI + '/voting/getStatistic/all/active')
      .then((response) => {
        setElectionLoginData(response?.data.data);
        setElectionAllVoteData(response?.data.allVotes);
      })
      .catch((error) => {
        console.log("error", error);
      })
  }

  return (
    <>
      {loading ? (<LoaderRoundedWithText />) :
        <div>
          <Sidebar />
          <div className="main">
            <div className="topbar">
              <Navbar title="Live Election Count" />
            </div>



            <div className="container mt-5">
              <div className="container" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                <Row className="mt-5 mx-3">
                  <Col lg={12} className='d-flex justify-content-end'>
                    <h5>Live Count : {ElectionAllVoteData ? ElectionAllVoteData : 0}</h5>
                  </Col>
                </Row>

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="table-responsive-sm" style={{ maxHeight: 1600, overflowY: "scroll" }}>
                      <table className="table table-bordered table-responsive table-hover">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>College Name </th>
                            <th>City </th>
                            <th>College Votes Count</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            loader ? <LoaderRounded /> :
                              electionLoginData !== undefined && electionLoginData !== null && electionLoginData.length !== 0 ?
                                electionLoginData?.map((val, id) => (
                                  <tr key={id}>
                                    <td>{page_selected * limitVal + id + 1}</td>
                                    <td>{val?.college_name}</td>
                                    <td>{val?.city}</td>
                                    <td><span className="circle">
                                      {val?.collegeVotes}</span></td>
                                  </tr>
                                )) : "No Data Found"
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <button className="reaload btn  btn-success btn-sm mb-3 mt-3" onClick={() => window.location.reload(true)}>
                <Icon.ArrowClockwise size={18} className="me-2" />
                Refresh
              </button>
            </div>
          </div>
        </div>
      }

    </>
  )
}

export default ElectionLoginCount
