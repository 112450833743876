import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  UserData: null,
  loading: "idle",
  selected_event: null,
  selected_forum: null,
  selected_college: null,
  selected_activity: null,
};

const UserData = createSlice({
  name: "login",
  initialState,
  reducers: {
    setLoginData(state, action) {
      state.UserData = action?.payload;
    },
    setLogout(state, action) {},
    setSelectedEvent(state, action) {
      state.selected_event = action?.payload;
    },
    setSelectedForum(state, action) {
      state.selected_forum = action?.payload;
    },
    setSelectedCollege(state, action) {
      state.selected_forum = action?.payload;
    },
    setSelectedActivty(state, action) {
      state.selected_activity = action?.payload;
    },
  },
});
export const {
  setLoginData,
  setLogout,
  setSelectedEvent,
  setSelectedForum,
  setSelectedCollege,
  setSelectedActivty,
} = UserData.actions;
export default UserData.reducer;
