import React, { Component } from "react";
//import { Redirect } from 'react-router-dom';
import axios from "axios";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import S3 from "react-aws-s3";
import Compressor from "compressorjs";
import SearchLocationInput from "./SearchLocationInput";
import moment from "moment";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import LoaderRounded from "./LoaderRounded";
import { Buffer } from "buffer";


Buffer.from("anything", "base64");
window.Buffer = window.Buffer || require("buffer").Buffer;
export default class EditEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      event_id: localStorage.getItem("eventid") ? localStorage.getItem("eventid") : '',
      event_images: [],
      gallery: [],
      sponsors: [],
      promotion_banner_images: [],
      image: "",
      event_restriction: "",
      event_type: "",
      isScheduledAdded: "",
      seatFillMessage: "",
      scheduledInfo: "",
      scheduledInfoTrue: "",
      promotion_banner_imagesTrue: "",
      seatFillMessageTrue: "",
      isSeatDisplay: "",
      is_navigation_added: "",
      college_codes: [],
      cities: [],
      state: "",
      CityData: [],
      event_discount: 0,
      city: "",
      event_title_mar: "",
      status: true,
      event_start_time: "",
      event_end_time: "",
      event_tags: [],
      is_paid: "",
      event_amount: "",
      eventAmountWithoutGst: "",
      eventGroupAmountWithoutGst: "",
      updated_at: "",
      loader: false,
      event_title: "",
      event_description: "",
      event_full_description: "",
      event_via: "",
      event_link: "",
      event_date: "",
      event_end_date: "",
      event_created_date: "",
      event_updated_by: [],
      createdAt: "",
      updatedAt: "",
      showtextbox: false,
      event_min_capacity: "",
      event_max_capacity: "",
      event_media: "OFFLINE",
      event_full_description_mar: "",
      event_address: "",
      event_description_mar: "",
      owner_entity_type: "YIN",
      event_venue: "",
      event_last_date_registration: "",
      event_documents: [],
      event_youtube_video: "EVENT_YOUTUBE_VIDEO_NOT_ADDED",
      latitude: "",
      longitude: "",
      redirect: false,
      isSubEventAdded: "",
      is_registration_over: "",
      isDatePostpone: "",
      eventGroupAmount: "",
      errors: {},
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.selectCity = this.selectCity.bind(this);
    this.ChangeCity = this.ChangeCity.bind(this);
    this.imageChangedHandler = this.imageChangedHandler.bind(this);
    this.uploadImageToS3 = this.uploadImageToS3.bind(this);
    this.uploadImageToS3gallery = this.uploadImageToS3gallery.bind(this);
    this.uploadsponsorsImageToS3 = this.uploadsponsorsImageToS3.bind(this);
    this.uploadpromotion_banner_imagesImageToS3 =
      this.uploadpromotion_banner_imagesImageToS3.bind(this);
    this.addNewSponsorsImage = this.addNewSponsorsImage.bind(this);
    this.addNewpromotion_banner_imagesImage = this.addNewpromotion_banner_imagesImage.bind(this);

  }

  //code for to get states lists
  UNSAFE_componentWillMount() {
    this.showCity();
    this.getDatabyeventid();
  }

  getDatabyeventid() {
    // alert(this.props.location.data.event_id)
    let eventid
    let currentComponent = this;
    if (this.props.location.data) {
      localStorage.setItem("eventid", this.props.location.data.event_id);
      eventid = this.props.location.data.event_id;
    } else {
      eventid = localStorage.getItem("eventid")

    }
    let event_id = eventid;

    axios.get(process.env.REACT_APP_TALK_API + `/event/list/event_id/${event_id}`)
      .then(res => {
        let responses = res.data;
        currentComponent.setState({
          ...responses[0]
        });
      }
      ).catch((err) => console.log(err))

  }


  handleOnChange2 = (e) => {
    if (e.target.value === 1) {
      this.setState({ showtextbox: true });
    } else {
      this.setState({ showtextbox: false });
    }
  };

  showCity() {
    axios
      .get(process.env.REACT_APP_APIURL + "/address/city/MH")
      .then((response) => {
        if (Array.isArray(response.data) && response.data.length > 0) {
          this.setState({
            CityData: response.data,
          });
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }

  ChangeCity = (e) => {
    e.preventDefault();
    let index1 = e.target.selectedIndex;
    this.setState({
      state: e.target[index1].innerText,
    });

    axios
      .get(process.env.REACT_APP_APIURL + "/address/city/" + e.target.value)
      .then((response) => {
        if (response.data.length > 0) {
          this.setState({
            CityData: response.data,
          });
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  selectCity = (e) => {
    //    e.preventDefault();
    let city = e.currentTarget.value;
    this.addCity(city);
  };

  handleFormValidation = () => {
    const {
      event_venue,
      event_title,
      event_title_mar,
      // event_images,
      gallery,
      state,
      event_last_date_registration,
      event_full_description,
      event_full_description_mar,
      event_address,
      event_description_mar,
      event_description,
      // is_paid,
      // event_amount,
      //event_discount,
      event_min_capacity,
      //eventGroupAmount,
      event_max_capacity,
      event_end_date,
      event_start_time,
      event_end_time,
      event_type,
      event_media,
    } = this.state;
    let errors = {};
    let formIsValid = true;
    let arr = [];
    if (!event_title) {
      errors["event_title"] = "Please enter event title.";
      formIsValid = false;
      arr.push({ field: "event_title", msg: "Please enter event title." });
    }
    if (!event_title_mar) {
      errors["event_title_mar"] = "कार्यक्रम शीर्षक प्रविष्ट करा";
      formIsValid = false;
      arr.push({
        field: "event_title_mar",
        msg: "कार्यक्रम शीर्षक प्रविष्ट करा",
      });
    }
    if (!event_venue) {
      formIsValid = false;
      errors["event_venue"] = "Enter event venue.";
      arr.push({ field: "event_venue", msg: "Enter event venue." });
    }
    //empty college name
    if (!event_full_description) {
      formIsValid = false;
      errors["event_full_description"] = "Give Your Answer.";
      arr.push({ field: "event_full_description", msg: "Give Your Answer." });
    }
    if (!event_full_description_mar) {
      formIsValid = false;
      errors["event_full_description_mar"] = "पूर्ण वर्णन प्रविष्ट करा";
      arr.push({
        field: "event_full_description_mar",
        msg: "पूर्ण वर्णन प्रविष्ट करा",
      });
    }
    if (!event_address) {
      formIsValid = false;
      errors["event_address"] = "Give Your Answer";
      arr.push({
        field: "event_address",
        msg: "Give Your Answer",
      });
    }
    if (!event_description_mar) {
      formIsValid = false;
      errors["event_description_mar"] = "वर्णन प्रविष्ट करा";
      arr.push({
        field: "event_description_mar",
        msg: " वर्णन प्रविष्ट करा",
      });
    }
    if (!event_description) {
      formIsValid = false;
      errors["event_description"] = "event description";
      arr.push({
        field: "event description",
        msg: "event_description",
      });
    }
    if (!event_media) {
      formIsValid = false;
      errors["event_media"] = "Give Your Answer";
      arr.push({
        field: "Give Your Answer",
        msg: "event_media",
      });
    }
    if (!event_min_capacity) {
      errors["event_min_capacity"] =
        "Please enter the Event minimum Capacity.";
      formIsValid = false;
      arr.push({
        field: "event_min_capacity",
        msg: "Please enter the Event minimum Capacity.",
      });
    }
    if (!event_max_capacity) {
      errors["event_max_capacity"] =
        "Please enter the Event Maximum Capacity.";
      formIsValid = false;
      arr.push({
        field: "event_max_capacity",
        msg: "Please enter the Event Maximum Capacity.",
      });
    }
    // if (!event_date) {
    //   formIsValid = false;
    //   errors["event_date"] = "*Please enter event date.";
    //   arr.push({ field: "event_date", msg: "*Please enter event date." });
    //   console.log("-------event date---------");
    // }
    if (!event_end_date) {
      formIsValid = false;
      errors["event_end_date"] = "Give Your Answer.";
      arr.push({ field: "event_end_date", msg: "Give Your Answer." });
    }
    if (!event_last_date_registration) {
      formIsValid = false;

      errors["event_last_date_registration"] = "Give Your Answer.";
      arr.push({
        field: "event_last_date_registration",
        msg: "Give Your Answer.",
      });
    }
    if (!event_start_time) {
      formIsValid = false;
      errors["event_start_time"] = "Give Your Answer.";
      arr.push({ field: "event_start_time", msg: "Give Your Answer." });
    }
    if (!event_end_time) {
      formIsValid = false;
      errors["event_end_time"] = "Give Your Answer.";
      arr.push({ field: "event_end_time", msg: "Give Your Answer." });
    }
    if (!event_type) {
      errors["event_type"] = "Please Select event Type.";
      formIsValid = false;
      arr.push({ field: "event_type", msg: "Please Select event Type." });
    }

    // if (!event_images === null || !event_images === "") {
    //   formIsValid = false;
    //   errors["event_images"] = "Give Your Answer.";
    //   arr.push({ field: "event_images", msg: "Give Your Answer." });
    // }

    if (!gallery === null || !gallery === "" || gallery.length <= 0) {
      formIsValid = false;
      errors["gallery"] = "Give Your Answer.";
      arr.push({ field: "gallery", msg: "Give Your Answer." });
    }

    //empty state
    if (state === "" || state === "--Choose State--") {
      formIsValid = false;
      errors["state"] = "Please Choose State.";
      arr.push({ field: "state", msg: "Please Choose State." });
    }
    //empty city
    // if (!cities.length > 0 || state === "--Choose City--") {
    //   formIsValid = false;
    //   errors["city"] = "Please Choose City";
    //   arr.push({ field: "city", msg: "Please Choose City" });
    // }
    this.setState({
      errors: errors,
    });
    // Focus on the first input field with an error message
    if (arr.length > 0) {
      const firstErrorField = document.querySelector(
        `[name="${arr[0].field}"]`
      );
      if (firstErrorField && firstErrorField.focus) {
        firstErrorField.focus();
      }
    }

    return formIsValid;
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    if (this.handleFormValidation()) {
      const updateevent = {
        event_id: localStorage.getItem("eventid"),
        is_published: true,
        status: true,
        isHead: false,
        ...this.state,
      };
      let statesSet = this;
      await axios.post(process.env.REACT_APP_TALK_API + '/event/update', updateevent)
        .then(res => {

          if (res.status === 200) {
            this.props.history.push("/eventlist");
            alert('Event updated successfully');
            statesSet.setState({
              changeToView: true,
              redirect: true,
              loader: false,
            })
          }
        });
    } else {
      console.log("--------this.handleFormValidation()--------", this.handleFormValidation()
      );
    }
  };


  handleCalldelete = async event => {
    event.preventDefault();
    // var id = localStorage.getItem("_id")
    const deleteevent = {
      ...this.state,
      event_id: localStorage.getItem("eventid"),
      is_published: false,
      status: false,
      isHead: false,

    };
    let statesSet = this;

    await axios.post(process.env.REACT_APP_TALK_API + '/event/update', deleteevent)
      .then(res => {
        let status = res.data.data.status;
        if (res.status === 200) {
          alert('deleted successfully');
          this.props.history.push("/eventlist");
          statesSet.setState({
            changeToView: true,
            redirect: true,
            laoder: false,
          })

        }
      })
  }


  imageChangedHandler(event) {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      this.setState({
        event_images: event.target.files[0],
        image: URL.createObjectURL(event.target.files[0]),
      });
    }
  }


  handleFormChange(event) {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
    });
  }


  addCity(city) {
    let CityData = this.state.cities;
    CityData.push(city);
    this.setState({
      cities: CityData,
    });
  }

  addNewImage(newImage) {
    let img_arr = this.state.event_images;
    img_arr.push(newImage);
    this.setState({
      event_images: img_arr,
    });
  }

  addNewImagegallery(newImage) {
    let img_arr = this.state.gallery;
    img_arr.push(newImage);
    this.setState({
      gallery: img_arr,
    });
  }

  removeImageURL(id) {
    let img_arr = this.state.event_images;
    img_arr = img_arr.filter((img) => img.id !== id);
    this.setState({
      event_images: img_arr,
    });
  }

  removeImageURLgallery(id) {
    let img_arr = this.state.gallery;
    img_arr = img_arr.filter((img) => img.id !== id);
    this.setState({
      gallery: img_arr,
    });
  }
  removePdfURL(id) {
    let pdf = this.state.event_documents;
    pdf = pdf.filter((pdf) => pdf.id !== id);
    this.setState({
      event_documents: pdf,
    });
  }

  uploadImageToS3(event) {
    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      dirName: "yin/event_images",
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
      s3Url: process.env.REACT_APP_S3URL,
    };

    var fileInput = false;
    const image = event.target.files[0];
    if (image === undefined) return;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      if (
        event.target.files[0].type === "image/png" ||
        event.target.files[0].type === "image/jpeg"
      ) {
        new Compressor(image, {
          quality: 0.6,
          success: (compressedResult) => {
            let newFileName = event.target.files[0].name.replace(/\..+$/, "");

            const finalFileName = newFileName;
            const ReactS3Client = new S3(config);
            // const newFileName = 'test-file';
            ReactS3Client.uploadFile(compressedResult, newFileName)
              .then((res) => {
                this.addNewImage({
                  type: "IMAGE",
                  url: res.location,
                  name: finalFileName,
                  id: Math.floor(Math.random() * 9999999999),
                });
              })
              .catch((err) => {
                console.error("image uploda error---->", err);
              });
          },
        });
      }
    }
  }

  uploadImageToS3gallery(event) {
    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      dirName: "yin/gallery",
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
      s3Url: process.env.REACT_APP_S3URL,
    };

    var fileInput = false;
    const image = event.target.files[0];
    if (image === undefined) return;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      if (
        event.target.files[0].type === "image/png" ||
        event.target.files[0].type === "image/jpeg"
      ) {
        new Compressor(image, {
          quality: 0.6,
          success: (compressedResult) => {
            let newFileName = event.target.files[0].name.replace(/\..+$/, "");

            const finalFileName = newFileName;
            const ReactS3Client = new S3(config);
            // const newFileName = 'test-file';
            ReactS3Client.uploadFile(compressedResult, newFileName)
              .then((res) => {
                this.addNewImagegallery({
                  type: "IMAGE",
                  url: res.location,
                  name: finalFileName,
                  id: Math.floor(Math.random() * 9999999999),
                });
              })
              .catch((err) => {
                console.error("image uploda error---->", err);
              });
          },
        });
      }
    }
  }
  uploadpdfToS3 = (event) => {
    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      dirName: "yin/event_pdfs",
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
      s3Url: process.env.REACT_APP_S3URL,
    };

    var fileInput = false;
    const image = event.target.files[0];
    if (image === undefined) return;
    // this.setState({
    //     loading_college_id:true,
    //     isDisabled:true,
    // })
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      let newFileName = event.target.files[0].name.replace(/\..+$/, "");
      const ReactS3Client = new S3(config);
      ReactS3Client.uploadFile(image, newFileName)
        .then((res) => {
          let pdfs = this.state.event_documents;
          let pdf = {
            type: "PDF",
            url: res.location,
            name: newFileName,
            id: Math.floor(Math.random() * 9999999999),
          };
          pdfs.push(pdf);
          this.setState({ event_documents: pdfs });
        })
        .catch((err) => console.error(err));

    }
  };

  addNewSponsorsImage(newSopnsorImage) {
    let sponsers_arr = this.state.sponsors;
    sponsers_arr.push(newSopnsorImage);
    this.setState({
      sponsors: sponsers_arr,
    });
  }
  addNewpromotion_banner_imagesImage(newSopnsorImage) {
    let sponsers_arr = this.state.promotion_banner_images;
    sponsers_arr.push(newSopnsorImage);
    this.setState({
      promotion_banner_images: sponsers_arr,
    });
  }

  removeSponsorImageURL(Id) {
    let sponsers_arr = this.state.sponsors;
    sponsers_arr = sponsers_arr.filter((img) => img.Id !== Id);
    this.setState({
      sponsors: sponsers_arr,
    });
  }
  removepromotion_banner_imagesImageURL(Id) {
    let sponsers_arr = this.state.promotion_banner_images;
    sponsers_arr = sponsers_arr.filter((img) => img.Id !== Id);
    this.setState({
      promotion_banner_images: sponsers_arr,
    });
  }

  uploadsponsorsImageToS3(event) {
    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      dirName: "yin/sponsors",
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
      s3Url: process.env.REACT_APP_S3URL,
    };

    var fileInput = false;
    const image = event.target.files[0];
    if (image === undefined) return;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      if (
        event.target.files[0].type === "image/png" ||
        event.target.files[0].type === "image/jpeg"
      ) {
        new Compressor(image, {
          quality: 0.6,
          success: (compressedResult) => {

            let newFileName = event.target.files[0].name.replace(/\..+$/, "");
            const finalFileName = newFileName;
            const ReactS3Client = new S3(config);
            // const newFileName = 'test-file';

            ReactS3Client.uploadFile(compressedResult, newFileName)
              .then((res) => {
                this.addNewSponsorsImage({
                  type: "IMAGE",
                  url: res.location,
                  name: finalFileName,
                  Id: Math.floor(Math.random() * 9999999999),
                });
              })
              .catch((err) => console.error(err));
          },
        });
      }
    }
  }

  uploadpromotion_banner_imagesImageToS3(event) {
    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      dirName: "yin/promotion_banner_images",
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
      s3Url: process.env.REACT_APP_S3URL,
    };

    var fileInput = false;
    const image = event.target.files[0];
    if (image === undefined) return;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      if (
        event.target.files[0].type === "image/png" ||
        event.target.files[0].type === "image/jpeg"
      ) {
        new Compressor(image, {
          quality: 0.6,
          success: (compressedResult) => {
            let newFileName = event.target.files[0].name.replace(/\..+$/, "");
            const finalFileName = newFileName;
            const ReactS3Client = new S3(config);
            // const newFileName = 'test-file';

            ReactS3Client.uploadFile(compressedResult, newFileName)
              .then((res) => {
                this.addNewpromotion_banner_imagesImage({
                  type: "IMAGE",
                  url: res.location,
                  name: finalFileName,
                  Id: Math.floor(Math.random() * 9999999999),
                });
              })
              .catch((err) => console.error(err));
          },
        });
      }
    }
  }

  render() {
    // const { is_published } = this.state;
    // const { showtextbox } = this.state;
    return (
      <>
        <Sidebar />
        <div className="main">
          <div className="topbar">
            <Navbar />
          </div>
          <div className="container">
            <BackAndBreadCrmOneParent
              backLink="/eventlist"
              backName="Event List"
              currentTabName="Edit Event"
            />

            <div className="row mt-4">
              <div
                style={{
                  marginBottom: "50px",
                  overflow: "hidden",
                }}
                className="col-lg-10 col-md-10 col-10 paddingAll10 mx-auto "
              >
                <div className="card rounded shadow shadow-sm ">
                  <div className="card-header">
                    <h3 className="mb-0 center">Edit Event</h3>
                  </div>
                  <div className="card-body paddingAll20">
                    {/* <center>
                                          <img src={this.state.profile_image} onChange={this.handleFormChange} width="100px" height="100px" alt="avtar" className="rounded-circle" />
                                      </center> */}
                    <form
                      id="candidate"
                      method="post"
                      onSubmit={this.handleSubmit}
                      encType="multipart/form-data"
                    >
                      <div className="row">

                        <div className="col-lg-6 col-md-6 col-6 ">
                          <div className="form-group">
                            <label
                              htmlFor="Designation & Role"
                              className="control-label"
                            >
                              Event Title
                            </label>
                            <input
                              type="text"
                              name="event_title"
                              value={this.state.event_title}
                              onChange={this.handleFormChange}
                              className={`form-control ${this.state.errors.event_title ? "error" : ""
                                }`}
                              placeholder="Type here"
                            ></input>
                            <div className="errorMsg">
                              {this.state.errors.event_title}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-6">
                          <div className="form-group">
                            <label
                              htmlFor="Designation & Role"
                              className="control-label"
                            >
                              कार्यक्रम शीर्षक
                            </label>
                            <input
                              type="text"
                              name="event_title_mar"
                              value={this.state.event_title_mar}
                              onChange={this.handleFormChange}
                              className={`form-control ${this.state.errors.event_title_mar ? "error" : ""
                                }`}
                              placeholder="येथे लिहा"
                            ></input>
                            <div className="errorMsg">
                              {this.state.errors.event_title_mar}
                            </div>
                          </div>
                        </div>

                      </div>
                      <br />


                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-6 ">
                          <div id="positionDiv" className="form-group">
                            <label className="label-caption" htmlFor="eventVia">
                              Event Via
                            </label>
                            <select
                              className="form-select"
                              name="event_via"
                              value={this.state.event_via}
                              readOnly
                              onChange={this.handleFormChange}
                            >
                              {/* <option>--Choose--</option> */}
                              <option value="Online Event Registration ">
                                Online Event Registration{" "}
                              </option>
                              <option value="Offline Event Registration">
                                Offline Event Registration
                              </option>
                            </select>
                            <div className="errorMsg">
                              {this.state.errors.event_via}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-6">
                          <div className="form-group">
                            <label className="label-caption" htmlFor="eventmedium">
                              Event Medium
                            </label>
                            <select
                              className="form-select"
                              name="event_via"
                              value={this.state.event_via}
                              readOnly
                              onChange={this.handleEventMode}
                            >
                              {/* <option>--Choose--</option> */}
                              {/* <option  value="Online Event Registration ">Online Event Registration </option> */}
                              <option value="OFFLINE">OFFLINE</option>
                            </select>
                            <div className="errorMsg">
                              {this.state.errors.event_media}
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />


                      <div className="row">

                        {/* <div className="col-lg-6 col-md-6 col-6 ">
                              <div className="form-group">
                              <label
                                className="label-caption"
                                htmlFor="event_address"
                              >
                                Event Address
                              </label>
                              <SearchLocationInput
                                name="event_venue"
                                value={this.state.event_venue}
                                onchange={this.handleFormChange}                           
                                callback={(addr) => {
                                  this.setState({
                                    event_address: addr.formatted_address,
                                  });
                                }}
                              />
                              <div className="errorMsg">
                                {this.state.errors.event_address}
                              </div>
                              </div>
                            </div> */}

                        <div className="col-lg-6 col-md-6 col-6 ">
                          <div className="form-group">
                            <label htmlFor="event venue" className="control-label">
                              Event Venue
                            </label>
                            <input
                              type="text"
                              name="event_venue"
                              value={this.state.event_venue}
                              onChange={this.handleFormChange}
                              className={`form-control ${this.state.errors.event_venue ? "error" : ""
                                }`}
                              placeholder="Type here"
                            ></input>
                            {/* <SearchLocationInput name="event_venue" value={this.state.event_venue} onchange={this.handleFormChange}
                                                          callback={(addr) => {
                                                              this.setState({ event_venue: addr.formatted_address });
                                                          }}
                                                          /> */}
                            <div className="errorMsg">
                              {this.state.errors.event_venue}
                            </div>
                          </div>
                        </div>

                      </div>
                      <br />

                      <div className="row">

                        <div className="col-lg-6 col-md-6 col-6 ">
                          <div className="form-group">
                            <label
                              className="label-caption"
                              htmlFor="FullDescription"
                            >
                              Full Description
                            </label>
                            <textarea
                              className={`form-control ${this.state.errors.event_full_description
                                ? "error"
                                : ""
                                }`}
                              name="event_full_description"
                              value={this.state.event_full_description}
                              onChange={this.handleFormChange}
                              id="FullDescription"
                              placeholder="Type here"
                              rows="3"
                            ></textarea>

                            <div className="errorMsg">
                              {this.state.errors.event_full_description}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-6 ">
                          <div className="form-group">
                            <label
                              className="label-caption"
                              htmlFor="FullDescription"
                            >
                              पूर्ण वर्णन
                            </label>
                            <textarea
                              className={`form-control ${this.state.errors.event_full_description_mar
                                ? "error"
                                : ""
                                }`}
                              name="event_full_description_mar"
                              value={this.state.event_full_description_mar}
                              onChange={this.handleFormChange}
                              id="FullDescription"
                              placeholder="पूर्ण येथे लिहा"
                              rows="3"
                            ></textarea>
                            <div className="errorMsg">
                              {this.state.errors.event_full_description_mar}
                            </div>
                          </div>
                        </div>

                      </div>
                      <br />

                      <div className="row">

                        <div className="col-lg-6 col-md-6 col-6 ">
                          <div className="form-group">
                            <label
                              className="label-caption"
                              htmlFor="FullDescription"
                            >
                              event description
                            </label>
                            <textarea
                              className={`form-control ${this.state.errors.event_description ? "error" : ""
                                }`}
                              name="event_description"
                              value={this.state.event_description}
                              onChange={this.handleFormChange}
                              id="FullDescription"
                              placeholder="Event Description"
                              rows="3"
                            ></textarea>
                            <div className="errorMsg">
                              {this.state.errors.event_description}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-6 ">
                          <div className="form-group">
                            <label
                              className="label-caption"
                              htmlFor="FullDescription"
                            >
                              वर्णन
                            </label>
                            <textarea
                              className={`form-control ${this.state.errors.event_description_mar
                                ? "error"
                                : ""
                                }`}
                              name="event_description_mar"
                              value={this.state.event_description_mar}
                              onChange={this.handleFormChange}
                              id="FullDescription"
                              placeholder="वर्णन येथे लिहा"
                              rows="3"
                            ></textarea>
                            <div className="errorMsg">
                              {this.state.errors.event_description_mar}
                            </div>
                          </div>
                        </div>

                      </div>
                      <br />

                      <div className="row">

                        <div className="col-lg-4 col-md-4 col-4">
                          <div className="textOnInputs">
                            <div className="col-md-12 mt-3">
                              <label htmlFor="image">
                                Promotional Campaign Photo
                              </label>
                              <br />

                              <input
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                id="image"
                                name="college_id"
                                className={`form-control-file ${this.state.errors.event_images ? "error" : ""
                                  }`}
                                onChange={this.uploadImageToS3}
                              />
                              {/* {this.state.event_images ? <img id="image" src={this.state.event_images} alt="" width="200px" height="80px" /> : ""} */}
                              <div className="errorMsg">
                                {this.state.errors.event_images}
                              </div>
                            </div>

                            {this.state.event_images.length === 0
                              ? null
                              : this.state.event_images.map((img) => {
                                return (
                                  <>
                                    <div
                                      className="card"
                                      style={{ margin: 10, width: 200 }}
                                    >
                                      <img
                                        src={img.url}
                                        key={img.url}
                                        className="card-img-top"
                                        alt={img.name}
                                        width="100px"
                                        height="100px"
                                      />
                                      <div class="card-body">
                                        <button
                                          type="delete"
                                          id="btnSubmitSignup"
                                          className="btn btn-danger btn-sm btn-block"
                                          onClick={() =>
                                            this.removeImageURL(img.id)
                                          }
                                        >
                                          delete
                                        </button>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-4">
                          <div className="textOnInputs">
                            <div className="col-md-12 mt-3">
                              <label htmlFor="image">Gallery Photo</label>
                              <br />

                              <input
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                id="image"
                                name="college_id"
                                className={`form-control-file ${this.state.errors.gallery ? "error" : ""
                                  }`}
                                onChange={this.uploadImageToS3gallery}
                              />
                              {/* {this.state.gallery ? <img id="image" src={this.state.gallery} alt="" width="200px" height="80px" /> : ""} */}
                              <div className="errorMsg">
                                {this.state.errors.gallery}
                              </div>
                            </div>

                            {this.state.gallery.length === 0
                              ? null
                              : this.state.gallery.map((img) => {
                                return (
                                  <>
                                    <div
                                      className="card"
                                      style={{ margin: 10, width: 200 }}
                                    >
                                      <img
                                        src={img.url}
                                        key={img.url}
                                        className="card-img-top"
                                        alt={img.name}
                                        width="100px"
                                        height="100px"
                                      />
                                      <div class="card-body">
                                        <button
                                          type="delete"
                                          id="btnSubmitSignup"
                                          className="btn btn-danger btn-sm btn-block"
                                          onClick={() =>
                                            this.removeImageURLgallery(img.id)
                                          }
                                        >
                                          delete
                                        </button>
                                      </div>
                                    </div>
                                    {/* <img style={{ margin: 10 }} width="100px" height="100px" key={img.url} src={img.url} alt={img.name} />
                                                                            <button type="delete" id="btnSubmitSignup" className="btn btn-danger btn-sm btn-block" onClick={() => this.removeImageURL(img.id)} >delete</button> */}
                                  </>
                                );
                              })}
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-4">
                          <div className="textOnInputs">
                            <div className="col-md-12 mt-3">
                              <label htmlFor="image">
                                Promotional Campaign Sponsers
                              </label>

                              <input
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                id="image"
                                name="sponsors"
                                className="form-control-file"
                                onChange={this.uploadsponsorsImageToS3}
                              />
                              {/* {this.state.event_images ? <img id="image" src={this.state.event_images} alt="" width="200px" height="80px" /> : ""} */}
                              <div className="errorMsg">
                                {this.state.errors.sponsors}
                              </div>
                            </div>
                            {this.state.sponsors.length === 0
                              ? null
                              : this.state.sponsors.map((img) => {
                                return (
                                  <>
                                    <div
                                      className="card"
                                      style={{ margin: 10, width: 200 }}
                                    >
                                      <img
                                        src={img.url}
                                        key={img.url}
                                        className="card-img-top"
                                        alt={img.name}
                                        width="100px"
                                        height="100px"
                                      />
                                      <div class="card-body">
                                        <button
                                          type="delete"
                                          id="btnSubmitSignup"
                                          className="btn btn-danger btn-sm btn-block"
                                          onClick={() =>
                                            this.removeSponsorImageURL(img.Id)
                                          }
                                        >
                                          delete
                                        </button>
                                      </div>
                                    </div>
                                    {/* <img style={{ margin: 10 }} width="100px" height="100px" key={img.url} src={img.url} alt={img.name} />
                                                                          <button type="delete" id="btnSubmitSignup" className="btn btn-danger btn-sm btn-block" onClick={() => this.removeImageURL(img.id)} >delete</button> */}
                                  </>
                                );
                              })}
                          </div>
                        </div>

                      </div>
                      <br />



                      <div className="row">

                        <div className="col-lg-4 col-md-4 col-4">
                          <div className="textOnInputs">
                            <div className="col-md-12">
                              <label htmlFor="image">
                                Promotional Campaign PDF
                              </label>
                              <input
                                type="file"
                                id="documents"
                                name="event_documents"
                                className="form-control-file"
                                onChange={this.uploadpdfToS3}
                                accept="image/*;capture=camera"
                              />
                              <div className="errorMsg">
                                {this.state.errors.event_documents}
                              </div>
                            </div>
                            {this.state.event_documents.length === 0
                              ? null
                              : this.state.event_documents.map((pdf) => {
                                return (
                                  <>
                                    <div
                                      className="card"
                                      style={{ margin: 10, width: 200 }}
                                    >
                                      <img
                                        src={pdf.url}
                                        className="card-img-top"
                                        alt={pdf.name}
                                        style={{ margin: 10 }}
                                        key={pdf.url}
                                      />
                                      <div class="card-body">
                                        <button
                                          type="delete"
                                          id="btnSubmitSignup"
                                          className="btn btn-danger btn-sm btn-block mb-2 mt-4"
                                          onClick={() =>
                                            this.removePdfURL(pdf.id)
                                          }
                                        >
                                          Delete
                                        </button>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                          </div>
                        </div>


                        <div className="col-lg-4 col-md-4 col-4">
                          <div className="textOnInputs">
                            <div className="form-group">
                              <label htmlFor="location" className="control-label">
                                promotion banner images
                              </label>
                              <select
                                className={`form-select ${this.state.errors.promotion_banner_images
                                  ? "error"
                                  : ""
                                  }`}
                                name="promotion_banner_images"
                                value={this.state.promotion_banner_imagesTrue}
                                onChange={(e) => {
                                  this.setState({
                                    promotion_banner_imagesTrue: e.target.value,
                                  });
                                }}
                              >
                                <option value="">--Choose--</option>
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                              </select>

                            </div>


                            <div
                              className="col-lg-4 col-md-4 col-4"
                              style={{
                                display:
                                  this.state.promotion_banner_imagesTrue === "true"
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <label htmlFor="image">Promotion Banner Images</label>

                              <input
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                id="image"
                                name="promotion_banner_images"
                                className="form-control-file"
                                onChange={
                                  this.uploadpromotion_banner_imagesImageToS3
                                }
                              />
                              {/* {this.state.event_images ? <img id="image" src={this.state.event_images} alt="" width="200px" height="80px" /> : ""} */}
                              <div className="errorMsg">
                                {this.state.errors.promotion_banner_images}
                              </div>
                            </div>
                            {this.state.promotion_banner_images.length === 0
                              ? null
                              : this.state.promotion_banner_images.map((img) => {
                                return (
                                  <>
                                    <div
                                      className="card"
                                      style={{ margin: 10, width: 200 }}
                                    >
                                      <img
                                        src={img.url}
                                        key={img.url}
                                        className="card-img-top"
                                        alt={img.name}
                                        width="100px"
                                        height="100px"
                                      />
                                      <div class="card-body">
                                        <button
                                          type="delete"
                                          id="btnSubmitSignup"
                                          className="btn btn-danger btn-sm btn-block"
                                          onClick={() =>
                                            this.removepromotion_banner_imagesImageURL(
                                              img.Id
                                            )
                                          }
                                        >
                                          delete
                                        </button>
                                      </div>
                                    </div>
                                    {/* <img style={{ margin: 10 }} width="100px" height="100px" key={img.url} src={img.url} alt={img.name} />
                                                                        <button type="delete" id="btnSubmitSignup" className="btn btn-danger btn-sm btn-block" onClick={() => this.removeImageURL(img.id)} >delete</button> */}
                                  </>
                                );
                              })}
                          </div>
                        </div>


                        <div className="col-lg-4 col-md-4 col-4">
                          <div className="form-group">
                            <label className="label-caption" htmlFor="eventLink">
                              YouTube URL
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="event_youtube_video"
                              value={this.state.event_youtube_video}
                              onChange={this.handleFormChange}
                              placeholder="Type here"
                            />
                            <div className="errorMsg">
                              {this.state.errors.event_youtube_video}
                            </div>
                          </div>
                        </div>

                      </div>
                      <br />
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-6">
                          <div className="form-group">
                            <label htmlFor="location" className="control-label">
                              Is Paid
                            </label>
                            <select
                              className={`form-select ${this.state.errors.is_paid ? "error" : ""
                                }`}
                              name="is_paid"
                              value={this.state.is_paid}
                              onChange={this.handleFormChange}
                            // disabled={
                            //   this.state.status === false ? "disabled" : null
                            // }
                            >
                              <option value="">--Choose--</option>
                              <option value="true">YES</option>
                              <option value="false">NO</option>
                            </select>
                            <div className="errorMsg">
                              {this.state.errors.is_paid}
                            </div>
                          </div>
                        </div>


                        <div className="col-lg-6 col-md-6 col-6">
                          <div className="form-group">
                            <label htmlFor="location" className="control-label">
                              Event Type
                            </label>
                            <select
                              className={`form-select ${this.state.errors.event_type ? "error" : ""
                                }`}
                              name="event_type"
                              value={this.state.event_type}
                              onChange={this.handleFormChange}
                            >
                              <option value="">--Choose--</option>
                              <option value="INDIVIDUAL">INDIVIDUAL</option>
                              <option value="GROUP">GROUP</option>
                            </select>
                            <div className="errorMsg">
                              {this.state.errors.event_type}
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="row">

                        {this.state.is_paid === 'true' && this.state.event_type === 'GROUP' && (
                          <>
                            <div className="col-lg-6 col-md-6 col-6">
                              <div className="form-group">
                                <label className="label-caption" htmlFor="eventLink">
                                  Event Group Amount
                                </label>
                                <input
                                  type="number"
                                  className={`form-control ${this.state.errors.eventGroupAmount ? "error" : ""
                                    }`}
                                  name="eventGroupAmount"
                                  value={this.state.eventGroupAmount}
                                  onChange={this.handleFormChange}
                                  placeholder="Type here"
                                />
                                <div className="errorMsg">
                                  {this.state.errors.eventGroupAmount}
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-6">
                              <div className="form-group">
                                <label className="label-caption" htmlFor="eventLink">
                                  Event Group Amount Without Gst
                                </label>
                                <input
                                  type="number"
                                  className={`form-control ${this.state.errors.eventGroupAmountWithoutGst
                                    ? "error"
                                    : ""
                                    }`}
                                  name="eventGroupAmountWithoutGst"
                                  value={this.state.eventGroupAmountWithoutGst}
                                  onChange={this.handleFormChange}
                                  placeholder="Type here"
                                />
                                <div className="errorMsg">
                                  {this.state.errors.eventGroupAmountWithoutGst}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      <br />


                      <div className="row">
                        {this.state.is_paid === 'true' && this.state.event_type === 'INDIVIDUAL' && (
                          <>
                            <div className="col-lg-6 col-md-6 col-6">
                              <div className="form-group">
                                <label className="label-caption" htmlFor="eventLink">
                                  Event Amount
                                </label>
                                <input
                                  type="number"
                                  className={`form-control ${this.state.errors.event_amount ? "error" : ""
                                    }`}
                                  name="event_amount"
                                  value={this.state.event_amount}
                                  onChange={this.handleFormChange}
                                  placeholder="Type here"
                                />
                                <div className="errorMsg">
                                  {this.state.errors.event_amount}
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-6">
                              <div className="form-group">
                                <label className="label-caption" htmlFor="eventLink">
                                  Event Amount Without Gst
                                </label>
                                <input
                                  type="number"
                                  className={`form-control ${this.state.errors.eventAmountWithoutGst
                                    ? "error"
                                    : ""
                                    }`}
                                  name="eventAmountWithoutGst"
                                  value={this.state.eventAmountWithoutGst}
                                  onChange={this.handleFormChange}
                                  placeholder="Type here"
                                />
                                <div className="errorMsg">
                                  {this.state.errors.eventAmountWithoutGst}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      <br />


                      <div className="row">

                        <div className="col-lg-4 col-md-4 col-4">
                          <div className="form-group">
                            <label className="label-caption" htmlFor="eventLink">
                              Event Discount
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              name="event_discount"
                              value={this.state.event_discount}
                              onChange={this.handleFormChange}
                              placeholder="Type here"
                            />
                            <div className="errorMsg">
                              {this.state.errors.event_discount}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-4">
                          <div className="form-group">
                            <label
                              className="label-caption"
                              htmlFor="event_min_capacity"
                            >
                              Event Min Capacity
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              name="event_min_capacity"
                              value={this.state.event_min_capacity}
                              onChange={this.handleFormChange}
                              placeholder="Type here"
                            />
                            <div className="errorMsg">
                              {this.state.errors.event_min_capacity}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-4">
                          <div className="form-group">
                            <label
                              className="label-caption"
                              htmlFor="event_max_capacity"
                            >
                              Event Max Capacity
                            </label>
                            <input
                              type="number"
                              className={`form-control ${this.state.errors.event_max_capacity ? "error" : ""
                                }`}
                              name="event_max_capacity"
                              value={this.state.event_max_capacity}
                              onChange={this.handleFormChange}
                              placeholder="Type here"
                            />
                            <div className="errorMsg">
                              {this.state.errors.event_max_capacity}
                            </div>
                          </div>
                        </div>

                      </div>
                      <br />


                      <div className="row">
                        <div className="col-lg-4 col-md-4 col-4">
                          <div className="form-group">
                            <label className="label-caption" htmlFor="eventDate">
                              Event Start Date
                            </label>
                            <input
                              type="date"
                              id="eventDate"
                              className={`form-control ${this.state.errors.event_date ? "error" : ""
                                }`}
                              name="event_date"
                              value={moment(this.state.event_date).format(
                                "yyyy-MM-DD"
                              )}
                              onChange={this.handleFormChange}
                            />
                            <div className="errorMsg">
                              {this.state.errors.event_date}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-4">
                          <div className="form-group">
                            <label className="label-caption" htmlFor="eventDate">
                              Event End Date
                            </label>
                            <input
                              type="date"
                              id="event_end_date"
                              className={`form-control ${this.state.errors.event_end_date ? "error" : ""
                                }`}
                              name="event_end_date"
                              value={moment(this.state.event_end_date).format(
                                "yyyy-MM-DD"
                              )}
                              onChange={this.handleFormChange}
                            />
                            <div className="errorMsg">
                              {this.state.errors.event_end_date}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-4">
                          <div className="form-group">
                            <label className="label-caption" htmlFor="eventDate">
                              Event Last Date Of registration
                            </label>
                            <input
                              type="date"
                              id="eventDate"
                              className={`form-control ${this.state.errors.event_last_date_registration
                                ? "error"
                                : ""
                                }`}
                              name="event_last_date_registration"
                              value={moment(
                                this.state.event_last_date_registration
                              ).format("yyyy-MM-DD")}
                              onChange={this.handleFormChange}
                            />
                            <div className="errorMsg">
                              {this.state.errors.event_last_date_registration}
                            </div>
                          </div>
                        </div>

                      </div>
                      <br />


                      <div className="row">

                        <div className="col-lg-4 col-md-4 col-4">
                          <div className="form-group">
                            <label
                              className="label-caption"
                              htmlFor="eventStartTime"
                            >
                              Event Start Time
                            </label>
                            <input
                              type="text"
                              id="eventStartTime"
                              className={`form-control ${this.state.errors.event_start_time ? "error" : ""
                                }`}
                              name="event_start_time"
                              value={this.state.event_start_time}
                              onChange={this.handleFormChange}
                              placeholder="Type here"
                            />
                            <div className="errorMsg">
                              {this.state.errors.event_start_time}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-4">
                          <div className="form-group">
                            <label className="label-caption" htmlFor="eventEndTime">
                              Event End Time
                            </label>
                            <input
                              type="text"
                              id="eventEndTime"
                              className={`form-control ${this.state.errors.event_end_time ? "error" : ""
                                }`}
                              name="event_end_time"
                              value={this.state.event_end_time}
                              onChange={this.handleFormChange}
                              placeholder="Type here"
                            />
                            <div className="errorMsg">
                              {this.state.errors.event_end_time}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-4">
                          <div className="form-group">
                            <label htmlFor="location" className="control-label">
                              Want to add subevent?
                            </label>
                            <select
                              className={`form-select ${this.state.errors.isSubEventAdded ? "error" : ""
                                }`}
                              name="isSubEventAdded"
                              value={this.state.isSubEventAdded}
                              onChange={this.handleFormChange}
                            >
                              <option value="">--Choose--</option>
                              <option value={true}>yes</option>
                              <option value={false}>no</option>
                            </select>
                            <div className="errorMsg">
                              {this.state.errors.isSubEventAdded}
                            </div>
                          </div>
                        </div>

                      </div>
                      <br />


                      <div className="row">
                        <div className="col-lg-4 col-md-4 col-4">
                          <div className="form-group">
                            <label htmlFor="location" className="control-label">
                              Is Seat Display
                            </label>
                            <select
                              className={`form-select ${this.state.errors.isSeatDisplay ? "error" : ""
                                }`}
                              name="isSeatDisplay"
                              value={this.state.isSeatDisplay}
                              onChange={this.handleFormChange}
                            >
                              <option value="">--Choose--</option>
                              <option value={true}>Yes</option>
                              <option value={false}>No</option>
                            </select>
                            <div className="errorMsg">
                              {this.state.errors.isSeatDisplay}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-4">
                          <div className="form-group">
                            <label htmlFor="location" className="control-label">
                              Seat Fill Message Added
                            </label>
                            <select
                              className={`form-select ${this.state.errors.seatFillMessage ? "error" : ""
                                }`}
                              name="seatFillMessage"
                              value={this.state.seatFillMessageTrue}
                              onChange={(e) => {
                                this.setState({
                                  seatFillMessageTrue: e.target.value,
                                });
                              }}
                            >
                              <option value="">--Choose--</option>
                              <option value={true}>Yes</option>
                              <option value={false}>No</option>
                            </select>
                            <br />
                            <input
                              style={{
                                display:
                                  this.state.seatFillMessageTrue === "true"
                                    ? "block"
                                    : "none",
                              }}
                              type="text"
                              id="seatFillMessage"
                              className={`form-control 
                                  }`}
                              name="seatFillMessage"
                              value={this.state.seatFillMessage}
                              onChange={this.handleFormChange}
                              placeholder="Add Seat Fill Message"
                            />
                            <div className="errorMsg">
                              {this.state.errors.seatFillMessage}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-4">
                          <div className="form-group">
                            <label htmlFor="location" className="control-label">
                              Is Navigation Added
                            </label>
                            <select
                              className={`form-select ${this.state.errors.is_navigation_added ? "error" : ""
                                }`}
                              name="is_navigation_added"
                              value={this.state.is_navigation_added}
                              onChange={this.handleFormChange}
                            >
                              <option value="">--Choose--</option>
                              <option value={true}>yes</option>
                              <option value={false}>No</option>
                            </select>
                            <div className="errorMsg">
                              {this.state.errors.is_navigation_added}
                            </div>
                          </div>
                        </div>

                      </div>



                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-6">
                          <div className="form-group">
                            <label htmlFor="location" className="control-label">
                              Is Schedule Added
                            </label>
                            <select
                              className={`form-select ${this.state.errors.isScheduledAdded ? "error" : ""
                                }`}
                              name="isScheduledAdded"
                              value={this.state.isScheduledAdded}
                              onChange={this.handleFormChange}
                            >
                              <option value="">--Choose--</option>
                              <option value={true}>Yes</option>
                              <option value={false}>No</option>
                            </select>
                            <div className="errorMsg">
                              {this.state.errors.isScheduledAdded}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-6">
                          <div className="form-group">
                            <label htmlFor="location" className="control-label">
                              Schedule Info
                            </label>
                            <select
                              className={`form-select ${this.state.errors.scheduledInfo ? "error" : ""
                                }`}
                              name="scheduledInfo"
                              value={this.state.scheduledInfoTrue}
                              onChange={(e) => {
                                this.setState({
                                  scheduledInfoTrue: e.target.value,
                                });
                              }}
                            >
                              <option value="">--Choose--</option>
                              <option value={true}>Yes</option>
                              <option value={false}>No</option>
                            </select>
                            <br />
                            <textarea
                              style={{
                                display:
                                  this.state.scheduledInfoTrue === "true"
                                    ? "block"
                                    : "none",
                              }}
                              type="text"
                              id="scheduledInfo"
                              className={`form-control 
                                  }`}
                              name="scheduledInfo"
                              value={this.state.scheduledInfo}
                              onChange={this.handleFormChange}
                              placeholder="Add scheduledInfo"
                              rows="3"
                            />
                            <div className="errorMsg">
                              {this.state.errors.scheduledInfo}
                            </div>
                          </div>
                        </div>
                      </div>



                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-6">
                          <div className="form-group">
                            <label htmlFor="states" className="control-label">
                              Select State
                            </label>
                            <select
                              id="states"
                              className="form-select"
                              name="state"
                              value={this.state.state}
                              onChange={this.ChangeCity}
                            >
                              {/*<option>--Choose State--</option>*/}
                              <option value="MH">Maharashtra</option>
                              {/* <option value="GA">Goa</option> */}
                            </select>
                            <div className="errorMsg">
                              {this.state.errors.state}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-6">
                          <div className="form-group">
                            <label
                              htmlFor="citys"
                              id="cityLabel"
                              className="control-label"
                            >
                              Select City
                            </label>
                            <select
                              id="citys"
                              name="cities"
                              className={`form-select ${this.state.errors.cities ? "error" : ""
                                }`}
                              onChange={this.selectCity}
                            >
                              <option>--Choose City--</option>
                              {Array.isArray(this.state.CityData) &&
                                this.state.CityData.length > 0 &&
                                this.state.CityData.map((e, key) => {
                                  return (
                                    <option key={key} value={e.name}>
                                      {e.name}
                                    </option>
                                  );
                                })}
                            </select>
                            <div className="errorMsg">{this.state.errors.city}</div>
                            <div className="d-flex flex-row bd-highlight mb-1">
                              {this.state.cities.map((c) => (
                                <div
                                  className="p-1 bd-highlight"
                                  key={Math.floor(Math.random() * 9999999999)}
                                >
                                  <div
                                    onClick={() => {
                                      let _cities = this.state.cities;
                                      if (this.state.cities.length > 0) {
                                        _cities = _cities.filter((x) => x !== c);
                                        this.setState({ cities: _cities });
                                      }
                                    }}
                                  >
                                    <button className="btn-sm btn-primary ">
                                      {c}{" "}
                                      <i type="delete" id="btnSubmitSignup">
                                        ❌
                                      </i>
                                    </button>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>

                      </div>
                      <br />


                      <div className="row">

                        <div className="col-lg-6 col-md-6 col-6">
                          <div className="form-group">
                            <label htmlFor="location" className="control-label">
                              Is Registration over?
                            </label>
                            <select
                              className={`form-select ${this.state.errors.is_registration_over
                                ? "error"
                                : ""
                                }`}
                              name="is_registration_over"
                              value={this.state.is_registration_over}
                              onChange={this.handleFormChange}
                            >
                              <option value="">--Choose--</option>
                              <option value={true}>yes</option>
                              <option value={false}>no</option>
                            </select>
                            <div className="errorMsg">
                              {this.state.errors.is_registration_over}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-6">
                          <div className="form-group">
                            <label htmlFor="location" className="control-label">
                              Is Date Postpone?
                            </label>
                            <select
                              className={`form-select ${this.state.errors.isDatePostpone ? "error" : ""
                                }`}
                              name="isDatePostpone"
                              value={this.state.isDatePostpone}
                              onChange={this.handleFormChange}
                            >
                              <option value="">--Choose--</option>
                              <option value={true}>yes</option>
                              <option value={false}>no</option>
                            </select>
                            <div className="errorMsg">
                              {this.state.errors.isDatePostpone}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        {/* <button type="button" onClick={this.handleSubmit}  className="btn btn-primary btn-lg btn-block mb-2 mt-4" style={{ margin: "4px" }}>Update</button> */}
                        <button type="button" onClick={this.handleSubmit} className="btn btn-primary btn-lg btn-block mb-2 mt-4" style={{ margin: "4px" }}>{this.state.loader ? <LoaderRounded /> : "Update"}</button>
                        <button type="button" onClick={this.handleCalldelete} className="btn btn-danger btn-lg btn-block mb-2 mt-4" style={{ margin: "4px" }}>{this.state.loader ? <LoaderRounded /> : "Delete"}</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </>
    );
  }
}
