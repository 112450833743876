import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Edit, Add, Visibility } from '@material-ui/icons';
import Sidebar from '../Sidebar';
import Navbar from '../Navbar';
import Select from 'react-select';
import { Redirect } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import BackAndBreadCrmOneParent from "../BackAndBreadCumOneParent";
import LoaderRounded from "../LoaderRounded";

class CollegeList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            indexno: '',
            renderPageLimit: 20,
            selectedPage: 0,
            college_name: '',
            currentFilter: null,
            currentFilterBy: '',
            college: '',
            currentPage: '',
            counts: '',
            totalPages: '',
            previousLimits: 100,
            isChecked: false,
            mergeCollegeDetails: [],
            college_code: [],
            collegeCodes: [],
            college_key: [],
            state_code: '',
            city_code: '',
            state: '',
            city: '',
            StateData: [],
            CityData: [],
            errors: {},
            districtsLocal: JSON.parse(localStorage.getItem("districts")),
            subCityName: localStorage.getItem("sub_city_name") ? localStorage.getItem("sub_city_name") : "",
            dropDownOptCollegeList: [],
            isSelect: false,
            role: '',
            loader: false,
            mergeError: "",
            isMerge: false,

        }
        this.applyFiter = this.applyFilter.bind(this);
        this.setFilter = this.setFilter.bind(this);
        this.showCity = this.showCity.bind(this);
        this.getCollegeWithCity = this.getCollegeWithCity.bind(this);
        this.getCollege = this.getCollege.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.searchFromList = this.searchFromList.bind(this);
    }
    handlePageChange(page) {
        this.setState({ selectedPage: page.selected })
    }

    applyFilter(arr) {
        if (!this.state.currentFilter) {
            return arr;
        }
        let filteron = this.state.currentFilterBy;
        if (filteron === "city") {
            if (this.state.currentFilter === "Pune") {
                let subcity = localStorage.getItem("sub_city_name");
                if (subcity) {
                    return arr.filter((x) => x.sub_city === subcity);
                } else {
                    return arr.filter((x) => x.city === this.state.currentFilter);
                }
            } else {
                return arr.filter((x) => x.city === this.state.currentFilter);
            }

        } else {
            let a = this.state.currentFilter.toLowerCase();
            var condition = new RegExp(a);
            var result = arr.filter(function (el) {
                return condition.test(el.college_name.toLowerCase());
            });
            // var result = arr.filter( x => x.toLowerCase() === condition.toLowerCase())
            return result;

        }

    }

    setFilter(e) {
        e.preventDefault();
        this.setState({
            currentFilter: e.target.value
        })
    }

    showCity() {
        let districts = JSON.parse(localStorage.getItem("districts"));
        if (districts[0] !== "*") {
            this.setState({
                CityData: districts,
            });
        } else {
            axios.get(process.env.REACT_APP_APIURL + `/address/city/MH`).then(response => {
                this.setState({
                    CityData: response.data,
                })
            }).catch(error => {
                console.log("Error", error);
            });
        }


    }


    UNSAFE_componentWillMount() {
        this.showCity();
        this.getCollege();
        this.setState({ role: localStorage.getItem("role") });

    }

    getCollege = async () => {
        this.setState({
            loader: true
        });
        // let city = localStorage.getItem("city_name");
        let districts = JSON.parse(localStorage.getItem("districts"));
        let city;
        if (this.state.districtsLocal[0] === "*") {
            city = "Pune";
        } else {
            city = this.state.CityData[0].name;
        }

        if (districts.length) {
            const API = await axios.get(process.env.REACT_APP_VOTINGAPI + `/voting/coordinator/vote/count/city/` + city)
            const serverResponse = API.data
            const dropDownValue = serverResponse.map((response) => ({
                "value": response.college_name,
                "label": response.college_name,
            }));
            this.setState({
                dropDownOptCollegeList: dropDownValue,
                college: API.data,
                loader: false
            });

        } else {
            this.setState({
                loader: false
            })
        }
    }

    async getCollegeCIty(city) {
        this.setState({
            loader: true
        });
        const API = await axios.get(process.env.REACT_APP_VOTINGAPI + `/voting/coordinator/vote/count/city/` + city);
        const serverResponse = API.data
        const dropDownValue = serverResponse.map((response) => ({
            "value": response.college_name,
            "label": response.college_name,
        }));
        this.setState({
            dropDownOptCollegeList: dropDownValue,
            college: API.data,
            loader: false
        });

    }
    //on change event for college list dropdown
    onChangeForCollegeListDropDown(event) {
        this.setState(
            {
                college_code: event.value,
                college_name: event.label,
                isSelect: true,
            }
        )
        localStorage.setItem("collegeCode", event.value);
        localStorage.setItem("collegeName", event.label);
        localStorage.setItem("college_code", event.value);
        localStorage.setItem("college_name", event.label);


    }

    getCollegeWithCity(event) {
        this.getCollegeCIty(event.target.value);
    }


    searchFromList(event) {
        this.setState({
            currentFilter: event.target.value,
            currentFilterBy: event.target.name
        })

    }

    render() {

        if (!localStorage.getItem("mobile")) {
            return <Redirect to="/login" />
        }

        if (this.state.isSelect) {
            return <Redirect to='/view-card' />;
        }

        let collegeList = '';
        if (this.state.college.length > 0) {
            collegeList = this.state.college && this.applyFilter(this.state.college).map((colleges, index) => (
                <tr key={index} >
                    <td>{index + 1}</td>
                    <td>{colleges.college_name}</td>
                    <td className="text-center"><h3>{colleges.collegeVotes}</h3></td>
                </tr>
            ));
        }
        else {
            collegeList = "No Data Found"
        }

        let cities;
        if (this.state.districtsLocal[0] !== "*") {
            cities = this.state.CityData.map((e, key) => {
                return <option key={key} value={e}>{e}</option>;
            })
        } else {
            cities = this.state.CityData.map((e, key) => {
                return <option key={key} value={e.name}>{e.name}</option>;
            })
        }


        return (
            <>
                <Sidebar />
                <div className="main">
                    <div className="topbar">
                        <Navbar />
                    </div>

                    <div className="container">
                        <BackAndBreadCrmOneParent backLink="/dashboard" currentTabName="College list" />
                        <div className="row">
                            <div className="col-12">
                                <u><h2 className="text-center">District wise live election count</h2></u>
                                <br></br>
                            </div>

                            <div className="col-12">
                                <span><small className="p-4 errorMsg">{this.state.mergeError}</small></span>
                                <select type="button" name="city" className="mx-2 btn btn-secondary btn-md dropdown-toggle" onChange={this.getCollegeWithCity} id="district" style={{ float: "right", width: "120px" }}>
                                    <option value={null}> --District-- </option>
                                    {cities}
                                </select>

                            </div>

                        </div>
                        <div className="col-md-4">
                            {this.state.role === "COORDINATOR" ? "" :
                                <>
                                    <label htmlFor="college_names" className="control-label">Select College</label>
                                    <Select
                                        options={this.state.dropDownOptCollegeList}
                                        onChange={this.onChangeForCollegeListDropDown.bind(this)}
                                        Redirect="/view-card"
                                    />
                                </>
                            }

                        </div>
                    </div>
                    <br></br>
                    <div className="container">
                        {/* <input type="checkbox" className="form-check-input" onChange={this.handleCheckbox} /> */}
                        {/* <label className="form-check-label ms-2">Select All</label> */}
                        {this.state.loader ? <LoaderRounded /> :
                            <div className="row">
                                <div className="col-12">
                                    <div className="table-responsive-sm" style={{ maxHeight: 400, overflowY: 'scroll' }} >
                                        <table className="table table-bordered table-responsive" >
                                            <thead>
                                                <tr>
                                                    <th>No.</th>
                                                    <th>Collegename</th>
                                                    <th>Vote cast</th>
                                                </tr>
                                            </thead>
                                            <tbody >
                                                {collegeList.slice(this.state.selectedPage * this.state.renderPageLimit, this.state.renderPageLimit + (this.state.selectedPage * this.state.renderPageLimit))}
                                            </tbody>
                                        </table>

                                    </div>

                                </div>
                                <div className="mt-3"></div>
                                <ReactPaginate
                                    nextLabel="Next"
                                    previousLabel="Previous"
                                    pageCount={collegeList.length / this.state.renderPageLimit}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    containerClassName="pagination"
                                    activeClassName="btn btn-primary"
                                    pageClassName="btn"
                                    previousClassName="btn"
                                    nextClassName="btn"
                                    onPageChange={this.handlePageChange}
                                />
                            </div>}
                    </div>
                </div>
            </>
        )
    }
}

export default CollegeList;