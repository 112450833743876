import React, { Component } from "react";
import "../Assets/css/loader.css";

class LoaderRoundedWithText extends Component {
  render() {
    return (
      <div className="text-center">
        <div className="loader"></div>
        {/* <small>{this.props.loadingText?this.props.loadingText:"Please wait for second, we are processing..."}</small> */}
      </div>
    );
  }
}

export default LoaderRoundedWithText;
