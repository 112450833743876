import React, { Component } from 'react'
import axios from 'axios';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
// import Select from 'react-select';
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
// import LoaderRounded from "./LoaderRounded";

export default class LdpSelectedDistrict extends Component {
    constructor(props) {
        super(props);
        this.state = {
            indexno: '',
        }
    }


    async getDestrict() {
        let districtData = await axios.get(process.env.REACT_APP_APIURL + "/address/city");

    }
    render() {
        return (
            <>
                <Sidebar />
                <div className="main">
                    <div className="topbar">
                        <Navbar />
                    </div>

                    <div className="container">
                        <BackAndBreadCrmOneParent backLink="/dashboard" currentTabName="All candidate list" />

                        <h2 className="text-center">All Candidate List</h2>
                        <br />
                    </div>
                </div>

            </>
        )
    }
}
