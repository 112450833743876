import React from 'react'
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'
import { Col, Row } from 'react-bootstrap'
import { Add, Edit } from '@material-ui/icons'
import {Link } from 'react-router-dom'
import LoaderRounded from '../LoaderRounded'
import { useState } from 'react'
import axios from 'axios'
import { useEffect } from 'react'

const ElectionPhaseShow = () => {
  
    const [loader, setLoader] = useState(false)
    const [electPhaseData, setElectPhaseData] = useState()

    useEffect(()=>{
        getElectionPhaseData()
    },[])

    const getElectionPhaseData = async() =>{
        await axios.get(process.env.REACT_APP_APIURL + `/elctionphase/get`)
        .then((response) =>{
            setElectPhaseData(response?.data?.data)
        })
        .catch((error)=>console.log(error))
    }

return (
    <>
        <Sidebar/>
        <div className="main">
            <div className="topbar">
                <Navbar/>
            </div>

            <div className='container'>
            <Row md={12} className='mx-4 mt-3 mb-5'>
                <Col md={6} className='d-flex justify-content-end w-100'>
                <Link
                    to="/ElectionPhaseAdd"
                    className="btn btn-secondary"
                    style={{backgroundColor: "#003147", color: "white"}}>
                    <Add className="me-1"/>
                        Add New
                    </Link>
                </Col>
            </Row>
            </div>

            <div className="container" style={{paddingLeft:"0px", paddingRight:"0px"}}>
                {loader ? (
                  <LoaderRounded/>
                ) : (
                  <div className="row mx-2">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div
                        className="table-responsive-sm"
                      >
                        <table className="table table-bordered table-responsive table-hover">
                          <thead>
                            <tr>
                              <th>No.</th>
                              <th>Phase Name</th>
                              <th>Phase Image</th>
                              <th>Status</th>
                              <th>Published Status</th>
                              <th style={{ width: "120px" }}>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                          {
                            electPhaseData !== undefined && electPhaseData !== null && electPhaseData.length > 0 ? 
                            electPhaseData.map((itm, index) => {
                                return (
                                <tr key={index}> 
                                    <td>{index + 1}</td>
                                    <td>{itm?.name}</td>
                                    <td>{itm?.phaseimage}</td>
                                    <td className='text-center'>{JSON.parse(itm?.status) ? "True" : "False"}</td>
                                    <td className='text-center'>{JSON.parse(itm?.is_published) ? "True" : "False"}</td>
                                    <td>
                                        <Link to={{ pathname: "./ElectionPhaseEdit", state:{data: itm }}}>
                                        <button className="btn btn-outline-warning btn-sm">
                                          <Edit/>
                                        </button>
                                        </Link>
                                    </td>
                                </tr> 
                                );
                            }) : (
                                <tr>
                                <td colSpan="6">No data available</td>
                                </tr>
                            )
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
              </div>
        </div>
    </>
  )
}

export default ElectionPhaseShow
