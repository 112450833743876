import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom'
import axios from 'axios';
import LoaderRoundedWithText from "./LoaderRoundedWithText";

class ImageGovtIdPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            showVoteCastModal: false,
            government_id: '',
            error: '',
            loader: false
        };
        this.uploadDocument = this.uploadDocument.bind(this);
        this.onImageChange = this.onImageChange.bind(this);
    }

    isShowModal = () => {
        this.handleClose();
    }

    handleClose = () => {
        this.props.onPopupCloseMain();
    }

    onImageChange(e) {
        this.setState({
            government_id: e.target.files[0],
        });
    }

    uploadDocument() {
        this.setState({
            loader: true
        });
        let formDataObj = new FormData();
        formDataObj.append("yin_id", this.props.yin_id);
        formDataObj.append("document", this.state.government_id);
        // axios.post(process.env.REACT_APP_ALL_API+'/v1/candidate/upload/document',formData, {
        axios.post(process.env.REACT_APP_APIURL + `/users/update/document/web`, formDataObj, {
            headers: {
                "Content-type": "multipart/form-data",
            },
        }).then(res => {
            this.setState({
                loader: false
            });
            this.props.onPopupCloseMain();
        }).catch(error => {
            this.setState({
                loader: false,
                error: "image not uploaded!!!"
            });
            this.props.onPopupCloseMain();
        })
    }

    render() {
        return (
            <>
                <div className="DisputePopup" >
                    <Modal show={this.props.showGovtIdPopup} onHide={this.handleClose}
                        centered>
                        <Modal.Header closeButton><b>{this.props.collegeId_card}</b></Modal.Header>
                        <Modal.Body >
                            <div>
                                <label htmlFor="goverment_ID" id="goverment_ID" className="control-label">Government Id Photo</label>
                                <input type="file" id="form_photo" name="govt_image" className="form-control-file" onChange={this.onImageChange} />
                                {/* <img src={this.props.imageUrl} alt="collegeId"/> */}

                            </div>
                        </Modal.Body>
                        <Modal.Footer >
                            <div><b>{this.state.error}</b></div>
                            <div>{this.state.loader ? <LoaderRoundedWithText loadingText="Please wait image uploading..." /> : ""}</div>

                            <Button className="btn btn-sm rounded-pill border mb-2" onClick={this.uploadDocument}>Upload</Button>
                            <Button onClick={this.props.onPopupCloseMain} className="btn btn-sm rounded-pill DisputebtnCancel border mb-2">Close</Button>

                        </Modal.Footer>
                    </Modal>
                </div>
            </>
        )
    }
}

export default withRouter(ImageGovtIdPopup);