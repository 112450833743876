import React, { useEffect } from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import { Row, Col, Card, Button, Table } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import axios from "axios";
import LoaderRounded from "./LoaderRounded";
import { useState } from "react";

const ViewIssue = () => {
  const location = useLocation();
  const [data, setData] = useState("");

  useEffect(() => {
    setData(location.data);
  }, []);

  return (
    <>
      <div>
        <Sidebar />
        <div className="main">
          <div className="topbar">
            <Navbar />
          </div>
          <BackAndBreadCrmOneParent
            backLink="/Issue-List"
            backName="Issue-List"
            currentTabName="View Issue"
          />
          <div className="row mt-4">
            <div
              style={{
                heigh: "800vh",
                overflow: "hidden",
              }}
              className="col-lg-8 col-md-8 col-12 paddingAll10 mx-auto "
            >
              <div className="card rounded shadow shadow-sm ">
                <div className="card-header">
                  <h3 className="mb-0 center">View Issue</h3>
                </div>
                <div className="card-body">
                  {data ? (
                    <form>
                      <Table style={{ fontSize: 18, marginTop: 20 }}>
                        <tbody style={{ width: "190px" }}>
                          <tr>
                            <td
                              style={{
                                fontWeight: "bold",
                                display: "flex",
                                color: "#003147",
                              }}
                            >
                              {" "}
                              <span></span>Issue Title
                            </td>
                            <td>{data?.issue_title}</td>
                          </tr>

                          <tr>
                            <td
                              style={{
                                fontWeight: "bold",
                                display: "flex",
                                color: "#003147",
                                alignItems: "center",
                                height: "80px",
                              }}
                            >
                              <span></span>Issue Id
                            </td>
                            <td>{data && data.issue_id}</td>
                          </tr>

                          <tr>
                            <td
                              style={{
                                fontWeight: "bold",
                                display: "flex",
                                color: "#003147",
                              }}
                            >
                              <span></span>
                              Issue Full Description
                            </td>
                            <td>{data && data.issue_full_description}</td>
                          </tr>
                          <tr>
                            <td
                              style={{ fontWeight: "bold", color: "#003147" }}
                            >
                              <span></span>Issue Types
                            </td>
                            <td>{data && data.issue_types}</td>
                          </tr>

                          <tr>
                            <td
                              style={{ fontWeight: "bold", color: "#003147" }}
                            >
                              <span></span>Issue Images
                            </td>
                            <td>
                              <div
                                className="card"
                                style={{ margin: 10, width: 200 }}
                              >
                                {data.issue_images.length > 0
                                  ? data.issue_images.map((img) => {
                                    return (
                                      <div
                                        className="card"
                                      // style={{ margin: 10, width: 200 }}
                                      >
                                        <img
                                          src={img.url}
                                          key={img.url}
                                          className="card-img-top"
                                          alt="No Image"
                                          width="100px"
                                          height="100px"
                                        />
                                      </div>

                                      // <img
                                      //   id="image"
                                      //   src={item.url}
                                      //   alt="No Image"
                                      //   width="100px"
                                      //   height="80px"
                                      // />
                                    );
                                  })
                                  : ""}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </form>
                  ) : (
                    <LoaderRounded />
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <div style={{ display: "flex", justifyContent: "center" }}>
            <Card className="w-80 mt-5 p-5">
              <Card.Header>{data?.issue_title}</Card.Header>
              <Row>
                <Col xs={2}>
                  <h5>Issue Id</h5>
                </Col>
                <Col xs={1}>-</Col>
                <Col xs={9}>{data && data.issue_id}</Col>
              </Row>

              <Row>
                <Col xs={2}>
                  <h5>Issue full description</h5>
                </Col>
                <Col xs={1}>-</Col>
                <Col xs={9}>{data && data.issue_full_description}</Col>
              </Row>
              <Row>
                <Col xs={2}>
                  <h5>Issue Types</h5>
                </Col>
                <Col xs={1}>-</Col>
                <Col xs={9}>{data && data.issue_types}</Col>
              </Row>
            </Card>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default ViewIssue;
