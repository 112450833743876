import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'font-awesome/css/font-awesome.min.css';
import '../Assets/css/Dashboard.css';
import '../Assets/css/style.css';
import yinimg from '../Assets/images/yin.png';

export default class SidebarScanner extends Component {


    constructor(props) {
        super(props);
        this.state = {
            role: '',
        }
    }

    componentDidMount() {
        let role = localStorage.getItem('role');
        this.setState({ role })
    }


    render() {
        return (
            <>
                <div className="containers">
                    <div className="navigation" >
                        <ul>
                            <div className="divider-10" >
                                <img src={yinimg} alt="yinimages" width="50px" height="50px" />
                            </div>
                            <li className="divider-20" id={ window.location.pathname === '/scanner' ? 'active' : '' }>
                                <Link to="/scanner">
                                    <span className="icon"><i className="fa fa-qrcode"></i></span>
                                    <span className="title">QR Code scanner</span>
                                </Link>
                            </li>

                           
                            <li className="divider-20" id={ window.location.pathname === '/scanedbymobileno' ? 'active' : '' }>
                                <Link to="/scanedbymobileno">
                                    <span className="icon"><i className="fa fa-qrcode"></i></span>
                                    <span className="title">Scanned By Mobile</span>
                                </Link>
                            </li>

                            <li className="divider-10" id={ window.location.pathname === '/list/scanner' ? 'active' : '' }>
                                <Link to="/list/scanner">
                                    <span className="icon"><i className="fa fa-list"></i></span>
                                    <span className="title">Scanned list</span>
                                </Link>
                            </li>

                            <li className="divider-10" id={ window.location.pathname === '/send/event/sms' ? 'active' : '' }>
                                <Link to="/send/event/sms">
                                    <span className="icon"><i className="fa fa-paper-plane"></i></span>
                                    <span className="title">Send Event PDF SMS</span>
                                </Link>
                            </li>

                            


                        </ul>
                    </div>
                </div>
            </>
        )
    }
}
