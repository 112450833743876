import React, { Component } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import S3 from "react-aws-s3";
import Compressor from "compressorjs";
import moment from "moment";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import LoaderRounded from "./LoaderRounded";
import { Redirect } from "react-router-dom";

export default class Position extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      description: "",
      imageURL: [],
      category: "",
      eventLink: "",
      amplifierDate: "",
      eventLink: "",
      updatedBy: "",
      redirect: false,
      errors: {},
      loader: false,
    };
    this.uploadImageToS3 = this.uploadImageToS3.bind(this);

    this.deleteAmplifierData = this.deleteAmplifierData.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.getAmplifierData = this.getAmplifierData.bind(this);
  }
  componentDidMount() {
    this.getAmplifierData();
    // this.deleteAmplifierData();
    // this.handleCallupdate();
  }
  handleFormChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  addNewImage(newImage) {
    let img_arr = this.state.imageURL;
    img_arr.push(newImage);
    this.setState({
      imageURL: img_arr,
    });
  }

  removeImageURL(id) {
    let img_arr = this.state.imageURL;
    img_arr = img_arr.filter((img) => img.id !== id);
    this.setState({
      imageURL: img_arr,
    });
  }

  removePdfURL(id) {
    let pdf = this.state.event_documents;
    pdf = pdf.filter((pdf) => pdf.id !== id);
    this.setState({
      event_documents: pdf,
    });
  }

  uploadImageToS3(event) {
    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      dirName: "yin/imageURL",
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
      s3Url: process.env.REACT_APP_S3URL,
    };

    var fileInput = false;
    const image = event.target.files[0];
    if (image === undefined) return;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      if (
        event.target.files[0].type === "image/png" ||
        event.target.files[0].type === "image/jpeg"
      ) {
        new Compressor(image, {
          quality: 0.6,
          success: (compressedResult) => {

            let newFileName = event.target.files[0].name.replace(/\..+$/, "");
            const finalFileName = newFileName;
            const ReactS3Client = new S3(config);
            // const newFileName = 'test-file';

            ReactS3Client.uploadFile(compressedResult, newFileName)
              .then((res) => {
                this.addNewImage(res.location);
              })
              .catch((err) => console.error(err));
          },
        });
      }
    }
  }

  getAmplifierData() {
    let amplifier_id;
    let currentComponent = this;
    if (this.props.location.data) {
      localStorage.setItem("amplifier_id", this.props.location.data._id);
      amplifier_id = this.props.location.data._id;
    } else {
      amplifier_id = localStorage.getItem("amplifier_id");
    }
    const amplifierid = {
      amplifier_id: localStorage.getItem("amplifier_id"),
    };
    axios
      .post("https://yin-api.foxberry.link/v1/amplifier/get", amplifierid)
      .then((res) => {
        let responses = res.data;

        currentComponent.setState({
          //   redirect:true,
          ...responses[0],
        });
      });
  }

  deleteAmplifierData() {
    let amplifier_id;
    let currentComponent = this;
    if (this.props.location.data) {
      localStorage.setItem("amplifier_id", this.props.location.data._id);
      amplifier_id = this.props.location.data._id;
    } else {
      amplifier_id = localStorage.getItem("amplifier_id");
    }
    const amplifierid = {
      amplifier_id: localStorage.getItem("amplifier_id"),
    };
    axios
      .put(process.env.REACT_APP_APIURL + "/amplifier/delete", amplifierid)
      .then((res) => {
        window.confirm("Are sure want to delete data")
        let responses = res.data;
        currentComponent.setState({
          redirect: true,
        });
      });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/AmplifyList" />;
    }
    return (
      <>
        <Sidebar />
        <div className="main">
          <div className="topbar">
            <Navbar />
          </div>
          <div></div>
          <div className="container">
            <BackAndBreadCrmOneParent
              backLink="/AmplifyList"
              backName="AmplifyList"
              currentTabName="Add Amplify"
            />

            <div className="row mt-4">
              <div
                style={{
                  heigh: "800vh",
                  overflow: "hidden",
                }}
                className="col-lg-8 col-md-8 col-12 paddingAll10 mx-auto "
              >
                <div className="card rounded shadow shadow-sm ">
                  <div className="card-header">
                    <h3 className="mb-0 center">Delete Amplify</h3>
                  </div>
                  <div className="card-body">
                    <form>
                      <div className="form-group">
                        <label htmlFor="Amplify Name" className="control-label">
                          Amplify Title
                        </label>
                        <input
                          id="Amplify Name"
                          type="text"
                          className="form-control"
                          name="title"
                          value={this.state.title}
                          onChange={this.handleFormChange}
                          placeholder="Type here"
                        ></input>
                        <div className="errorMsg">{this.state.errors.name}</div>
                      </div>
                      <br />
                      <hr className="mx-n3" />

                      <div className="form-group">
                        <label htmlFor="Amplify Name" className="control-label">
                          Amplify Description
                        </label>
                        <input
                          id="Amplify Name"
                          type="text"
                          className="form-control"
                          name="description"
                          value={this.state.description}
                          onChange={this.handleFormChange}
                          placeholder="Type here"
                        ></input>
                        <div className="errorMsg">
                          {this.state.errors.description}
                        </div>
                      </div>
                      <br />
                      <hr className="mx-n3" />

                      <div className="form-group">
                        <label htmlFor="Amplify Name" className="control-label">
                          AmplifierDate
                        </label>
                        <input
                          id="Amplify Name"
                          type="text"
                          className="form-control"
                          name="amplifierDate"
                          value={this.state.amplifierDate}
                          onChange={this.handleFormChange}
                          placeholder="Type here"
                        ></input>
                        <div className="errorMsg">
                          {this.state.errors.amplifierDate}
                        </div>
                      </div>
                      <br />
                      <hr className="mx-n3" />

                      <div className="form-group">
                        <label htmlFor="Amplify Name" className="control-label">
                          Category
                        </label>
                        <input
                          id="Amplify Name"
                          type="text"
                          className="form-control"
                          name="category"
                          value={this.state.category}
                          onChange={this.handleFormChange}
                          placeholder="Type here"
                        ></input>
                        <div className="errorMsg">
                          {this.state.errors.amplifierDate}
                        </div>
                      </div>
                      <br />
                      <hr className="mx-n3" />

                      <div className="form-group">
                        <label htmlFor="Amplify Name" className="control-label">
                          EventLink
                        </label>
                        <input
                          id="Amplify Name"
                          type="text"
                          className="form-control"
                          name="eventLink"
                          value={this.state.eventLink}
                          onChange={this.handleFormChange}
                          placeholder="Type here"
                        ></input>
                        <div className="errorMsg">
                          {this.state.errors.eventLink}
                        </div>
                      </div>
                      <br />
                      <hr className="mx-n3" />

                      <div className="textOnInputs">
                        <div className="col-md-12 mt-3">
                          <label htmlFor="image">
                            <span style={{ color: "#ff0000" }}>*</span>
                            Promotional Campaign Photo
                          </label>

                          <input
                            type="file"
                            accept="image/png, image/gif, image/jpeg"
                            id="image"
                            name="college_id"
                            className="form-control-file"
                            onChange={this.uploadImageToS3}
                          />
                          {/* {this.state.imageURL ? <img id="image" src={this.state.imageURL} alt="" width="200px" height="80px" /> : ""} */}
                          <div className="errorMsg">
                            {this.state.errors.imageURL}
                          </div>
                        </div>

                        {this.state.imageURL.length === 0
                          ? null
                          : this.state.imageURL.map((img) => {
                            return (
                              <>
                                <div
                                  className="card"
                                  style={{ margin: 10, width: 200 }}
                                >
                                  <img
                                    src={img.url}
                                    key={img.url}
                                    className="card-img-top"
                                    alt={img.name}
                                    width="100px"
                                    height="100px"
                                  />
                                  <div class="card-body">
                                    <button
                                      type="delete"
                                      id="btnSubmitSignup"
                                      className="btn btn-danger btn-sm btn-block"
                                      onClick={() =>
                                        this.removeImageURL(img.id)
                                      }
                                    >
                                      delete
                                    </button>
                                  </div>
                                </div>
                                {/* <img style={{ margin: 10 }} width="100px" height="100px" key={img.url} src={img.url} alt={img.name} />
                                                                    <button type="delete" id="btnSubmitSignup" className="btn btn-danger btn-sm btn-block" onClick={() => this.removeImageURL(img.id)} >delete</button> */}
                              </>
                            );
                          })}
                      </div>
                      <br />
                      <hr className="mx-n3" />

                      <div className="form-group">
                        <button
                          type="button"
                          onClick={(event) => this.deleteAmplifierData(event)}
                          className="btn btn-primary btn-lg btn-block mb-2 mt-4"
                          style={{ margin: "4px" }}
                        >
                          Delete
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
