import React, { Component } from "react";
import axios from "axios";
// import Resizer from "react-image-file-resizer";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import LoaderRounded from "./LoaderRounded";
import { Label } from "@material-ui/icons";

class Addcoordinator extends Component {
  constructor(pops) {
    super(pops);

    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      mobile: "",
      gender: "",
      password: "",
      is_verified: "",
      //for dropdown purpose
      state_name: "",
      state_code: "",
      city: "",
      StateData: [],
      CityData: [],
      district: [],
      errors: [],
      loader: false,
    };
    this.initialState = this.state;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.ChangeCity = this.ChangeCity.bind(this);
    this.selectCity = this.selectCity.bind(this);
    this.addCity = this.addCity.bind(this);
    this.handleFormValidation = this.handleFormValidation.bind(this);
  }
  UNSAFE_componentWillMount() {
    this.showCity();
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  ChangeCity = (e) => {
    e.preventDefault();
    let index1 = e.target.selectedIndex;
    this.setState({
      state: e.target[index1].innerText,
    });

    axios
      .get(process.env.REACT_APP_TALK_API + "/address/city/" + e.target.value)
      .then((response) => {
        if (response.data.length > 0) {
          this.setState({
            CityData: response.data,
          });
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  showCity() {
    axios
      .get(process.env.REACT_APP_APIURL + "/address/city/MH")
      .then((response) => {
        if (Array.isArray(response.data) && response.data.length > 0) {
          this.setState({
            CityData: response.data,
          });
        }
      })
      .catch((error) => {
        console.log("city error", error);
      });
  }

  selectCity = (e) => {
    //    e.preventDefault();
    let city = e.currentTarget.value;
    this.addCity(city);
  };

  addCity(city) {
    let CityData = this.state.district;
    CityData.push(city);
    this.setState({
      district: CityData,
    });
  }

  handleFormValidation = () => {
    const {
      first_name,
      last_name,
      password,
      mobile,
      state_name,
      district,
      city,
      email,
      gender,
      is_verified,
    } = this.state;
    let errors = {};
    let formIsValid = true;

    //empty coordinator name
    // const regName = /^[a-zA-Z]+ [a-zA-Z]+$/
    let arr = [];
    if (!first_name) {
      errors["first_name"] = "*Please enter first name.";
      arr.push({
        field: "first_name",
      });
      formIsValid = false;
    } else if (!first_name.match(/^[a-zA-Z ]*$/)) {
      formIsValid = false;
      errors["first_name"] = "*Please enter alphabet characters only.";
      arr.push({
        field: "first_name",
      });
    }

    if (!last_name) {
      formIsValid = false;
      errors["last_name"] = "*Please enter last name.";
      arr.push({
        field: "last_name",
      });
    } else if (!last_name.match(/^[a-zA-Z ]*$/)) {
      formIsValid = false;
      errors["last_name"] = "*Please enter alphabet characters only.";
      arr.push({
        field: "last_name",
      });
    }
    if (!email) {
      errors["email"] = "*Please enter your email.";
      formIsValid = false;
      arr.push({
        field: "email",
      });
    } else if (
      !email.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      )
    ) {
      formIsValid = false;
      errors["email"] = "*Invalid email format Missing '@' symbol";
      arr.push({
        field: "email",
      });
    }
    if (!mobile) {
      formIsValid = false;
      errors["mobile"] = "*Please enter mobile no.";
      arr.push({
        field: "mobile",
      });
    } else if (!mobile.match(/[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/) && mobile.length !== 10) {
      formIsValid = false;
      errors["mobile"] = "*Please enter 10 digit mobile no.";
      arr.push({
        field: "mobile",
      });
    } else {
      // Clear the error when mobile is not empty and meets the criteria
      errors["mobile"] = "";
    }
    if (!password) {
      formIsValid = false;
      errors["password"] = "*Please enter Password.";
      arr.push({
        field: "password",
      });
    }

    if (!gender) {
      formIsValid = false;
      errors["gender"] = "*Please select gender.";
      arr.push({
        field: "gender",
      });
    }
    if (!city) {
      formIsValid = false;
      errors["city"] = "*Please choose city.";
      arr.push({
        field: "city",
      });
    }

    if (!district.length > 0) {
      formIsValid = false;
      errors["district"] = "*Please choose Assigned city/cities.";
      arr.push({
        field: "district",
      });
    }

    //empty state
    if (state_name === "" || state_name === "--Choose State--") {
      formIsValid = false;
      errors["state_name"] = "Please Choose State.";
      arr.push({
        field: "state_name",
      });
    }

    if (!is_verified) {
      formIsValid = false;
      errors["is_verified"] = "*Please enter is_verified.";
      arr.push({
        field: "is_verified",
      });
    }

    if (arr.length > 0) {
      const firstErrorField = document.querySelector(
        `[name="${arr[0].field}"]`
      );
      if (firstErrorField && firstErrorField.focus) {
        firstErrorField.focus();
      }
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  handleSubmit(event) {
    event.preventDefault();
    if (this.handleFormValidation()) {
      this.setState({
        loader: true,
      });
      const coordinator = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        password: this.state.password,
        mobile: this.state.mobile,
        gender: this.state.gender,
        district: this.state.district,
        state: this.state.state_name,
        city: this.state.city,
        state_code: this.state.state_code,
        is_verified: this.state.is_verified,
      };

      let statesSet = this;

      axios
        .post(
          process.env.REACT_APP_APIURL + `/coordinator/add-new`,
          coordinator
        )
        .then((res) => {
          this.setState({
            loader: false,
          });
          if (res.status === 200 && res.data.verified) {
            this.props.history.push("/coordinator");
            alert("Coordinator added successfully.");

            statesSet.setState({
              coordinator: res.data,
              changeToView: true,
              redirect: true,
            });
          } else {
            alert(res.data);
          }
        })
        .catch((err) => {
          this.setState({
            loader: false,
          });
          console.log("err in handlesubmit", err);
        });
    } else {
      this.handleFormValidation();
    }
  }

  render() {
    if (
      !this.props.UserData.mobile === "9637972851" &&
      !this.props.UserData.mobile === "7517096209"
    ) {
      return <Redirect to="/login" />;
    }

    // const CityData={};
    // const AllcollageData={};

    return (
      <>
        <Sidebar />
        <div className="main">
          <div className="topbar">
            <Navbar />
          </div>
          <div className="container">
            <BackAndBreadCrmOneParent
              backLink="/coordinator"
              backName="Coordinator List"
              currentTabName="Add new coordinator"
            />
            <div className="row mt-3">
              <div className="col-12 col-lg-10 col-md-10 m-auto">
                <div className="card rounded shadow shadow-sm">
                  <div className="card-header">
                    <h3 className="mb-0">Add New Coordinator</h3>
                  </div>
                  <div className="card-body mt-2">
                    <form id="Addcoordinator">
                      <div className="row mb-2">
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="form-group">
                            <label htmlFor="first_name" className="control-label">
                              First Name <span style={{ color: "#ff0000" }}>*</span>
                            </label>
                            <input
                              type="text"
                              id="first_name"
                              name="first_name"
                              className="form-control rounded-1"
                              value={this.state.first_name}
                              onChange={this.handleChange}
                              placeholder="Enter your first name"
                              required
                            />
                            <div className="errorMsg">
                              {this.state.errors.first_name}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="form-group">
                            <label htmlFor="last_name" className="control-label">
                              Last Name <span style={{ color: "#ff0000" }}>*</span>
                            </label>
                            <input
                              type="text"
                              id="last_name"
                              name="last_name"
                              className="form-control rounded-1"
                              value={this.state.last_name}
                              onChange={this.handleChange}
                              placeholder="Enter your last name"
                              required
                            />
                            <div className="errorMsg">
                              {this.state.errors.last_name}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row mb-2">
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="form-group">
                            <label
                              htmlFor="email"
                              id="currentadd"
                              className="control-label"
                            >
                              Email Address
                              <span style={{ color: "#ff0000" }}>*</span>
                            </label>
                            <input
                              type="text"
                              id="email"
                              name="email"
                              value={this.state.email}
                              onChange={this.handleChange}
                              className="form-control"
                              placeholder="abc@gmail.com"
                              required
                            />
                            <div className="errorMsg">
                              {this.state.errors.email}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="form-group">
                            <label
                              htmlFor="mobile"
                              id="mobile"
                              className="control-label"
                            >
                              Mobile Number
                              <span style={{ color: "#ff0000" }}>*</span>
                            </label>
                            <input
                              name="mobile"
                              className="form-control"
                              value={this.state.mobile}
                              onChange={this.handleChange}
                              placeholder="Enter mobile number"
                              maxLength={10}
                            />
                            <div className="errorMsg">
                              {this.state.errors.mobile}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row mb-2">
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="form-group">
                            <label
                              htmlFor="password"
                              id="password"
                              className="control-label"
                            >
                              Password<span style={{ color: "#ff0000" }}>*</span>
                            </label>
                            <input
                              type="text"
                              name="password"
                              value={this.state.password}
                              onChange={this.handleChange}
                              className="form-control"
                            ></input>
                            <div className="errorMsg">
                              {this.state.errors.password}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-6">
                          <div>
                            {" "}
                            Gender &nbsp;&nbsp;
                            <br />
                            <input
                              style={{ marginTop: "10px" }}
                              type="radio"
                              id="html"
                              name="gender"
                              value="male"
                              onChange={this.handleChange}
                            />

                            <label for="male">Male</label>
                            <input
                              type="radio"
                              id="female"
                              name="gender"
                              value="female"
                              onChange={this.handleChange}
                              style={{
                                marginLeft: "5px",
                              }}
                            />
                            <label for="female">Female</label>
                            <input
                              type="radio"
                              id="other"
                              name="gender"
                              value="other"
                              onChange={this.handleChange}
                              style={{
                                marginLeft: "5px",
                              }}
                            />
                            <label for="javascript">Other</label>
                            <div className="errorMsg">
                              {this.state.errors.gender}
                            </div>
                          </div>

                        </div>
                      </div>

                      <div className="row mb-2">
                        <div className="col-lg-6 col-md-6 col-12">
                          <div id="stateDiv" className="form-group" style={{ marginTop: "0px" }}>
                            <label htmlFor="states" className="control-label">
                              Select State
                              <span style={{ color: "#ff0000" }}>*</span>
                            </label>
                            <select
                              id="states"
                              className="form-select"
                              name="state"
                              onChange={(e) => {
                                this.setState({
                                  state_code: e.target.value,
                                  state_name: "Maharashtra",
                                });
                              }}
                            >
                              <option>--Choose State--</option>
                              <option value="MH">Maharashtra</option>
                              {/* <option value="GA">Goa</option> */}
                            </select>
                            <div className="errorMsg">
                              {this.state.errors.state_name}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="form-group">
                            <label
                              htmlFor="city"
                              id="city"
                              className="control-label"
                            >
                              City<span style={{ color: "#ff0000" }}>*</span>
                            </label>
                            <select
                              type="text"
                              name="city"
                              value={this.state.city}
                              onChange={this.handleChange}
                              className="form-select"
                            >
                              <option value={null}>--Choose City--</option>
                              {this.state.CityData?.map((e, key) => {
                                return (
                                  <option key={key} value={e.name}>
                                    {e.name}
                                  </option>
                                );
                              })}
                            </select>
                            <div className="errorMsg">
                              {this.state.errors.city}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row mb-2">
                        <div className="col-lg-6 col-md-6 col-12">
                          <div
                            className="form-group"
                          >
                            <label
                              htmlFor="citys"
                              id="cityLabel"
                              className="control-label"
                            >
                              Select Assigned City
                              <span style={{ color: "#ff0000" }}>*</span>
                            </label>
                            <select
                              id="citys"
                              name="district"
                              className="form-select"
                              type="button"
                              onChange={this.selectCity}
                            >
                              <option value={null}>
                                --Choose City/Cities--
                              </option>
                              {Array.isArray(this.state.CityData) &&
                                this.state.CityData.length > 0 &&
                                this.state.CityData?.map((e, key) => {
                                  return (
                                    <option key={key} value={e.name}>
                                      {e.name}
                                    </option>
                                  );
                                })}
                            </select>
                            <div className="errorMsg">
                              {this.state.errors.district}
                            </div>
                            <div className="d-flex flex-row bd-highlight mb-1">
                              {this.state.district.map((c) => (
                                <div
                                  className="p-1 bd-highlight"
                                  key={Math.floor(Math.random() * 9999999999)}
                                >
                                  <div
                                    onClick={() => {
                                      let _district = this.state.district;
                                      if (this.state.district.length > 0) {
                                        _district = _district.filter(
                                          (x) => x !== c
                                        );
                                        this.setState({ district: _district });
                                      }
                                    }}
                                  >
                                    <button className="btn-sm btn-primary ">
                                      {c}{" "}
                                      <i type="delete" id="btnSubmitSignup">
                                        ❌
                                      </i>
                                    </button>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="form-group">
                            <label
                              htmlFor="is_verified"
                              id="is_verified"
                              className="control-label"
                            >
                              Verification Status
                              <span style={{ color: "#ff0000" }}>*</span>
                            </label>
                            <br />
                            <select
                              id="verified"
                              name="is_verified"
                              className="form-select"
                              value={this.state.is_verified}
                              onChange={this.handleChange}

                            >
                              {" "}
                              <option value={null}> Select </option>
                              <option value={true}>True</option>
                              <option value={false}>False</option>
                            </select>
                            <div className="errorMsg">
                              {this.state.errors.is_verified}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className=" col-12 col-md-12 d-flex justify-content-center align-items-center">
                          <button
                            type="button"
                            id="btnSubmitSignup"
                            onClick={this.handleSubmit}
                            className="btn btn-dark btn-lg btn-block mt-4 m-auto"
                          >
                            {this.state.loader ? <LoaderRounded /> : "Submit"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    UserData: state.PersistReducer.UserData.UserData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Addcoordinator);
