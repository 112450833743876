import React, { useState } from 'react';
import Sidebar from '../../Sidebar';
import Navbar from '../../Navbar';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import "../../../Assets/css/Dashboard.css";
import "../../../Assets/css/card.css";
import { Link } from "react-router-dom/cjs/react-router-dom";

const ElectionPage = () => {
    const [showEdit, setShowEdit] = useState(false);
    const [loader, setLoader] = useState(false);

    const closeModal = () =>{
        setShowEdit(false)
    }

  return (
    <>
        <Sidebar/>
            <div className="main">
                <div className="topbar">
                    <Navbar title="Election"/>
                </div>

                <div className="container">
                    <Row md={12} className="mb-4 mt-5 mx-2">
                        <Col md={3} className="mb-4 sm-mb-3">
                            {/* <Link to="/LDPStart" style={{ color: "black"}}> */}
                            <div className="card-container">
                                <div className="card-header d-flex p-3 justify-content-center rounded">
                                <Icon.ArrowUpRightCircle className="me-3" size={24} />
                                <h5>LDP Starts for all Colleges</h5>
                                </div>
                            </div>
                            {/* </Link> */}
                        </Col>

                        <Col md={3} className="mb-4 sm-mb-3">
                            {/* <Link to="/ElectionStart" style={{ color: "black"}}> */}
                            <div className="card-container">
                                <div className="card-header d-flex p-3 justify-content-center rounded">
                                <Icon.CheckCircle className="me-3" size={26} />
                                <h5>Election starts for all Colleges</h5>
                                </div>
                            </div>
                            {/* </Link> */}
                        </Col>

                        <Col md={3} className="mb-4 sm-mb-3">
                        {/* <Link to="/ElectionActive" style={{ color: "black"}}> */}
                            <div className="card-container">
                                <div className="card-header d-flex p-3 justify-content-center rounded">
                                    <Icon.RecordCircle className="me-3" size={26} />
                                    <h5>Set election active years</h5>
                                </div>
                            </div>
                        {/* </Link> */}
                        </Col>

                        <Col md={3} className="mb-4 sm-mb-3">
                        {/* <Link to="/ElectionVoter" style={{ color: "black"}}> */}
                            <div className="card-container">
                                <div className="card-header d-flex p-3 justify-content-center rounded">
                                    <Icon.BuildingExclamation className="me-3" size={26} />
                                    <h5>Is election voter for all colleges</h5>
                                </div>
                            </div>
                        {/* </Link> */}
                        </Col>

                        <Col md={3} className="mb-4 sm-mb-3">
                        {/* <Link to="/ElectionOver" style={{ color: "black"}}> */}
                            <div className="card-container">
                                <div className="card-header d-flex p-3 justify-content-center rounded">
                                    <Icon.StopCircle className="me-3" size={26} />
                                    <h5>Election Over for All College</h5>
                                </div>
                            </div>
                        {/* </Link> */}
                        </Col>

                        <Col md={3} className="mb-4 sm-mb-3">
                        <Link to="/ViewVoterRegistr" style={{ color: "black"}}>
                            <div className="card-container">
                                <div className="card-header d-flex p-3 justify-content-center rounded">
                                    <Icon.ViewList className="me-3" size={26} />
                                    <h5>View voter registration</h5>
                                </div>
                            </div>
                        </Link>
                        </Col>

                        <Col md={3} className="mb-4 sm-mb-3">
                        {/* <Link to="/VoterRegistrStart" style={{ color: "black"}}> */}
                            <div className="card-container">
                                <div className="card-header d-flex p-3 justify-content-center rounded">
                                    <Icon.PencilSquare className="me-3" size={26} />
                                    <h5>Voter Registration Start</h5>
                                </div>
                            </div>
                        {/* </Link> */}
                        </Col>

                        <Col md={3} className="mb-4 sm-mb-3">
                        {/* <Link to="/ResultAvailable" style={{ color: "black"}}> */}
                            <div className="card-container">
                                <div className="card-header d-flex p-3 justify-content-center rounded">
                                    <Icon.List className="me-3" size={26} />
                                    <h5>Is Result available</h5>
                                </div>
                            </div>
                        {/* </Link> */}
                        </Col>

                        <Col md={3} className="mb-4 sm-mb-3">
                        {/* <Link to="/DisputeOpen" style={{ color: "black"}}> */}
                            <div className="card-container">
                                <div className="card-header d-flex p-3 justify-content-center rounded">
                                    <Icon.LightningCharge className="me-3" size={26} />
                                    <h5>Is Dispute Open</h5>
                                </div>
                            </div>
                        {/* </Link> */}
                        </Col> 

                        <Col md={3} className="mb-4 sm-mb-3">
                        {/* <Link to="/ElectionPhase" style={{ color: "black"}}> */}
                            <div className="card-container">
                                <div className="card-header d-flex p-3 justify-content-center rounded">
                                    <Icon.PinMap className="me-3" size={26} />
                                    <h5>Set election phase</h5>
                                </div>
                            </div>
                        {/* </Link> */}
                        </Col>

                        <Col md={3} className="mb-4 sm-mb-3">
                        {/* <Link to="/AddVoterRegisterMsg" style={{ color: "black"}}> */}
                            <div className="card-container" onClick={()=>{setShowEdit(true)}}>
                                <div className="card-header d-flex p-3 justify-content-center rounded">
                                    <Icon.EnvelopeCheck className="me-3" size={26} />
                                    <h5>Add voter Registration message</h5>
                                </div>
                            </div>
                        {/* </Link> */}
                        </Col>
                    </Row>
                </div>
            </div> 

        {/* Add Voter registration page model */}
        <Modal size='md' show={showEdit} backdrop='static' keyboard={false} centered onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title> <h4 className='mb-0'><Icon.EnvelopeCheck size={20} className='me-2'/>Add Voter Registration Message</h4> </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Message<span style={{color:"red"}}> *</span></Form.Label>
                            <Form.Control placeholder='Enter Message' 
                            // onChange={(e)=> {if (
                            //     !e.target.value.match(/[0-9+@#$&%!~=^_:(){}\[\]|/*./\//\-/?<>,;`'""/]/) &&  e.target.value.trim() ) {
                            //         setFirstName(e.target.value);
                            //     } else if (e.target.value.length === 0) {
                            //         setFirstName(e.target.value);
                            //     }
                            //  e.target.value.length > 0 ? setFisrtNameErr(false) : setFisrtNameErr(true); }}
                              />
                            {/* {fisrtNameErr ? <p className='errMsg'>Please enter name</p> : ''} */}
                        </Form.Group> 
                        </Col>

                        {/* <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Last name<span className='errMsg'>*</span></Form.Label>
                            <Form.Control placeholder='Enter last name' name='lastName' value={lastName} onChange={(e)=> {if (
                                !e.target.value.match(/[0-9+@#$&%!~=^_:(){}\[\]|/*./\//\-/?<>,;`'""/]/) &&  e.target.value.trim() ) {
                                    setLastName(e.target.value);
                                } else if (e.target.value.length === 0) {
                                    setLastName(e.target.value);
                                }
                             e.target.value.length > 0 ? setLastNameErr(false) : setLastNameErr(true); }} />
                            {lastNameErr ? <p className='errMsg'>Please enter valid name</p> : ''}
                        </Form.Group> 
                        </Col> */}
                    </Row>   
                </Modal.Body>
  
                <Modal.Footer>
                    <Button className='primaryBtn' disabled={loader}> 
                    { loader ? <Spinner size='sm'/> : <Icon.PlusCircleFill className='me-2'/> }Add</Button>
                </Modal.Footer>
            </Modal>
    </>
  )
}

export default ElectionPage
