
import React from 'react';
import axios from 'axios';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';
import { Add } from '@material-ui/icons';
import LoaderRounded from "./LoaderRounded";
import profileImage from "../Assets/images/noimahe.png";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import ReactPaginate from 'react-paginate';
import * as XLSX from 'xlsx';
import moment from 'moment';
import EventTransfer from './EventTransfer';
export default class GetMemberList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            event_id: localStorage.getItem("eventid") ? localStorage.getItem("eventid") : '',
            first_name: '',
            event: JSON.parse(localStorage.getItem('selected_event')),
            college_id: '',
            last_name: '',
            profile_image: [],
            college_name: '',
            city: '',
            cities: [],
            memberList: [],
            CityData: [],
            loader: false,
            counts: '',
            totalPages: '',
            previousLimits: 100,
            renderPageLimit: 20,
            selectedPage: 0,
            eventList: [],
            eventLoader: false
        };
        this.listBycity = this.listBycity.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.CustomExcel = this.CustomExcel.bind(this)
        this.downloadExcel = this.downloadExcel.bind(this)
        this.getEventList = this.getEventList.bind(this)
    }

    handlePageChange(page) {
        this.setState({ selectedPage: page.selected })
    }

    getEventList = async () => {
        this.setState({
            eventLoader: true
        })
        await axios.get(process.env.REACT_APP_TALK_API + '/event/all/eventhead/web')
            .then((res) => {
                this.setState({
                    eventList: res.data.data,
                    eventLoader: false
                })
            })
    }

    componentWillMount() {

        setTimeout(() => this.setState({
            loader: false, msg: false
        }), 2500);
        setTimeout(() => this.setState({ loading: false }), 1000);
        //this.getEventList()

    }
    //code for to get member lists
    UNSAFE_componentWillMount() {
        this.getMemberListbyeventid();
        //  this.showCity();
    }

    showCity() {
        axios.get(process.env.REACT_APP_APIURL + "/address/city/MH").then(response => {
            this.setState({
                CityData: response.data,
            });
        }).catch(error => {
            console.log("Error", error);
        });
    }
    selectCity = (e) => {
        //    e.preventDefault();
        let city = e.currentTarget.value;

    }
    // ChangeCity = (e) => {
    //     e.preventDefault();
    //     let index1 = e.target.selectedIndex;
    //     this.setState({
    //         state: e.target[index1].innerText
    //     })
    //     axios.get(process.env.REACT_APP_APIURL +`/address/city/MH` + e.target.value).then(response => {
    //         this.setState({
    //             CityData: response.data,
    //         })
    //     }).catch(error => {
    //         console.log("Error", error);
    //     });
    // }


    selectCity = (e) => {
        //    e.preventDefault();
        let city = e.currentTarget.value;
        this.addCity(city)
    }

    listBycity = async (event) => {

        event.preventDefault();
        this.setState({

            city: event.target.value,
            memberList: [],
        });

        let currentComponent = this;
        await axios.get(process.env.REACT_APP_TALK_API + "event/get/event/member-details?EventId=" + this.state.event.event_id + `&city=` + event.target.value).then(res => {
            currentComponent.setState({
                memberList: res.data.data,

            });

        });
    }

    getMemberListbyeventid() {
        // let eventid;
        let currentComponent = this;
        axios.get(process.env.REACT_APP_TALK_API + `/event/get/event/member-details/` + this.state.event.event_id)
            .then(res => {
                // let responses = res.data;
                let memberList = res.data.data;
                currentComponent.setState({
                    memberList: memberList,
                    loader: false,
                });
            });

    }

    handleFormChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    downloadExcel = (customHeadings) => {
        let dataSet = [];
        dataSet = customHeadings;
        const worksheet = XLSX.utils.json_to_sheet(dataSet);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, this.state.event.event_title + "_Report.xlsx");
    };

    CustomExcel = () => {

        const customHeadings = this.state.memberList.length > 0 && this.state.memberList.map(item => ({
            "Event Name": this.state.event.event_title,
            "Name": item.first_name && item.last_name ? item.first_name + " " + item.last_name : '-',
            "Mobile": item.mobile ? item.mobile : '-',
            "Email": item.email ? item.email : '-',
            "Gender": item.gender ? item.gender : '-',
            "City": item.city ? item.city : '-',
            "College Name": item.college_name ? item.college_name : '-',
            "Reg. Date": item.registration_date ? moment(item.registration_date).format("DD MMM YYYY hh:mm a") : '-'
        }))
        this.downloadExcel(customHeadings)
    };

    render() {
        let member_list = '';
        let i = 0;
        if (this.state.memberList) {
            member_list = this.state.memberList.map(
                memberList =>
                    memberList ?
                        <tr key={memberList.yin_id}>
                            <td>{i += 1}</td>
                            <td style={{ display: 'flex', justifyContent: 'center' }}>
                                {memberList?.profile_image?.length > 0 ?
                                    <img src={memberList?.profile_image} width="70" height="70" style={{ borderRadius: '50%' }} alt="profilephoto" />
                                    :
                                    <img src={profileImage} width="70" height="70" style={{ borderRadius: '50%' }} alt="noprofile" />
                                }
                            </td>
                            <td> {memberList.first_name} {memberList.last_name}</td>
                            {/* <td>{memberList.profile_image}</td> */}
                            <td> {memberList.mobile} </td>
                            <td> {memberList.city} </td>
                            <td> {memberList.college_name} </td>
                            <td>
                                <EventTransfer tranferFrom={memberList} eventId={this.state.event_id} eventList={this.state.eventList} />
                            </td>
                        </tr> : ""
            )
        }
        else {
            member_list =
                <tr>
                    <td>No Data Found....</td>
                </tr>
        }


        return (
            <>
                <div>
                    <Sidebar />
                    <div className="main">
                        <div className="topbar">
                            <Navbar />
                        </div>

                        <div>

                            <Link to="/subevent-attendee" className="btn btn-secondary btn-sm" style={{ float: "right", marginRight: "40px", marginTop: "20px" }}><Add />Add New Attendee</Link>

                            {/* <select type="button" className="mx-2 btn btn-secondary btn-md dropdown-toggle" id="dropdown" style={{ float: "right", marginRight: "40px", marginTop: "20px" }} onChange={this.listBycity}>
                                    <option value={null}> -- City-- </option>
                                    {this.state.CityData.map((e, key) => {
                                        return <option key={key} value={e.name}>{e.name}  </option>;
                                    })}
                                </select> */}

                            <button className='btn btn-dark btn-sm float-right' style={{ float: "right", marginTop: "20px", marginRight: "5px" }} onClick={this.CustomExcel}>Export Data</button>


                        </div>

                        <div className="container">
                            <BackAndBreadCrmOneParent backLink="/Subevent-List" backName="SubEvent List" currentTabName="Member List" />
                            {/* <BackAndBreadCrmOneParent backLink="/view-card" backName="View card" currentTabName="Election List"/> */}
                            <div className="row">
                                <div className="col-12">
                                    <h2 className="text-center">{this.state.event.event_title}</h2>
                                    <hr />

                                </div>
                                <div className="col-12">
                                    <h4 className="text-center">Member List</h4>
                                </div>
                            </div>
                        </div>
                        <br></br>
                        <div className="container">

                            <div className="row">
                                <div className="col-12">
                                    <div className="table-responsive-sm">
                                        <table className="table table-bordered table-responsive">
                                            <thead>
                                                <tr className="text-center">
                                                    <th>No</th>
                                                    <th>Full Name</th>
                                                    <th>Profile Image</th>

                                                    <th>Mobile</th>
                                                    <th>City</th>
                                                    <th>College Name</th>
                                                    <th>Action</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.loader || this.state.eventLoader ? <LoaderRounded /> : member_list
                                                }


                                            </tbody>
                                        </table>

                                    </div>

                                </div>
                                {this.state.loader || this.state.eventLoader ? null :
                                    <ReactPaginate
                                        nextLabel="Next"
                                        previousLabel="Previous"
                                        pageCount={member_list.length / this.state.renderPageLimit}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        containerClassName="pagination"
                                        activeClassName="btn btn-primary"
                                        pageClassName="btn"
                                        previousClassName="btn"
                                        nextClassName="btn"
                                        onPageChange={this.handlePageChange}
                                    />}

                            </div>

                        </div>
                        <button className=" reaload btn  btn-success btn-sm " onClick={() => window.location.reload(true)} >Click to reload!</button>
                    </div>
                </div>
            </>
        )
    }
}
