import React from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import LoaderRoundedWithText from "./LoaderRoundedWithText";
import "../Assets/css/Collagename.css";
import { Col, Row } from "react-bootstrap";

class CoordinatorWiseCountList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collegeData: [],
      CityData: [],
      loader: true,
      loading: true,
      currentFilter: "",
      currentFilterBy: "",
      errors: "",
      isError: false,
      districtsLocal: JSON.parse(localStorage.getItem("districts")),
    };
    this.applyFiter = this.applyFilter.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.showCity = this.showCity.bind(this);
    this.getData = this.getData.bind(this);
    this.storeCollegeCode = this.storeCollegeCode.bind(this);
    this.searchFromList = this.searchFromList.bind(this);
    this.getCollegeWithCity = this.getCollegeWithCity.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.showCity();
    this.getData();
  }
  componentWillMount() {
    setTimeout(() => this.setState({ loader: false, msg: false }), 2000);
    setTimeout(() => this.setState({ loading: false }), 1000);
  }

  getCollegeWithCity(event) {
    this.setState({
      currentFilter: event.target.value,
      currentFilterBy: event.target.name,
    });
  }

  storeCollegeCode(collegeCode, collegeName) {
    localStorage.setItem("collegeCode", collegeCode);
    localStorage.setItem("college_code", collegeCode);
    localStorage.setItem("college_name", collegeName);
  }

  getData() {
    // let subcity = localStorage.getItem("sub_city_name");
    // let cityData = {
    //     city:this.state.districtsLocal,
    //     sub_city:subcity
    // }
    axios
      .get(
        process.env.REACT_APP_APIURL + "/candidate/get/coordinaor/wise/count"
      )
      .then((res) => {
        let response = res.data;
        this.setState({
          collegeData: response,
          loader: false,
          errors: "",
        });
      })
      .catch((error) => {
        this.setState({
          collegeData: "",
          loader: false,
          isError: true,
          errors:
            "While executing query it will take more time to execute. Please refresh page or try again later...",
        });
        console.log("Error", error);
      });
  }

  showCity() {
    let districts = JSON.parse(localStorage.getItem("districts"));
    if (districts[0] !== "*") {
      this.setState({
        CityData: districts,
      });
    } else {
      axios.get(process.env.REACT_APP_APIURL + `/address/city/MH`)
        .then((response) => {
          this.setState({
            CityData: response.data,
          });
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }
  }

  applyFilter(arr) {
    if (!this.state.currentFilter) {
      return arr;
    }
    let filteron = this.state.currentFilterBy;
    if (filteron === "city") {
      if (this.state.currentFilter === "Pune") {
        let subcity = localStorage.getItem("sub_city_name");
        if (subcity) {
          return arr.filter((x) => x.sub_city === subcity);
        } else {
          return arr.filter((x) => x.districts === this.state.currentFilter);
        }
      } else {
        return arr.filter((x) => x.first_name === this.state.currentFilter);
      }
    } else {
      var condition = new RegExp(this.state.currentFilter);
      var result = arr.filter(function (el) {
        return condition.test(el.first_name);
      });
      return result;
    }
  }

  setFilter(e) {
    e.preventDefault();
    this.setState({
      currentFilter: e.target.value,
    });
  }

  searchFromList(event) {
    this.setState({
      currentFilter: event.target.value,
      currentFilterBy: event.target.name,
    });
  }

  render() {
    let collegeList =
      this.state.collegeData &&
      this.applyFilter(this.state.collegeData).map((colleges, index) => (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>
            {colleges.first_name} {colleges.last_name}
          </td>
          <td>
            {" "}
            {colleges.districts}{" "}
            {colleges.sub_city ? <>({colleges.sub_city})</> : ""}
          </td>
          <td>
            {" "}
            <div className="numberCircle-center_count1">
              {colleges.phase1_candidate}
            </div>
          </td>
          <td>
            {" "}
            <div className="numberCircle-center_count1">
              {colleges.phase2_candidate}
            </div>
          </td>
          {/* <td><div className="numberCircle-center_count2">{colleges.phase1_user}</div></td>
                            <td><div className="numberCircle-center_count2">{colleges.phase2_user}</div></td> */}
          {/* <td className="text-center">
                                <Link to="/view-card" ><Visibility /></Link>
                            </td> */}
        </tr>
      ));

    let cities;
    if (this.state.districtsLocal[0] !== "*") {
      cities = this.state.CityData.map((e, key) => {
        return (
          <option key={key} value={e}>
            {e}
          </option>
        );
      });
    } else {
      cities = this.state.CityData.map((e, key) => {
        return (
          <option key={key} value={e.name}>
            {e.name}
          </option>
        );
      });
    }

    return (
      <>
        {this.state.loading ? (
          <LoaderRoundedWithText />
        ) : (
          <div>
            <Sidebar />
            <div className="main">
              <div className="topbar">
                <Navbar title="Coordinator wise count" />
              </div>

              <div className="container">
                <BackAndBreadCrmOneParent
                  backLink="/electioncountcards"
                  currentTabName="Coordinator wise count"
                />

                {/* <br></br> */}
                {/* <div className="row m-4">
                  <div className="col-6">
                    <input
                      type="text"
                      placeholder="Search here"
                      name="search"
                      onChange={this.searchFromList}
                      className="form-control"
                    ></input>
                  </div>

                  <div className="col-6">
                    <select
                      type="button"
                      name="city"
                      className="btn btn-secondary btn-md dropdown-toggle"
                      onChange={this.getCollegeWithCity}
                      id="district"
                      style={{ float: "right", width: "120px" }}
                    >
                      <option value={null}> --District-- </option>
                      {cities}
                    </select>
                  </div>
                </div> */}

                <Row md={12} className="mx-2 mt-2 mb-5">
                  <Col md={5}>
                    <input
                      type="text"
                      placeholder="Search here"
                      name="search"
                      onChange={this.searchFromList}
                      className="form-control w-75"
                    ></input>
                  </Col>

                  <Col md={7}>
                    <Row md={12} className="d-flex justify-content-end">
                      <Col md={4}>
                        <select
                          type="button"
                          name="city"
                          className="btn btn-md dropdown-toggle w-100"
                          style={{ backgroundColor: "#003147", color: "white" }}
                          onChange={this.getCollegeWithCity}
                          id="district"
                        >
                          <option value={null}> --District-- </option>
                          {cities}
                        </select>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>

              <div className="container">
                {/* <input type="checkbox" className="form-check-input" onChange={this.handleCheckbox} /> */}
                {/* <label className="form-check-label ms-2">Select All</label> */}
                {this.state.isError ? (
                  <b>{this.state.errors}</b>
                ) : this.state.loading ? (
                  <LoaderRoundedWithText loadingText="Please wait while loading, It may take more time to loading data..." />
                ) : (
                  <div className="row">
                    <div className="col-12">
                      <div className="table-responsive-sm">
                        <table className="table table-bordered table-responsive">
                          <thead>
                            <tr>
                              <th>No.</th>
                              <th>Coordinator name</th>
                              <th>City</th>
                              <th style={{ textAlign: "center" }}>
                                Phase 1 Candidates
                              </th>
                              <th style={{ textAlign: "center" }}>
                                Phase 2 Candidates
                              </th>

                              {/* <th style={{textAlign:'center'}}>Phase 1 YIN Members</th>
                                                        <th style={{textAlign:'center'}}>Phase 2 YIN Members</th> */}
                              {/* <th className="text-center">Actions</th> */}
                            </tr>
                          </thead>
                          <tbody>{collegeList}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default CoordinatorWiseCountList;
