import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import S3 from "react-aws-s3";
import Compressor from "compressorjs";
import moment from "moment";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import LoaderRounded from "./LoaderRounded";

export default class Position extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: JSON.parse(localStorage.getItem("selected_event")),

      // event_id:EVENT_SOLODANCE_1663845791212,
      first_name: "",
      last_name: "",
      email: "",
      dob: "",
      mobile: "",
      gender: "",
      city: "",
      pincode: "",
      college_name: "",
      college_code: "",
      college_year: "",
      address1: "",
      address2: "",
      isNominated: "",
      adharcardNo: "",
      event_amount: "",
      receiptNumber: "",
      redirect: false,
      errors: {},
      loader: false,
      sub_city: "",
      state_code: "",
      city_code: "",
      state_name: "",
      city_name: "",
      StateData: [],
      CityData: [],
      errors: [],
      CollegeData: [],
      receipt_Photo: null,
    };
    this.uploadImageToS3 = this.uploadImageToS3.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.ChangeCity = this.ChangeCity.bind(this);
    this.showCity = this.showCity.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.imageChangedHandler = this.imageChangedHandler.bind(this);
  }
  imageChangedHandler(event) {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      this.setState({
        event_images: event.target.files[0],
        image: URL.createObjectURL(event.target.files[0]),
      });
    }
  }

  handleFormChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  UNSAFE_componentWillMount() {
    this.getCollege();
    // this.getMember();
  }
  handleFormValidation = () => {
    const { first_name, last_name, email, dob, mobile, gender, city, pincode, college_code, college_name, college_year, address1, address2, adharcardNo, event_amount, receipt_Photo, receiptNumber } = this.state;
    let errors = {};
    let formIsValid = true;

    //empty college name

    if (!first_name) {
      errors["first_name"] = "*Please enter event title.";
      formIsValid = false;
    } else if (!first_name.match(/^[a-zA-Z ]*$/)) {
      formIsValid = false;
      errors["first_name"] = "*Please enter alphabet characters only.";
    }

    if (!last_name) {
      errors["last_name"] = "*Please enter event title.";
      formIsValid = false;
    } else if (!last_name.match(/^[a-zA-Z ]*$/)) {
      formIsValid = false;
      errors["last_name"] = "*Please enter alphabet characters only.";
    }
    if (!email) {
      formIsValid = false;
      errors["email"] = "*Give Your Answer.";
    }
    if (!dob) {
      formIsValid = false;
      errors["dob"] = "*Give Your Answer.";
    }
    if (!mobile) {
      formIsValid = false;
      errors["mobile"] = "*Give Your Answer.";
    }
    if (!gender) {
      formIsValid = false;
      errors["gender"] = "*Give Your Answer.";
    }
    if (!city) {
      formIsValid = false;
      errors["city"] = "*Give Your Answer.";
    }
    if (!pincode) {
      formIsValid = false;
      errors["pincode"] = "*Give Your Answer.";
    }
    if (!college_code) {
      formIsValid = false;
      errors["college_code"] = "*Give Your Answer.";
    }
    if (!college_name) {
      formIsValid = false;
      errors["college_name"] = "*Give Your Answer.";
    }
    if (!college_year) {
      formIsValid = false;
      errors["college_year"] = "*Give Your Answer.";
    }
    if (!address1) {
      formIsValid = false;
      errors["address1"] = "*Give Your Answer.";
    }
    if (!address2) {
      formIsValid = false;
      errors["address2"] = "*Give Your Answer.";
    }
    if (!address2) {
      formIsValid = false;
      errors["address2"] = "*Give Your Answer.";
    }
    if (!address2) {
      formIsValid = false;
      errors["address2"] = "*Give Your Answer.";
    }
    if (!adharcardNo) {
      formIsValid = false;
      errors["adharcardNo"] = "*Give Your Answer.";
    }
    if (!event_amount) {
      formIsValid = false;
      errors["event_amount"] = "*Give Your Answer.";
    }
    if (!receiptNumber) {
      formIsValid = false;
      errors["receiptNumber"] = "*Give Your Answer.";
    }
    if (!receipt_Photo) {
      formIsValid = false;
      errors["receipt_Photo"] = "*Give Your Answer.";
    }
    this.setState({
      errors: errors
    });
    return formIsValid;

  }

  uploadImageToS3(event) {
    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      dirName: "yin/receipt_Photo",
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
      s3Url: process.env.REACT_APP_S3URL,
    };

    var fileInput = false;
    const image = event.target.files[0];
    if (image === undefined) return;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      if (
        event.target.files[0].type === "image/png" ||
        event.target.files[0].type === "image/jpeg"
      ) {
        new Compressor(image, {
          quality: 0.6,
          success: (compressedResult) => {

            let newFileName = event.target.files[0].name.replace(/\..+$/, "");
            const finalFileName = newFileName;

            const ReactS3Client = new S3(config);
            // const newFileName = 'test-file';

            ReactS3Client.uploadFile(compressedResult, newFileName)
              .then((res) => {
                this.setState({
                  receipt_Photo: res.location
                });
              })
              .catch((err) => console.error(err));
          },
        });
      }
    }
  }

  getCollege() {
    let districts = JSON.parse(localStorage.getItem("districts"));

    axios
      .post(process.env.REACT_APP_APIURL + `/coordinator/college/all/city`, {
        districts,
      })
      .then((response) => {
        localStorage.getItem("collegecode", response.data.college_code);
        this.setState({
          CollegeData: response.data,
        });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }

  ChangeCity = (e) => {
    e.preventDefault();
    axios
      .get(
        process.env.REACT_APP_APIURL + "/address/city/" + e.target.value
      )
      .then((response) => {
        this.setState({
          CityData: response.data,
        });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  showCity = (e) => {
    e.preventDefault();
    let index = e.target.selectedIndex;
    this.setState({ city_name: e.target[index].innerText });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    // if (this.handleFormValidation()) {
    const registrationdata = {
      event_id: this.state.event.event_id,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      dob: this.state.dob,
      mobile: this.state.mobile,
      gender: this.state.gender,
      city: this.state.city,
      pincode: this.state.pincode,
      college_name: this.state.pincode,
      college_code: this.state.college_code,
      college_year: this.state.college_year,
      address1: this.state.address1,
      address2: this.state.address2,
      isNominated: false,
      adharcardNo: this.state.adharcardNo,
      event_amount: this.state.event_amount,
      receiptNumber: this.state.receiptNumber,
      receipt_Photo: this.state.receipt_Photo,
    };

    let statesSet = this;

    await axios
      .post("https://yin-api.foxberry.link/v1/users/add-new/offline", registrationdata)
      .then((res) => {

        if (res.status === 200) {
          // this.props.history.push("/forumlist");
          alert("Added successfully");
          statesSet.setState({
            changeToView: true,
            redirect: true,
            loader: true,
          });
        }
      });
    // }
  };
  render() {
    // if (this.state.redirect) {
    //     return <Redirect to="/" />;
    //   }

    let subCitys = localStorage.getItem("sub_city_name") ? (
      <div id="stateDiv" className="col-12 col-md-6 pt-md-0 pt-4">
        <div className="form-group">
          <label htmlFor="subCity" className="control-label">
            Sub City
          </label>
          <select
            id="subCity"
            name="sub_city"
            className="form-select"
            onChange={this.handleChange}
          >
            <option>--Choose your sub city--</option>
            <option value="PCMC">PCMC</option>
            <option value="PUNE_CITY">PUNE_CITY</option>
            <option value="PUNE_RURAL">PUNE_RURAL</option>
          </select>
          <div className="errorMsg">{this.state.errors.subCity}</div>
        </div>
      </div>
    ) : (
      ""
    );

    return (
      <>
        <Sidebar />
        <div className="main">
          <div className="topbar">
            <Navbar />
          </div>
          <div></div>
          <div className="container">
            {/* <BackAndBreadCrmOneParent
              backLink="/AmplifyList"
              backName="AmplifyList"
              currentTabName="Add Amplify"/> */}

            <div className="row mt-4">
              <div
                style={{
                  heigh: "800vh",
                  overflow: "hidden",
                }}
                className="col-lg-8 col-md-8 col-12 paddingAll10 mx-auto "
              >
                <div className="card rounded shadow shadow-sm ">
                  <div className="card-header">
                    <h3 className="mb-0 center">
                      YIN Online Kala Mohatsav Kalamohotsav Registration Form
                    </h3>
                  </div>
                  <div className="card-header">
                    <h3 className="mb-0 center">
                      Event Name:{this.state.event.event_title}
                    </h3>
                  </div>
                  <div className="card-body">
                    <form
                      id="candidate"
                      method="post"
                      onSubmit={this.handleSubmit}
                      encType="multipart/form-data"
                    >
                      <div className="form-group">
                        <label htmlFor="firstName" className="control-label">
                          <span style={{ color: "#ff0000" }}>*</span>First Name
                        </label>
                        <input
                          id="first Name"
                          type="text"
                          className="form-control"
                          name="first_name"
                          value={this.state.first_name}
                          onChange={this.handleFormChange}
                          placeholder="Type here"
                          required
                        ></input>
                        <div className="errorMsg">{this.state.errors.first_name}</div>
                      </div>
                      <br />
                      <hr className="mx-n3" />

                      {/* <div className="form-group">
                                                <label htmlFor="middleName" className="control-label"><span style={{ color: "#ff0000" }}>*</span>Middle Name</label>
                                                <input id="middle Name" type="text" className="form-control" name="fristname" value={this.state.firstname} onChange={this.handleFormChange} placeholder="Type here"  required ></input>
                                            </div>
                                            <br />
                                            <hr className="mx-n3" /> */}

                      <div className="form-group">
                        <label htmlFor="lastName" className="control-label">
                          <span style={{ color: "#ff0000" }}>*</span>Last Name
                        </label>
                        <input
                          id="last Name"
                          type="text"
                          className="form-control"
                          name="last_name"
                          value={this.state.last_name}
                          onChange={this.handleFormChange}
                          placeholder="Type here"
                          required
                        ></input>
                        <div className="errorMsg">{this.state.errors.last_name}</div>
                      </div>
                      <br />
                      <hr className="mx-n3" />

                      <div className="form-group">
                        <label htmlFor="mobile" className="control-label">
                          <span style={{ color: "#ff0000" }}>*</span>Contact
                          Details
                        </label>
                        <input
                          id="mobile"
                          type="text"
                          className="form-control"
                          name="mobile"
                          value={this.state.mobile}
                          onChange={this.handleFormChange}
                          placeholder="Type here"
                          required
                        ></input>
                        <div className="errorMsg">{this.state.errors.mobile}</div>
                      </div>
                      <br />
                      <hr className="mx-n3" />

                      <div className="form-group">
                        <label htmlFor="email" className="control-label">
                          <span style={{ color: "#ff0000" }}>*</span>Email
                        </label>
                        <input
                          id="email"
                          type="text"
                          className="form-control"
                          name="email"
                          value={this.state.email}
                          onChange={this.handleFormChange}
                          placeholder="Type here"
                          required
                        ></input>
                        <div className="errorMsg">{this.state.errors.email}</div>
                      </div>
                      <br />
                      <hr className="mx-n3" />

                      <div className="form-group">
                        <label htmlFor="dateofbirth" className="control-label">
                          <span style={{ color: "#ff0000" }}>*</span>Date of
                          Birth
                        </label>
                        <input
                          id="dateofbirth"
                          type="date"
                          className="form-control"
                          name="dob"
                          value={this.state.dob}
                          onChange={this.handleFormChange}
                          placeholder="Type here"
                          required
                        ></input>
                        <div className="errorMsg">{this.state.errors.dob}</div>
                      </div>
                      <br />
                      <hr className="mx-n3" />

                      {/* <div className="form-group">
                                                <label htmlFor="age" className="control-label"><span style={{ color: "#ff0000" }}>*</span>Age</label>
                                                <input id="age" type="text" className="form-control" name="mobile" value={this.state.mobile} onChange={this.handleFormChange} placeholder="Type here"  required ></input>
                                            </div>
                                            <br />
                                            <hr className="mx-n3" /> */}

                      <div className="form-group">
                        <label htmlFor="gender" className="control-label">
                          <span style={{ color: "#ff0000" }}>*</span>Gender
                        </label>
                        <input
                          id="gender"
                          type="text"
                          className="form-control"
                          name="gender"
                          value={this.state.gender}
                          onChange={this.handleFormChange}
                          placeholder="Type here"
                          required
                        ></input>
                        <div className="errorMsg">{this.state.errors.gender}</div>
                      </div>
                      <br />
                      <hr className="mx-n3" />

                      <div className="form-group">
                        <label htmlFor="Collegename" className="control-label">
                          <span style={{ color: "#ff0000" }}>*</span>College
                          Name
                        </label>
                        {/* <input id="collegename" type="text" className="form-control" name="mobile" value={this.state.mobile} onChange={this.handleFormChange} placeholder="Type here"  required ></input> */}
                        {/* <div className="errorMsg">{this.state.errors.title}</div> */}
                        <select
                          id="citys"
                          name="district"
                          className="form-select"
                          onChange={this.getCollege}
                          required={true}
                        >
                          <option>--Choose College--</option>
                          {this.state.CollegeData.map((e, key) => {
                            return (
                              <option key={key} value={e.college_name}>
                                {e.college_name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <br />
                      <hr className="mx-n3" />

                      <div className="form-group">
                        <label htmlFor="Collegename" className="control-label">
                          College code
                        </label>
                        <input
                          id="collegename"
                          type="text"
                          className="form-control"
                          name="college_code"
                          value={this.state.college_code}
                          onChange={this.handleFormChange}
                          placeholder="Type here"
                        ></input>
                        {/* <div className="errorMsg">{this.state.errors.title}</div> */}
                      </div>
                      <br />
                      <hr className="mx-n3" />

                      <div className="form-group">
                        <label htmlFor="College Year" className="control-label">
                          <span style={{ color: "#ff0000" }}>*</span>College
                          Year
                        </label>
                        <input
                          id="college Year"
                          type="text"
                          className="form-control"
                          name="college_year"
                          value={this.state.college_year}
                          onChange={this.handleFormChange}
                          placeholder="Type here"
                          required
                        ></input>
                        <div className="errorMsg">{this.state.errors.college_name}</div>
                      </div>
                      <br />
                      <hr className="mx-n3" />

                      <div className="form-group">
                        <label htmlFor="Address 1" className="control-label">
                          <span style={{ color: "#ff0000" }}>*</span>Address 1
                        </label>
                        <input
                          id="Address 1"
                          type="text"
                          className="form-control"
                          name="address1"
                          value={this.state.address1}
                          onChange={this.handleFormChange}
                          placeholder="Type here"
                          required
                        ></input>
                        <div className="errorMsg">{this.state.errors.address1}</div>
                      </div>
                      <br />
                      <hr className="mx-n3" />

                      <div className="form-group">
                        <label htmlFor="Address 2" className="control-label">
                          Address 2
                        </label>
                        <input
                          id="Address 2"
                          type="text"
                          className="form-control"
                          name="address2"
                          value={this.state.address2}
                          onChange={this.handleFormChange}
                          placeholder="Type here"
                          required
                        ></input>
                        {/* <div className="errorMsg">{this.state.errors.title}</div> */}
                      </div>
                      <br />
                      <hr className="mx-n3" />

                      <div>
                        <div id="stateDiv" className="form-group">
                          <label htmlFor="states" className="control-label">
                            <span style={{ color: "#ff0000" }}>*</span>Select
                            State
                          </label>
                          <select
                            id="states"
                            className="form-select"
                            name="state"
                            onChange={this.ChangeCity}
                            required={true}
                          >
                            <option>--Choose State--</option>
                            <option value="MH">Maharashtra</option>
                            {/* <option value="GA">Goa</option> */}
                          </select>
                        </div>
                      </div>

                      <div>
                        <div>
                          <div id="stateDiv" className="form-group">
                            <label
                              htmlFor="citys"
                              id="cityLabel"
                              className="control-label"
                            >
                              <span style={{ color: "#ff0000" }}>*</span>Select
                              City
                            </label>
                            <select
                              id="citys"
                              name="city"
                              className="form-select"
                              onChange={this.showCity}
                              required={true}
                            >
                              <option>--Choose City--</option>
                              {this.state.CityData.map((e, key) => {
                                return (
                                  <option key={key} value={e.countryCode}>
                                    {e.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>

                        {subCitys}
                      </div>
                      <hr className="mx-n3" />

                      <div className="form-group">
                        <label htmlFor="Pincode" className="control-label">
                          <span style={{ color: "#ff0000" }}>*</span>Pincode
                        </label>
                        <input
                          id="Pincode"
                          type="text"
                          className="form-control"
                          name="pincode"
                          value={this.state.pincode}
                          onChange={this.handleFormChange}
                          placeholder="Type here"
                          required
                        ></input>
                        <div className="errorMsg">{this.state.errors.pincode}</div>
                      </div>
                      <br />
                      <hr className="mx-n3" />

                      <div className="form-group">
                        <label htmlFor="adharno" className="control-label">
                          <span style={{ color: "#ff0000" }}>*</span>Adhar No
                        </label>
                        <input
                          id="adharno"
                          type="text"
                          className="form-control"
                          name="adharcardNo"
                          value={this.state.adharcardNo}
                          onChange={this.handleFormChange}
                          placeholder="Type here"
                          required
                        ></input>
                        <div className="errorMsg">{this.state.errors.adharcardNo}</div>
                      </div>
                      <br />
                      <hr className="mx-n3" />

                      {/* <div className="form-group">
                                                <label htmlFor="Group Name" className="control-label">Group Name</label>
                                                <input id="Group Name" type="text" className="form-control" name="mobile" value={this.state.mobile} onChange={this.handleFormChange} placeholder="Type here"  required ></input>
                                            </div>
                                            <br />
                                            <hr className="mx-n3" /> */}

                      {/* <div className="form-group">
                                                <label htmlFor="registration Date" className="control-label"><span style={{ color: "#ff0000" }}>*</span>Registration Date</label>
                                                <input id="registration no" type="text" className="form-control" name="mobile" value={this.state.registra} onChange={this.handleFormChange} placeholder="Type here"  required ></input>
                                               
                                            </div>
                                            <br />
                                            <hr className="mx-n3" /> */}

                      <div className="form-group">
                        <label
                          htmlFor="registration Amount"
                          className="control-label"
                        >
                          <span style={{ color: "#ff0000" }}>*</span>
                          Registration Amount
                        </label>
                        <input
                          id="registration no"
                          type="text"
                          className="form-control"
                          name="event_amount"
                          value={this.state.event_amount}
                          onChange={this.handleFormChange}
                          placeholder="Type here"
                          required
                        ></input>
                        <div className="errorMsg">{this.state.errors.event_amount}</div>
                      </div>
                      <br />
                      <hr className="mx-n3" />

                      <div className="form-group">
                        <label
                          htmlFor="registration No"
                          className="control-label"
                        >
                          <span style={{ color: "#ff0000" }}>*</span>Receipt No
                        </label>
                        <input
                          id="registration no"
                          type="text"
                          className="form-control"
                          name="receiptNumber"
                          value={this.state.receiptNumber}
                          onChange={this.handleFormChange}
                          placeholder="Type here"
                          required
                        ></input>
                        <div className="errorMsg">{this.state.errors.receiptNumber}</div>
                      </div>
                      <br />
                      <hr className="mx-n3" />

                      {/* <div className="form-group">
                                                <label htmlFor="location" className="control-label"><span style={{ color: "#ff0000" }}>*</span>Payment Type</label>
                                                <select className="form-select" name="is_paid" value={this.state.is_paid} onChange={this.handleFormChange} disabled={(this.state.status === false) ? "disabled" : null}>
                                                    <option value="">--Choose--</option>
                                                    <option value="true">Online</option>
                                                    <option value="false">Offline</option>
                                                </select>
                                               
                                            </div>
                                            <br />
                                            <hr className="mx-n3" /> */}

                      <div className="textOnInputs">
                        <div className="col-md-12 mt-3">
                          <label htmlFor="image">
                            <span style={{ color: "#ff0000" }}>*</span>
                            Receipt Photo
                          </label>
                          <input
                            type="file"
                            accept="image/png, image/gif, image/jpeg"
                            id="image"
                            name="receipt_Photo"
                            className="form-control-file"
                            onChange={this.uploadImageToS3}
                            required
                          />
                          {/* {this.state.event_images ? <img id="image" src={this.state.event_images} alt="" width="200px" height="80px" /> : ""} */}
                          <div className="errorMsg">
                            {this.state.errors.receipt_Photo}
                          </div>
                        </div>


                        <>
                          {this.state.receipt_Photo && <div
                            className="card"
                            style={{ margin: 10, width: 200 }}
                          >
                            <img
                              src={this.state.receipt_Photo}
                              //   key={img.url}
                              className="card-img-top"
                              //   alt={img.name}
                              width="100px"
                              height="100px"
                            />

                          </div>}
                          {/* <img style={{ margin: 10 }} width="100px" height="100px" key={img.url} src={img.url} alt={img.name} />
                                                                    <button type="delete" id="btnSubmitSignup" className="btn btn-danger btn-sm btn-block" onClick={() => this.removeImageURL(img.id)} >delete</button> */}
                        </>

                      </div>
                      <br />
                      <hr className="mx-n3" />

                      {/* <div className="form-group">
                                                <label htmlFor="choose Event" className="control-label"><span style={{ color: "#ff0000" }}>*</span>Event Type</label>
                                                <select className="form-select" name="is_paid" value={this.state.is_paid} onChange={this.handleFormChange} disabled={(this.state.status === false) ? "disabled" : null}>
                                                    <option value="">--Choose--</option>
                                                    <option value="true">GROUP</option>
                                                    <option value="false">INDIVIDUAL</option>

                                                </select>
                                            </div>
                                            <br />
                                            <hr className="mx-n3" />
                                           */}
                      {/* <div className="form-group">
                                                <label htmlFor="Event City" className="control-label"><span style={{ color: "#ff0000" }}>*</span>Event City</label>
                                                <input id="Event City" type="text" className="form-control" name="mobile" value={this.state.mobile} onChange={this.handleFormChange} placeholder="Type here"  required ></input>
                                            </div>
                                            <br />
                                            <hr className="mx-n3" /> */}

                      <div className="form-group">
                        <button
                          type="submit"
                          id="btnSubmitSignup"
                          className="btn btn-primary btn-lg btn-block mb-2 mt-4"
                          style={{ margin: "4px" }}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
