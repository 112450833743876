import React, { useState } from 'react'
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'
import axios from 'axios';
import yinimg from "../../Assets/images/yin.png";


const DeleteAccountPage = () => {
    const [loading, setLoading] = useState(false)
    const [mobile, setMobile] = useState("")
    const [email, setEmail] = useState("")
    const [comment, setComment] = useState("")

    const [mobileErr, setMobileErr] = useState(false)
    const [commentErr, setCommentErr] = useState(false)

    const fromValidation = () => {
        let isFormValid = true;

        if (mobile.length === 0) {
            isFormValid = false;
            setMobileErr(true)
        } else if (comment.length === 0) {
            isFormValid = false
            setCommentErr(true)
        }
        return isFormValid
    }

    const deleteUserRecord = async () => {
        setLoading(true)
        if (fromValidation()) {
            const payload = {
                mobile: mobile,
                email: email,
                comment: comment
            }
            await axios.post("https://yin-api.foxberry.link/v1/users/delete/mobile", payload)
                .then((response) => {
                    setLoading(false)
                    if (response?.data?.message === "User deleted request send successfully") {
                        alert("User delete request send successfully");
                        setMobile("");
                        setEmail("");
                        setComment("");
                    }
                })
                .catch((error) => {
                    setLoading(false)
                    console.log("error", error);
                })
        }
    }

    return (
        <>
            <div className='mt-4'>
                <Row md={12} className='d-flex align-items-center justify-content-center'>
                    <h2 className='text-center mb-3'>Delete User</h2>
                    <Col md={4} className='border border-grey p-4 mb-4 delBox'>
                        <div className='mb-3 text-center'>
                            <img src={yinimg} alt="yinimages" width="50px" height="50px" />
                        </div>

                        <div className='mb-3'>
                            <b>Before deleting your account:</b>
                            <ul>
                                <li>Sign out of your devices and web browsers.</li>
                                <li>When account deleted you will not able to login to our system and acceess your account</li>
                                <li>Account deletion is permanent</li>
                            </ul>
                            <b>To help us improve our services, add your mobile number,email and reason for your request:</b>
                        </div>
                        <div className='mb-3'>
                            <Form.Label>Mobile Number<span style={{ color: "red" }}> *</span></Form.Label>
                            <Form.Control name='mobile' value={mobile}
                                onChange={(e) => {
                                    if (
                                        !e.target.value.match(/[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/) && e.target.value.length <= 10
                                    ) {
                                        setMobile(e.target.value);
                                    }

                                    e.target.value < 0 ?
                                        setMobileErr(true) :
                                        setMobileErr(false)
                                }} />
                            <span style={{ color: "red" }}>{mobileErr ? "Enter valid mobile number" : ""}</span>
                        </div>

                        <div className='mb-3'>
                            <Form.Label>Email</Form.Label>
                            <Form.Control name='email' value={email} onChange={(e) => { setEmail(e.target.value) }} />
                        </div>

                        <div className='mb-4'>
                            <Form.Label>Comment<span style={{ color: "red" }}> *</span></Form.Label>
                            <Form.Control as="textarea" name='comment' value={comment}
                                onChange={(e) => {
                                    setComment(e.target.value);

                                    e.target.value < 0 ?
                                        setCommentErr(true) :
                                        setCommentErr(false)
                                }} />
                            <span style={{ color: "red" }}>{commentErr ? "Enter comment" : ""}</span>
                        </div>

                        <div className='d-flex justify-content-center mb-3'>
                            {
                                loading ?
                                    <Spinner /> :
                                    <Button className='excelBtn' onClick={() => { deleteUserRecord() }}>Requist for delete</Button>
                            }
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default DeleteAccountPage
