import React, { useState } from 'react';
import Sidebar from '../Sidebar';
import Navbar from '../Navbar';
import { Col, Form, FormControl, Row } from 'react-bootstrap';
import BackAndBreadCumOneParent from '../BackAndBreadCumOneParent';
import axios from 'axios';

const ElectionPahseAdd = (props) => {
  const [phaseName, setPhaseName] = useState("")
  const [phaseImage, setPhaseImage] = useState("")

  const [phaseNameError, setPhaseNameError] = useState("")
  const [phaseImageError, setPhaseImageError] = useState("")

  const handleCancel = () =>{
    setPhaseName("")
    setPhaseImage("")
  }

  const handleProfileImg = async(e)=>{
    // let result = await ReactS3(e.target.files[0], s3Data,"departmentDocs");
    // setProfileImage(result);
}

  const handleValidation = () =>{
    let isValid = true;
    if(phaseName?.length === 0){
      isValid  = false;
      setPhaseNameError(true)
    }
    // else if(phaseImage.length === 0){
    //   isValid  = false;
    //   setPhaseImageError(true)
    // }
    return isValid;
  }

  const addElectionPhase = async(e) =>{
    e.preventDefault();
    if(handleValidation()){
      const payload = {
        name: phaseName ,
        phaseimage: phaseImage
      }
      await axios.post(process.env.REACT_APP_APIURL + `/elctionphase/insert`,payload)
      .then((response) =>{
        if(response?.data?.message === "Election phase added!!!"){
          alert("Election phase added successfully.")
          window.history.back()
        }else if(response?.data?.message === "Already added"){
          alert("Election phase already added, Please add with another name.")
        }
      })
      .catch((error) =>{
        console.log("error",error)
      })
    }
  }

  return (
    <>
        <Sidebar/>
        <div className="main">
          <div className="topbar">
            <Navbar />
          </div>

          <div className="container">
            <BackAndBreadCumOneParent
                backLink="/ElectionPhaseShow"
                backName="Election Phase"
                currentTabName="ElectionPhaseAdd"
              />

            <div className="row mt-4">
              <div className="col-lg-6 col-md-8 col-8 paddingAll10 mx-auto">
                <div className="card rounded shadow shadow-sm">
                  <div className="card-header">
                    <h3 className="mb-0 paddingAll10">Add Election Phase</h3>
                  </div>
                  <div className="card-body">
                    <form>

                    <Row md={12} className='mx-4'>
                      <Col md={12} className='mb-3 sm-mb-3'>
                      <Form.Group>
                      <Form.Label>Phase Name <span style={{ color: "#ff0000" }}>*</span></Form.Label>
                      <FormControl
                        placeholder='Enter Phase Name'
                        name='name'
                        value={phaseName}
                        onChange={(e) =>{setPhaseName(e.target.value)
                          phaseName?.length < 0 ?
                          setPhaseNameError(true) : 
                          setPhaseNameError(false)
                          }
                        }
                      />
                      {phaseNameError? <p style={{color:"red"}}>Enter valid phase name</p> : ""}
                      </Form.Group>
                      </Col>

                      <Col md={12}>
                      <Form.Group>
                      <Form.Label>
                          Phase Image <span style={{ color: "#ff0000" }}>*</span>
                        </Form.Label>
                        <FormControl type='file' 
                          name='phaseimage'
                          value={phaseImage}
                          onChange={(e) =>{handleProfileImg(e)

                            phaseImage.length < 0 ?
                            setPhaseImageError(true) : 
                            setPhaseImageError(false)}}/>
                        {phaseImageError? <p style={{color:"red"}}>Select phase image</p> : ""}
                      </Form.Group>
                      </Col>
                    </Row>

                      <div style={{ display: "flex", marginTop: "25px", marginBottom: "15px", marginLeft:"35px"}}>
                        <button  className="btn btn-success" onClick={addElectionPhase}>
                          Submit
                        </button>
                        &nbsp;
                        <button
                          type="reset"
                          className="btn btn-danger"
                          onClick={handleCancel}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}

export default ElectionPahseAdd
