import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'font-awesome/css/font-awesome.min.css';
import '../Assets/css/Dashboard.css';
import '../Assets/css/style.css';
import yinimg from '../Assets/images/yin.png';

export default class SideBarQRcode extends Component {


    constructor(props) {
        super(props);
        this.state = {
            role: '',
        }
    }

    componentDidMount() {
        let role = localStorage.getItem('role');
        this.setState({ role })
    }


    render() {
        return (
            <>
                <div className="containers">
                    <div className="navigation">
                        <ul>
                            <div className="divider-10" >
                                <img src={yinimg} alt="yinimages" width="50px" height="50px" />
                            </div>
                            <li className="divider-20">
                                <Link to="/view/qrcode/list">
                                    <span className="icon"><i className="fa fa-home"></i></span>
                                    <span className="title">QR Code Payment</span>
                                </Link>
                            </li>

                            <li className="divider-10">
                                <Link to="/view/qrcode/approved/list">
                                    <span className="icon"><i className="fa fa-list"></i></span>
                                    <span className="title">Approve payment list</span>
                                </Link>
                            </li>

                            {/* <li className="divider-10">
                                <Link to="/view-user">
                                    <span className="icon"><i className="fa fa-list"></i></span>
                                    <span className="title">All User List</span>
                                </Link>
                            </li>

                            <li className="divider-10">
                                <Link to="/candidateList">
                                    <span className="icon"><i className="fa fa-user"></i></span>
                                    <span className="title">Candidate</span>
                                </Link>
                            </li>
                            
                            <li className="divider-10">
                                <Link to="/collegeList">
                                    <span className="icon"><i className="fa fa-list"></i></span>
                                    <span className="title">College List</span>
                                </Link>
                            </li> */}


                        </ul>
                    </div>
                </div>
            </>
        )
    }
}
