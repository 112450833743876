import React, { Component } from 'react'

import list from "./Sc";

import Sidebar from './Sidebar';
import Navbar from './Navbar'

export default class FileUpload extends Component {
    constructor(props){
        super(props);
        this.state ={
            list:list
        }
    }
     
    render() {
        return (
            <>
            <Sidebar />
                <div className="main">
                    <div className="topbar">
                        <Navbar />
                    </div>
                <div className="container">
                    <div className="text-center">

                        <div className="file btn btn-lg  btn-secondary" style={{ margin: "5px",marginTop:"20px" }}>
                           
                            <input type="file" name="file" />
                        </div>
                        <div className="btn btn-lg btn-primary" style={{ margin: "5px",marginTop:"20px" }}>Submit</div>

                    </div>
                </div>

                <div className="container">
                    <div className="text-center">
                        <div className="row">
                            <div className="col-12">
                                <div style={{ overflowx: "auto" }}>
                                    <div className="table-responsive-sm">
                                        <table className="table table-bordered table-responsive "  style={{ margin: "5px",marginTop:"20px" }}>
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Name</th>
                                                    <th>College</th>
                                                    <th>Email</th>
                                                    <th>Mobile</th>
                                                    <th>Address</th>
                                                    <th>DOB</th>
                                                    <th>College_id</th>
                                                    <th>Status</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.list.map(lists => (
                                                    <tr >
                                                        <td>{lists.ID}</td>
                                                        <td>{lists.Name}</td>
                                                        <td>{lists.College}</td>
                                                        <td>{lists.email}</td>
                                                        <td>{lists.Mobile}</td>
                                                        <td>{lists.Address}</td>
                                                        <td>{lists.DOB}</td>
                                                        <td>{lists.College_id}</td>
                                                        <td>{lists.Status}</td>

                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </>
        )
    }
}

