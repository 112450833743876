import React from 'react'
import Sidebar from '../../../Sidebar'
import Navbar from '../../../Navbar'

const ElectionPhase = () => {
  return (
    <>
      <Sidebar/>
        <div className="main">
          <div className="topbar">
            <Navbar title="Election Phase"/>
          </div>
        </div>
    </>
  )
}

export default ElectionPhase
