import React, { Component } from 'react';
import axios from 'axios';
import ResultChildCom from './DisputeListCount';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import LoaderRounded from "./LoaderRounded";

export default class DisputeListByCandidate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // college_code:'',
            college_code: localStorage.getItem("collegeCode") ? localStorage.getItem("collegeCode") : '',
            yin_id: '',
            first_name: '',
            last_name: '',
            votecount: '',
            profile_image: [],
            candidateData: [],
            //for dropdown purpose
            state_code: '',
            city_code: '',
            state_name: '',
            city_name: '',
            StateData: [],
            CityData: [],
            country: "IN",
            id: "",
            loader: true,
            email: ''
        };
        this.candidates = this.candidates.bind(this);
    }



    UNSAFE_componentWillMount() {
        let candidateDisputeListId = localStorage.getItem("show_dispute_candidate_id");
        this.candidates(candidateDisputeListId);
    }


    async candidates(candidateDisputeListId) {
        // axios.get(process.env.REACT_APP_VOTINGAPI + `/voting/result/MHPC00003`)
        axios.get(process.env.REACT_APP_VOTINGAPI + `/voting/disputesByCandidate/` + candidateDisputeListId)
            .then(res => {
                let candidates = res.data.data;

                let a = [];
                if (candidates.length > 0) {
                    a.push(candidates);
                    this.setState({
                        candidateData: candidates,
                        loader: false
                    });
                }

                else {
                    let error = "No Data Found"
                    console.log("error in result----", error);
                }

            });
    }



    // {this.state.candidateData.map((candidates, index) => (
    //     <div key={index}>
    //         <ResultChildCom profile_image={candidates.profile_image[0]} first_name={candidates.first_name} last_name={candidates.last_name} votecount={candidates.votecount} yin_id={candidates.yin_id} />
    //     </div>
    // ))}


    render() {


        let candidate_data_list = '';
        if (this.state.candidateData.length > 0) {
            candidate_data_list = this.state.candidateData.map((candidates, index) => (

                <div key={index}>
                    <ResultChildCom profile_image={candidates.profile_image[0]} first_name={candidates.first_name} last_name={candidates.last_name} yin_id={candidates.yin_id} />
                </div>
            ))
        }

        else {
            candidate_data_list =
                <tr>
                    <td>No Data Found....</td>
                </tr>
        }

        return (
            <>
                <Sidebar />
                <div className="main">
                    <div className="topbar">
                        <Navbar />
                    </div>
                    <div className="container divider-12">
                        <BackAndBreadCrmOneParent backLink="/dispute-list" backName="Dispute list" currentTabName="Dispute list by candidate" />

                        <h3 className="divider-20">Dispute list candidate wise</h3>
                        <div className="col-12 col-md-6 col-lg-6">
                            {
                                this.state.loader ? <LoaderRounded /> : candidate_data_list
                            }
                        </div>


                        <div>

                        </div>



                    </div>

                </div>

            </>
        )
    }
}
