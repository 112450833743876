import React from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { Edit } from "@material-ui/icons";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import candidate_old from "../data/static-candidate";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import LoaderRounded from "./LoaderRounded";
import LoaderRoundedWithText from "./LoaderRoundedWithText";
import ReactPaginate from "react-paginate";

class RejectedCandidateList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentFilter: null,
      candidate_old: candidate_old,
      candidate: [],
      selectValue: "all",
      redirect: false,
      // loader:true,
      loader: true,
      loading: true,
      limitVal: 20,
      allpage: "",
      page_selected: 0,
    };
    this.Editcandidate = this.Editcandidate.bind(this);
    this.getData = this.getData.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.getData();
  }

  componentWillMount() {
    setTimeout(() => this.setState({ loader: false, msg: false }), 3500);
    setTimeout(() => this.setState({ loading: false }), 1000);
  }
  getData() {
    let currentComponent = this;
    let districts = JSON.parse(localStorage.getItem("districts"));
    axios
      .post(process.env.REACT_APP_APIURL + `/candidate/list/status/REJECTED`, {
        districts,
      })
      .then((res) => {
        let responses = res.data.slice(
          this.state.page_selected * this.state.limitVal,
          this.state.limitVal * (this.state.page_selected + 1)
        );
        let con = Math.ceil(parseInt(res.data.length) / this.state.limitVal);
        currentComponent.setState({
          candidate: responses,
          allpage: con,
          loader: false,
        });
      });
  }

  Editcandidate(yinID, candidateID) {
    localStorage.setItem("candidate_id", candidateID);
    localStorage.setItem("yin_id", yinID);
    this.setState({
      redirect: true,
    });
  }
  handlePageChange(page) {
    this.setState({
      page_selected: page.selected,
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/edit_candidate" />;
    }

    if (!localStorage.getItem("mobile")) {
      return <Redirect to="/login" />;
    }

    let candidateList = "";
    if (this.state.candidate.length > 0) {
      candidateList = this.state.candidate.map((candidates, id) => (
        <tr key={id}>
          <td>{this.state.page_selected * this.state.limitVal + id + 1}</td>
          <td>{candidates.yin_id}</td>
          <td>{candidates.first_name}</td>
          <td>{candidates.college_name}</td>
          <td>{candidates.city}</td>
          <td className="noselect">{candidates.mobile}</td>
          <td>{candidates.dob}</td>
          {candidates.application_status === "APPROVED" ? (
            <td className="text-success">APPROVED</td>
          ) : (
            ""
          )}
          {candidates.application_status === "REJECTED" ? (
            <td className="text-danger">REJECTED</td>
          ) : (
            ""
          )}
          {candidates.application_status === "PENDING" ? (
            <td className="text-warning">PENDING</td>
          ) : (
            ""
          )}
          <span
            onClick={() =>
              this.Editcandidate(candidates.yin_id, candidates.college_code)
            }
          >
            <Edit />
          </span>
        </tr>
      ));
    } else {
      candidateList = "No data found";
    }

    return (
      <>
        {this.state.loading ? (
          <LoaderRoundedWithText />
        ) : (
          <div>
            <Sidebar />
            <div className="main">
              <div className="topbar">
                <Navbar title="All Rejected Candidate List" />
              </div>

              <div className="container">
                <BackAndBreadCrmOneParent currentTabName="All rejected candidate list" />

                <br />

                <div className="container">
                  <div>
                    Current Filter: {this.state.currentFilter ?? "NO FIlter"}{" "}
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div style={{ overflowx: "auto" }}>
                        <div className="table-responsive-sm">
                          <table className="table table-bordered table-responsive">
                            <thead>
                              <tr>
                                <th>Sr.No</th>
                                <th>ID</th>
                                <th>Name</th>
                                <th>College</th>
                                <th>City</th>
                                <th>Mobile</th>
                                <th>DOB</th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.loader ? (
                                <LoaderRounded type="Spinner Type" />
                              ) : (
                                candidateList
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {
                  <ReactPaginate
                    nextLabel="Next"
                    previousLabel="Previous"
                    pageCount={
                      // candidateList.length / this.state.renderPageLimit
                      this.state.allpage
                    }
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    containerClassName="pagination"
                    activeClassName="btn btn-primary"
                    pageClassName="btn"
                    previousClassName="btn"
                    nextClassName="btn"
                    onPageChange={this.handlePageChange}
                  />
                }
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
export default RejectedCandidateList;
