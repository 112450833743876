import React, { Component } from 'react'
import axios from 'axios';
import Navbar from './Navbar';
import LoaderRounded from "./LoaderRounded";
import SideBarQRcode from './SideBarQRcode';
import ApprovedQRCodePaymentPopUp from './ApprovedQRCodePaymentPopUp';

export default class ViewQrcodePayment extends Component {
    constructor(pops) {
        super(pops);

        this.state = {
            allpaymentData: [],
            errors: [],
            address_line_1: "",
            images: "",
            address_line_2: "",
            application_status: "",
            yin_id: "",
            redirect: false,
            showModalPopup: false,
            loader: true,
            showLoader: false,
            disabledTrue: false,
        }
        // this.handleSubmit = this.handleSubmit.bind(this);
        this.isShowPopup = this.isShowPopup.bind(this);

    }


    isShowPopup = (status, yinId, Mobile, qr_code_upi_id, qr_code_transaction_id, qr_code_transaction_photo) => {
        localStorage.setItem("approved_yin_id", yinId);
        localStorage.setItem("approved_mobile", Mobile);
        localStorage.setItem("approved_qr_code_upi_id", qr_code_upi_id);
        localStorage.setItem("approved_qr_code_transaction_id", qr_code_transaction_id);
        localStorage.setItem("approved_qr_code_transaction_photo", qr_code_transaction_photo);
        this.setState({ showModalPopup: status, disabled: true });
    }

    isClosePopup = () => {
        this.setState({ showModalPopup: false });
    }

    UNSAFE_componentWillMount() {
        this.getData();
    }

    getData() {
        let currentComponent = this;
        axios.get(process.env.REACT_APP_APIURL + `/qrcode/payment/list/nonapproved`)
            .then(res => {
                if (res.data === "No non approved list found") {
                    currentComponent.setState({
                        allpaymentData: null,
                        loader: false,
                    });
                } else {
                    let responses = res.data;
                    currentComponent.setState({
                        allpaymentData: responses,
                        loader: false,
                    });
                }
            });
    }

    popCloseAfterData() {
        this.getData();
        this.setState({ showModalPopup: false });
    }


    render() {
        let paymentDetails = this.state.allpaymentData ? this.state.allpaymentData.map((payments, index) => (
            <tr key={index} >
                <td>{payments.first_name} {payments.last_name}</td>
                <td>{payments.email}</td>
                <td>{payments.mobile}</td>
                <td>{payments.qr_code_upi_id}</td>
                <td>{payments.qr_code_transaction_id}</td>
                <td>{payments.ispayment_approved ? <span style={{ border: "1px solid #ccc", padding: "6px 16px", backgroundColor: "#ffbf00", display: "block" }}>Payment Approved</span> : <span style={{ border: "1px solid #ccc", padding: "6px 16px", backgroundColor: "#ffbf00", display: "block" }}>Payment Not Approved</span>}</td>
                <td>
                    <span className="btn btn-sm btn-success border" onClick={() => this.isShowPopup(true, payments.yin_id, payments.mobile, payments.qr_code_upi_id, payments.qr_code_transaction_id, payments.qr_code_transaction_photo)}>Approve Payment</span>
                </td>
            </tr>
        )) : "NO Pending QR Code payment..."

        return (
            <>
                <SideBarQRcode />
                <div className="main">
                    <div className="topbar">
                        <Navbar />
                    </div>

                    <div className="container">
                        {/* <BackAndBreadCrmOneParent backLink="/" currentTabName="Pending QR Code Payment for Approved"/> */}
                        <div className="row">
                            <div className="col-12">
                                <u><h2 className="text-center divider-20">Pending QR Code Payment for Approvel</h2></u>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="table-responsive-sm">
                                    <table className="table table-bordered table-responsive">
                                        <thead>
                                            <tr>
                                                <th>Student Name</th>
                                                <th>Email</th>
                                                <th>Mobile</th>
                                                <th>QR Code UPI ID</th>
                                                <th>QR Transaction ID</th>
                                                <th>Status</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.loader ? <LoaderRounded /> : paymentDetails}
                                        </tbody>
                                    </table>

                                </div>
                            </div>


                        </div>
                    </div>
                </div>

                <ApprovedQRCodePaymentPopUp
                    showModalPopup={this.state.showModalPopup}
                    confirm_text="Approved payment"
                    onPopupCloseMain={this.isClosePopup.bind(this)}
                    onPopupCloseMainAfterData={this.popCloseAfterData.bind(this)}
                ></ApprovedQRCodePaymentPopUp>

            </>
        )
    }
}
