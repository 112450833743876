import React, { Component } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import S3 from "react-aws-s3";
import Select from "react-select";
import Compressor from "compressorjs";
import moment from "moment";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import LoaderRounded from "./LoaderRounded";
import { Redirect } from "react-router-dom";

export default class Position extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      _id: "",
      title: "",
      category: "",
      tags: "",
      description: "",
      image: "",
      url: "",
      college_id_image: "",
      dropDownOptCategoryList: [],
      isSelect: false,
      loader: false,
      redirect: false,
    };

    this.initialState = this.state;
    this.imageChangedHandler = this.imageChangedHandler.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getData = this.getData.bind(this);
    this.getcontentData = this.getcontentData.bind(this);

    this.deleteAmplifierData = this.deleteAmplifierData.bind(this);
  }

  async getData(id) {
    let currentComponent = this;
    // let contentID = localStorage.getItem("_id");
    await axios
      .get(process.env.REACT_APP_APIURL + "/Contents/getSingleContent/" + id)
      .then((res) => {
        let responses = res.data;
        currentComponent.setState({
          _id: responses._id,
          title: responses.title,
          category: responses.category,
          tags: responses.tags,
          responses: responses.description,
          image: responses.image,
          url: responses.url,
        });
      });
  }
  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  imageChangedHandler(event) {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      this.setState({
        college_id_image: event.target.files[0],
        image: URL.createObjectURL(event.target.files[0]),
      });
    }
  }

  UNSAFE_componentWillMount() {
    this.getCategory();
    this.getcontentData();
    // this.deleteAmplifierData();
  }
  componentDidMount() {
    // this.deleteAmplifierData();
  }

  getCategory = async () => {
    const API = await axios.get(
      process.env.REACT_APP_APIURL + `/Contents/getcategory`
    );
    const serverResponse = API.data;
    const dropDownValue = serverResponse.map((response) => ({
      value: response.id,
      label: response.category,
    }));

    this.setState({
      dropDownOptCategoryList: dropDownValue,
    });
  };
  onChangeForCategoryListDropDown(event) {
    this.setState({
      id: event.value,
      category: event.label,
      isSelect: true,
    });
    localStorage.setItem("id", event.value);
    localStorage.setItem("category", event.label);
  }

  getcontentData() {
    let contentid;
    let currentComponent = this;
    if (this.props.location.data) {
      localStorage.setItem("contentid", this.props.location.data._id);
      contentid = this.props.location.data._id;
    } else {
      contentid = localStorage.getItem("contentid");
    }

    axios
      .get(
        process.env.REACT_APP_APIURL + `/contents/getSingleContent/` + contentid
      )
      .then((res) => {
        let responses = res.data;

        currentComponent.setState({
          ...responses,
        });
      });
  }

  deleteAmplifierData(e) {
    e.preventDefault();
    let currentComponent = this;
    const deleteid = {
      id: localStorage.getItem("contentid"),
      status: "REJECTED",
    };
    axios
      .put(process.env.REACT_APP_APIURL + `/Contents/updateStatus`, deleteid)
      .then((res) => {
        let responses = res.data;
        currentComponent.setState({
          redirect: true,
        });
      });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/contentlist" />;
    }
    return (
      <>
        <Sidebar />
        <div className="main">
          <div className="topbar">
            <Navbar />
          </div>
          <div></div>
          <div className="container">
            <BackAndBreadCrmOneParent
              backLink="/contentlist"
              backName="ContentList"
              currentTabName="delete Content"
            />

            <div className="row mt-4">
              <div
                style={{
                  heigh: "800vh",
                  overflow: "hidden",
                }}
                className="col-lg-8 col-md-8 col-12 paddingAll10 mx-auto "
              >
                <div className="card rounded shadow shadow-sm ">
                  <div className="card-header">
                    <h3 className="mb-0 center">Delete Content</h3>
                  </div>
                  <div className="card-body">
                    <form>
                      <div className="form-group">
                        <div className="col-sm-12 mt-3">
                          <label className="control-label" htmlFor="Titles">
                            Title
                          </label>
                          <input
                            type="text"
                            name="title"
                            value={this.state.title}
                            placeholder="Title"
                            className="form-control"
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 mt-3">
                        <label
                          htmlFor="category_names"
                          className="control-label"
                        >
                          <span style={{ color: "#ff0000" }}>*</span> Select
                          Category
                        </label>

                        <Select
                          options={this.state.dropDownOptCategoryList}
                          onChange={this.onChangeForCategoryListDropDown.bind(
                            this
                          )}
                          Redirect="/view-card"
                        />
                      </div>

                      <div className="form-group">
                        <div className="col-sm-12 mt-3">
                          <label className="control-label" htmlFor="Tags">
                            Tags
                          </label>
                          <input
                            type="Text"
                            name="tags"
                            placeholder="tag"
                            className="form-control"
                            onChange={this.handleChange}
                            value={this.state.tags}
                          />
                        </div>
                      </div>

                      <div className="textOnInputs">
                        <div className="col-md-12 mt-3">
                          <label htmlFor="image">
                            <span style={{ color: "#ff0000" }}>*</span> Upload
                            Photo
                          </label>
                          <input
                            type="file"
                            id="image"
                            name="college_id"
                            className="form-control-file"
                            onChange={this.imageChangedHandler}
                          />
                          {this.state.image ? (
                            <img
                              id="image"
                              src={this.state.image}
                              alt=""
                              width="200px"
                              height="80px"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="col-sm-12 mt-3">
                          <label
                            className="control-label"
                            htmlFor="Purpose Of Travel"
                          >
                            Url
                          </label>
                          <input
                            type="Text"
                            name="url"
                            placeholder="https://www.abc.com"
                            onChange={this.handleChange}
                            className="form-control"
                            value={this.state.url}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="col-sm-12 mt-3">
                          <label
                            className="control-label"
                            htmlFor="Description"
                          >
                            Description
                          </label>
                          <input
                            type="Text"
                            name="description"
                            placeholder="Lorem Ipsum, sometimes referred to as 'lipsum', is the placeholder text used in design when creating content. "
                            onChange={this.handleChange}
                            value={this.state.description}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <button
                          type="button"
                          onClick={(event) => this.deleteAmplifierData(event)}
                          className="btn btn-primary btn-lg btn-block mb-2 mt-4"
                          style={{ margin: "4px" }}
                        >
                          Delete
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
