import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Visibility } from '@material-ui/icons';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import { Redirect } from 'react-router-dom';
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import LoaderRoundedWithText from "./LoaderRoundedWithText";
import "../Assets/css/Collagename.css";

class Collegenamelist extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            collegeData: [],
            CityData: [],
            loader: true,
            currentFilter: null,
            currentFilterBy: '',
            errors: "",
            isError: false,
            loader: false,
            skipCount: 0,
            districtsLocal: JSON.parse(localStorage.getItem("districts")),
            role: localStorage.getItem('role'),

        }
        this.applyFiter = this.applyFilter.bind(this);
        this.setFilter = this.setFilter.bind(this);
        this.showCity = this.showCity.bind(this);
        this.getData = this.getData.bind(this);
        this.storeCollegeCode = this.storeCollegeCode.bind(this);
        this.searchFromList = this.searchFromList.bind(this);
        this.getCollegeWithCity = this.getCollegeWithCity.bind(this);
        this.getDataForView = this.getDataForView.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.showCity();
        this.getData(this.state.skipCount);
    }

    getCollegeWithCity(event) {
        this.setState({
            currentFilter: event.target.value,
            currentFilterBy: event.target.name
        })
    }

    storeCollegeCode(collegeCode, collegeName) {
        localStorage.setItem("collegeCode", collegeCode);
        localStorage.setItem("college_code", collegeCode);
        localStorage.setItem("college_name", collegeName);
    }

    getData(skipRow) {
        this.setState({
            loader: true,
            errors: ""
        })
        let subcity = localStorage.getItem("sub_city_name");
        let cityData;
        if (subcity) {
            cityData = {
                city: this.state.districtsLocal,
                sub_city: subcity,
                show_colleges: skipRow
            }
        } else {
            cityData = {
                city: this.state.districtsLocal,
                show_colleges: skipRow
            }
        }
        // axios.post(process.env.REACT_APP_APIURL+"/coordinator/dashboard/count/college/all",cityData)
        axios.post(process.env.REACT_APP_APIURL + "/coordinator/dashboard/count/college/all", cityData)
            .then(res => {
                let response = res.data;
                this.setState({
                    collegeData: response,
                    loader: false,
                    errors: ""
                })
            }).catch(error => {
                this.setState({
                    collegeData: "",
                    loader: false,
                    isError: true,
                    errors: "While executing query it will take more time to execute. Please refresh page or try again later..."
                })
            })
    }

    showCity() {
        let districts = JSON.parse(localStorage.getItem("districts"));
        if (districts[0] !== "*") {
            this.setState({
                CityData: districts,
            });
        } else {
            axios.get(process.env.REACT_APP_APIURL + `/address/city/MH`).then(response => {
                this.setState({
                    CityData: response.data,
                })
            }).catch(error => {
                console.log("Error", error);
            });
        }


    }

    applyFilter(arr) {
        if (!this.state.currentFilter) {
            return arr;
        }
        let filteron = this.state.currentFilterBy;
        if (filteron === "city") {
            if (this.state.currentFilter === "Pune") {
                let subcity = localStorage.getItem("sub_city_name");
                if (subcity) {
                    return arr.filter((x) => x.sub_city === subcity);
                } else {
                    return arr.filter((x) => x.city === this.state.currentFilter);
                }
            } else {
                return arr.filter((x) => x.city === this.state.currentFilter);
            }

        } else {
            var condition = new RegExp(this.state.currentFilter);
            var result = arr.filter(function (el) {
                return condition.test(el.college_name);
            });
            return result;

        }

    }


    setFilter(e) {
        e.preventDefault();
        this.setState({
            currentFilter: e.target.value
        })
    }

    searchFromList(event) {
        this.setState({
            currentFilter: event.target.value,
            currentFilterBy: event.target.name
        })

    }
    getDataForView(event) {
        this.getData(event.target.value)
    }

    render() {
        if (!localStorage.getItem("mobile")) {
            return <Redirect to="/login" />
        }

        let collegeList = this.state.collegeData && this.applyFilter(this.state.collegeData).map((colleges, index) => (
            <tr key={index}>
                <td>{index + 1}</td>
                <td width="30%">{colleges.college_name}</td>
                <td >{colleges.city}</td>
                <td> <div className="numberCircle-center_count1">{colleges.phase1_allCandidateCount}</div></td>
                <td> <div className="numberCircle-center_count1">{colleges.phase2_allCandidateCount}</div></td>
                <td><div className="numberCircle-center_count2">{colleges.phase1_allUserCount}</div></td>
                <td><div className="numberCircle-center_count2">{colleges.phase2_allUserCount}</div></td>
                <td className="text-center">
                    <Link to="/view-card" onClick={() => this.storeCollegeCode(colleges.college_code, colleges.college_name)} ><Visibility /></Link>
                </td>
            </tr>
        ));


        let cities;
        if (this.state.districtsLocal[0] !== "*") {
            cities = this.state.CityData.map((e, key) => {
                return <option key={key} value={e}>{e}</option>;
            })
        } else {
            cities = this.state.CityData.map((e, key) => {
                return <option key={key} value={e.name}>{e.name}</option>;
            })
        }

        return (
            <>
                <div>
                    <Sidebar />
                    <div className="main">
                        <div className="topbar">
                            <Navbar />
                        </div>

                        <div className="container">
                            <BackAndBreadCrmOneParent backLink="/dashboard" currentTabName="Counting list of Colleges" />
                            <div className="row">
                                <div className="col-12">
                                    <u><h2 className="text-center">Counting list of Colleges</h2></u>
                                    <br></br>
                                </div>
                            </div>
                            <br></br>
                            <div className="row m-4">
                                <div className="col-6">
                                    <input type="text" placeholder="Search here" name="search" onChange={this.searchFromList} className="form-control"></input>
                                </div>

                                {this.state.role === 'COORDINATOR' ? "" : <div className="col-2">
                                    <select type="button" name="city" className="btn btn-secondary btn-md dropdown-toggle" onChange={this.getDataForView} id="district" style={{ float: "right", width: "120px" }}>
                                        <option value="500"> 500 </option>
                                        <option value="1000"> 1000 </option>
                                        <option value="1500"> 1500 </option>
                                        <option value="2000"> 2000 </option>
                                        <option value="2500"> 2500 </option>
                                        <option value="3000"> 3000 </option>
                                        <option value="3500"> 3500 </option>
                                        <option value="4000"> 4000 </option>
                                    </select>
                                </div>}

                                <div className="col-3">
                                    <select type="button" name="city" className="btn btn-secondary btn-md dropdown-toggle" onChange={this.getCollegeWithCity} id="district" style={{ float: "right", width: "120px" }}>
                                        <option value={null}> --District-- </option>
                                        {cities}
                                    </select>
                                </div>
                            </div>
                        </div>


                        <div className="container">
                            {/* <input type="checkbox" className="form-check-input" onChange={this.handleCheckbox} /> */}
                            {/* <label className="form-check-label ms-2">Select All</label> */}
                            {this.state.isError ? <b>{this.state.errors}</b> : this.state.loader ? <LoaderRoundedWithText loadingText="Please wait while loading, It may take more time to loading data..." /> :
                                <div className="row">
                                    <div className="col-12">
                                        <div className="table-responsive-sm" >
                                            <table className="table table-bordered table-responsive" >
                                                <thead>
                                                    <tr>
                                                        <th>No.</th>
                                                        <th>College Name</th>
                                                        <th>District</th>
                                                        <th style={{ textAlign: 'center' }}>Phase 1 Candidates</th>
                                                        <th style={{ textAlign: 'center' }}>Phase 2 Candidates</th>
                                                        <th style={{ textAlign: 'center' }}>Phase 1 YIN Members</th>
                                                        <th style={{ textAlign: 'center' }}>Phase 2 YIN Members</th>
                                                        <th className="text-center">Actions</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {collegeList}
                                                </tbody>
                                            </table>

                                        </div>

                                    </div>


                                </div>}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Collegenamelist;