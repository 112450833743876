import React, { Component } from "react";
import axios from "axios";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { Redirect } from "react-router-dom";
import Select from "react-select";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import S3 from "react-aws-s3";
import LoaderRounded from "./LoaderRounded";

class Editcoordinator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      _id: "",
      errors: [],
      city: "",
      email: "",
      first_name: "",
      gender: "",
      last_name: "",
      mobile: "",
      state: "",
      loader: false,
      districts: this.props.location.data?.districts ? this.props.location.data?.districts : [],
      verified: "",
      yin_id: "",
      redirect: false,
      StateData: [],
      CityData: [],
    };

    this.initialState = this.state;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.ChangeCity = this.ChangeCity.bind(this);
    this.showCity = this.showCity.bind(this);
    this.handleFormValidation = this.handleFormValidation.bind(this);
    this.selectCity = this.selectCity.bind(this);
    this.addCity = this.addCity.bind(this);
    this.getcoordinatorData = this.getcoordinatorData.bind(this);
    //this.handleRemove = this.handleRemove.bind(this);
  }
  UNSAFE_componentWillMount() {
    // this.getData();
    this.getcoordinatorData()
    this.showCity();
  }
  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  ChangeCity = (e) => {
    e.preventDefault();
    axios
      .get(process.env.REACT_APP_APIURL + "/address/city/" + e.target.value)
      .then((response) => {
        this.setState({
          CityData: response.data,
          state: e.target.value,
        });
      })
      .catch((error) => {
        console.log("------error in change city-------", error);
      });
  };

  showCity = (e) => {
    axios
      .get(process.env.REACT_APP_APIURL + "/address/city/MH")
      .then((response) => {
        if (Array.isArray(response.data) && response.data.length > 0) {
          this.setState({
            CityData: response.data,
          });
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  selectCity = (e) => {
    //    e.preventDefault();
    let city = e.currentTarget.value;
    this.addCity(city);
  };

  addCity(city) {
    let CityData = this.state.districts;
    CityData.push(city.toString());
    this.setState({
      districts: CityData,
    });
  }


  handleFormValidation = () => {
    let formIsValid = true;
    const {
      first_name,
      last_name,
      email,
      mobile,
      state_name,
      city,
      districts,
    } = this.state;
    let errors = {};

    //empty coordinator name
    const regName = /^[a-zA-Z]+ [a-zA-Z]+$/;
    let arr = [];
    if (!first_name) {
      errors["first_name"] = "*Please enter first name.";
      arr.push({
        field: "first_name",
      });
      formIsValid = false;
    } else if (!first_name.match(/^[a-zA-Z ]*$/)) {
      formIsValid = false;
      errors["first_name"] = "*Please enter alphabet characters only.";
      arr.push({
        field: "first_name",
      });
    }
    if (!last_name) {
      formIsValid = false;
      errors["last_name"] = "*Please enter Last name.";
      arr.push({
        field: "last_name",
      });
    } else if (regName.test(last_name)) {
      formIsValid = false;
      errors["last_name"] = "*Please enter alphabet characters only.";
      arr.push({
        field: "last_name",
      });
    }

    if (!email) {
      errors["email"] = "*Please enter your email.";
      formIsValid = false;
      arr.push({
        field: "email",
      });
    } else if (
      !email.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      )
    ) {
      formIsValid = false;
      errors["email"] = "*Invalid email format Missing '@' symbol.";
      arr.push({
        field: "email",
      });
    }

    if (!mobile) {
      formIsValid = false;
      errors["mobile"] = "*Please enter mobile no.";
      arr.push({
        field: "mobile",
      });
    }

    if (!city) {
      formIsValid = false;
      errors["city"] = "Please Choose City";
      arr.push({
        field: "city",
      });
    }

    if (!districts.length > 0) {
      formIsValid = false;
      errors["districts"] = "*Please choose Assigned city/cities.";
      arr.push({
        field: "districts",
      });
    }

    //empty state
    if (state_name === "" || state_name === "--Choose State--") {
      formIsValid = false;
      errors["state_name"] = "Please Choose State.";
      arr.push({
        field: "state_name",
      });
    }

    this.setState({
      errors: errors,
    });
    if (arr.length > 0) {
      const firstErrorField = document.querySelector(
        `[name="${arr[0].field}"]`
      );
      if (firstErrorField && firstErrorField.focus) {
        firstErrorField.focus();
      }
    }

    return formIsValid;
  };

  // get coordinator data
  getcoordinatorData() {
    let coordinatorid;
    let currentComponent = this;
    if (this.props.location.data) {
      localStorage.setItem("coordinatorid", this.props.location.data.yin_id
      );
      coordinatorid = this.props.location.data.yin_id;
    } else {
      coordinatorid = localStorage.getItem("coordinatorid");
    }

    axios
      .get(
        process.env.REACT_APP_APIURL + "/users/details/" + coordinatorid
      )
      .then((res) => {
        let responses = res.data[0];
        this.setState({
          yin_id: responses?.yin_id,
          first_name: responses?.first_name,
          last_name: responses?.last_name,
          mobile: responses?.mobile,
          email: responses?.email,
          gender: responses?.gender,
          city: responses?.city,
          district: responses?.districts,
          verified: responses?.verified,
          state: responses?.state,

        });

      });
  }



  handleSubmit(event) {
    event.preventDefault();

    if (this.handleFormValidation()) {

      let statesSet = this;

      axios.post(process.env.REACT_APP_APIURL + "/coordinator/edit", {
        _id: localStorage.getItem("coordinatorid"),
        mobile: this.state?.mobile,
        first_name: this.state?.first_name,
        last_name: this.state?.last_name,
        email: this.state?.email,
        gender: this.state?.gender,
        city: this.state?.city,
        district: this.state?.districts,
        verified: this.state?.verified,
      })
        .then((res) => {
          if (res.status === 200) {
            alert("coordinator Updated successfully.");
            statesSet.setState({
              redirect: true,
              loader: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            errorsUpload: "Please Go Back and Try again later...",
          });
        });
    }
    else {
      this.handleFormValidation();
    }
  }


  render() {
    if (this.state.redirect) {
      return <Redirect to="/coordinator" />;
    }
    return (
      <>
        <Sidebar />
        <div className="main">
          <div className="topbar">
            <Navbar />
          </div>
          <div className="container">
            <BackAndBreadCrmOneParent
              backLink="/coordinator"
              backName="All Coordinator list"
              currentTabName="Edit Coordinator"
            />

            <div className="row mt-3">
              <div className="col-12  col-lg-10 col-md-10 m-auto">
                <div className="card rounded shadow shadow-sm">
                  <div className="card-header">
                    <h3 className="mb-0">Edit Coordinator</h3>
                  </div>
                  <div className="card-body">
                    <form id="formEditCoordinator">
                      <div className="row mb-1">
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="form-group">
                            <label htmlFor="first_name" className="control-label">
                              Coordinator First Name <span style={{ color: "#ff0000" }}>*</span>
                            </label>
                            <input
                              type="text"
                              id="first_name"
                              name="first_name"
                              className="form-control rounded-1"
                              value={this.state.first_name}
                              onChange={this.handleChange}
                              placeholder="Enter your first name"
                              required
                            />
                            <div className="errorMsg">
                              {this.state.errors.first_name}
                            </div>

                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="form-group">
                            <label htmlFor="last_name" className="control-label">
                              Coordinator last Name <span style={{ color: "#ff0000" }}>*</span>
                            </label>
                            <input
                              type="text"
                              id="last_name"
                              name="last_name"
                              className="form-control rounded-1"
                              value={this.state.last_name}
                              onChange={this.handleChange}
                              placeholder="Enter coordinator last name"
                            />
                            <div className="errorMsg">
                              {this.state.errors.last_name}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="form-group">
                            <label htmlFor="mobile" className="control-label">
                              Mobile Number
                            </label>
                            <input
                              id="mobile"
                              name="mobile"
                              className="form-control rounded-1"
                              value={this.state.mobile}
                              onChange={this.handleChange}
                              placeholder="Enter mobile number"
                              readOnly={true}
                            />
                            <div className="errorMsg">
                              {this.state.errors.mobile}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-12">
                          <div id="Email" className="form-group">
                            <label
                              htmlFor="Email"
                              id="currentadd"
                              className="control-label"
                            >
                              Email Address <span style={{ color: "#ff0000" }}>*</span>
                            </label>
                            <input
                              type="text"
                              id="Email"
                              name="email"
                              value={this.state.email}
                              onChange={this.handleChange}
                              className="form-control"
                              placeholder="abc@gmail.com"
                            />
                            <div className="errorMsg">
                              {this.state.errors.email}
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="row mt-2">

                        {/* <div className="col-lg-6 col-md-6 col-6">
                          <div>
                            {" "}
                            Gender &nbsp;&nbsp;
                            <br />
                            <input
                              style={{ marginTop: "10px" }}
                              type="radio"
                              id="html"
                              name="gender"
                              value="male"
                              onChange={this.handleChange}
                            />

                            <label for="male">Male</label>
                            <input
                              type="radio"
                              id="female"
                              name="gender"
                              value="female"
                              onChange={this.handleChange}
                              style={{
                                marginLeft: "5px",
                              }}
                            />
                            <label for="female">Female</label>
                            <input
                              type="radio"
                              id="other"
                              name="gender"
                              value="other"
                              onChange={this.handleChange}
                              style={{
                                marginLeft: "5px",
                              }}
                            />
                            <label for="javascript">Other</label>
                            <div className="errorMsg">
                              {this.state.errors.gender}
                            </div>
                          </div>

                        </div> */}

                        <div className="col-lg-6 col-md-6 col-6">
                          <div>
                            Gender &nbsp;&nbsp;
                            <br />
                            <input
                              style={{ marginTop: "10px" }}
                              type="radio"
                              id="male"
                              name="gender"
                              value="male"
                              onChange={this.handleChange}
                              checked={this.state.gender === "male"}
                            />
                            <label htmlFor="male">Male</label>

                            <input
                              type="radio"
                              id="female"
                              name="gender"
                              value="female"
                              onChange={this.handleChange}
                              style={{ marginLeft: "5px" }}
                              checked={this.state.gender === "female"}
                            />
                            <label htmlFor="female">Female</label>

                            <input
                              type="radio"
                              id="other"
                              name="gender"
                              value="other"
                              onChange={this.handleChange}
                              style={{ marginLeft: "5px" }}
                              checked={this.state.gender === "other"}
                            />
                            <label htmlFor="other">Other</label>

                            <div className="errorMsg">
                              {this.state.errors.gender}
                            </div>
                          </div>
                        </div>


                        <div className="col-lg-6 col-md-6 col-12">
                          <div id="stateDiv" className="form-group" style={{ marginTop: "0px" }}>
                            <label htmlFor="states" className="control-label">
                              Select State
                            </label>
                            <select
                              id="states"
                              className="form-select"
                              name="state_name"
                              value={this.state.state_name}
                              // onChange={(e) => {
                              //   this.setState({
                              //     state_code: e.target.value,
                              //     state_name: "Maharashtra",
                              //   });
                              // }}
                              disabled
                              required={true}
                            >
                              <option value={this.state.state_name}>
                                {this.state.state
                                  ? this.state.state
                                  : "--Choose State--"}
                              </option>
                              <option value="MH">Maharashtra</option>
                              {/* <option value="GA">Goa</option> */}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-lg-6 col-md-6 col-12">
                          <div id="stateDiv" className="form-group">
                            <label
                              htmlFor="citys"
                              id="cityLabel"
                              className="control-label"
                            >
                              Select City <span style={{ color: "#ff0000" }}>*</span>
                            </label>
                            <select
                              id="citys"
                              name="city"
                              className="form-select"
                              // onChange={this.showCity}
                              onChange={this.handleChange}
                              required={true}
                            >
                              <option value={this.state.city}>
                                {this.state.city
                                  ? this.state.city
                                  : "--- choose city---"}
                              </option>
                              {this.state.CityData.map((e, key) => {
                                return (
                                  <option key={key} value={e.countryCode}>
                                    {e.name}
                                  </option>
                                );
                              })}
                            </select>
                            <div className="errorMsg">
                              {this.state.errors.city}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-12">
                          <div id="stateDiv" className="form-group">
                            <label
                              htmlFor="citys"
                              id="cityLabel"
                              className="control-label"
                            >
                              Select Assigned city <span style={{ color: "#ff0000" }}>*</span>
                            </label>
                            <select
                              id="citys"
                              name="district"
                              className="form-select"
                              type="button"
                              onChange={this.selectCity}
                            >
                              <option value={null}>--Choose City/Cities--</option>
                              {Array.isArray(this.state.CityData) &&
                                this.state.CityData.length > 0 &&
                                this.state.CityData?.map((e, key) => {
                                  return (
                                    <option key={key} value={e.name}>
                                      {e.name}
                                    </option>
                                  );
                                })}
                            </select>

                            <div className="d-flex flex-row bd-highlight mb-1">
                              {this.state.districts.map((c) => (
                                <div
                                  className="p-1 bd-highlight"
                                  key={Math.floor(Math.random() * 9999999999)}
                                >
                                  <div
                                    onClick={() => {
                                      let _district = this.state.districts;
                                      if (this.state.districts.length > 0) {
                                        _district = _district.filter(
                                          (x) => x !== c
                                        );
                                        this.setState({ districts: _district });
                                      }
                                    }}
                                  >
                                    <button className="btn-sm btn-primary ">
                                      {c}{" "}
                                      <i type="delete" id="btnSubmitSignup">
                                        ❌
                                      </i>
                                    </button>
                                  </div>
                                </div>
                              ))}
                              <div className="errorMsg">
                                {this.state.errors.districts}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-lg-6 col-md-12 col-12">
                          <div id="verified" className="form-group">
                            <label
                              htmlFor="is_verified"
                              id="verified"
                              className="control-label"
                              style={{ marginTop: "15px" }}
                            >
                              Verification Status <span style={{ color: "#ff0000" }}>*</span>
                            </label>
                            <select
                              id="status"
                              name="verified"
                              value={this.state.verified}
                              onChange={this.handleChange}
                              style={{ marginLeft: "5px", padding: "5px" }}
                            >
                              {" "}
                              <option value={null}> Select </option>
                              <option value={true}>True</option>
                              <option value={false}>False</option>
                            </select>
                            <div className="errorMsg">
                              {this.state.errors.is_verified}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="errorMsg" style={{marginLeft:"15px"}}>{this.state.errorsGETDATA}</div>*/}
                      <div className="row">
                        <div className=" col-12 col-md-12 d-flex justify-content-center align-items-center">
                          <button
                            type="button"
                            id="btnSubmitProfile"
                            className="btn btn-dark btn-lg btn-block mt-4 m-auto"
                            onClick={this.handleSubmit}
                          >
                            {this.state.loader ? <LoaderRounded /> : "Update"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Editcoordinator;
