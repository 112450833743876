import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';


export default class VoteDisputetpop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showVoteCastModal: false,
            redirect: false,

        };
    }

    isShowVoteDisputetpopModal = (status) => {
        this.handleClose();
        this.setState({
            showVoteDisputetpopModal: status,
        });
    }

    handleClose = () => {
        this.props.onPopupClose();
    }

    render() {
        return (
            <>
              
                <Modal show={this.props.showVoteDisputetpop} onHide={this.handleClose} centered>
                    <Modal.Body>
                        <div style={{ textAlign: "center" }}>
                        <h5><b>Disputed</b></h5>
                            <label><h5>Vote Disputed successfully</h5></label>
                            {/* <label><h5>Vote Disputed or Approved successfully</h5></label> */}
                        </div>
                    </Modal.Body>
                    <Modal.Footer >
                        <div style={{ textAlign: "center" }}>
                            <Button onClick={this.props.onPopupClose} className="btn btn-sm rounded-pill border mb-2"style={{ backgroundColor:'#000', padding: '10px 20px',color:'#fff'}}>Done</Button>
                        </div>
                    </Modal.Footer>
                </Modal>

            </>
        )
    }


}




