import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';
import { Edit } from '@material-ui/icons';
import axios from 'axios';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import LoaderRounded from "./LoaderRounded";

export default class ViewPendingCollegeStudent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            role: '',
            college: [],
            view_student: false,
            redirect: false,
            loader: true,
            editUser: false,
        };
        this.deleteCollege = this.deleteCollege.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.getData();
        this.setState({ role: localStorage.getItem('role') });
    }
    getData() {
        let currentComponent = this;
        let collegeCode = localStorage.getItem("college_code");
        axios.get(process.env.REACT_APP_APIURL + `/users/collegestudents?college_code=` + collegeCode)
            .then(res => {
                if (res.data.message) {
                    currentComponent.setState({
                        college: [],
                        view_student: true,
                        loader: false,
                    });
                } else {
                    let responses = res.data.data;
                    currentComponent.setState({
                        college: responses,
                        loader: false,
                    });
                }
            });
    }

    EditCollege(collegeName) {
        localStorage.setItem("yin_id", collegeName);
        this.setState({
            editUser: true,
        })
    }

    deleteCollege() {
        let college_code = localStorage.getItem("college_code");
        axios.post(process.env.REACT_APP_APIURL + `/coordinator/college/delete`, { college_code })
            .then(res => {
                // alert("College deleted!!");
                this.setState({
                    redirect: true
                })
            }).catch(error => {
                console.log(error);
            });
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to="/pending-college" />
        }

        if (this.state.editUser) {
            return <Redirect to="/edit/pending/user" />
        }

        return (
            <>
                <Sidebar />
                <div className="main">
                    <div className="topbar">
                        <Navbar />
                    </div>

                    <div className="container">
                        <BackAndBreadCrmOneParent backLink="/pending-college" currentTabName="Pending college student list" />
                        <div className="row">
                            <div className="col-12">
                                <h2 className="text-center">{localStorage.getItem("college_name") ? localStorage.getItem("college_name") : ""}</h2>
                                <u><h2 className="text-center divider-20">Pending College Student List</h2></u>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="table-responsive-sm">
                                    <table className="table table-bordered table-responsive">
                                        <thead>
                                            <tr>
                                                <th>YIN ID</th>
                                                <th>Student Name</th>
                                                <th>Mobile</th>
                                                <th>College Name</th>
                                                <th>City</th>
                                                <th>User Type</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.loader ? <LoaderRounded /> :
                                                this.state.view_student ? "NO student found in this college" :
                                                    this.state.college ? this.state.college.map((colleges, index) => (
                                                        <tr key={index} >
                                                            <td>{colleges.yin_id}</td>
                                                            <td>{colleges.first_name} {colleges.last_name}</td>
                                                            <td>{colleges.mobile}</td>
                                                            <td>{colleges.college_name}</td>
                                                            <td>{colleges.city}</td>
                                                            <td>{colleges.user_type}</td>
                                                            <td>
                                                                <span onClick={() => this.EditCollege(colleges.yin_id)} ><Edit /></span>
                                                            </td>
                                                        </tr>
                                                    )) : "NO Pending College student found"

                                            }
                                        </tbody>
                                    </table>

                                </div>
                            </div>

                            {this.state.view_student ? <div className="col-12">
                                <button type="button"
                                    id="btnSubmitSignup"
                                    className="btn btn-danger btn-lg btn-block m-4 "
                                    onClick={this.deleteCollege}>Delete This college</button>

                            </div> : ""}

                        </div>
                    </div>
                </div>
            </>
        )
    }
}
