import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom'
import axios from 'axios';
import LoaderRoundedWithText from "./LoaderRoundedWithText";

class ImageIdPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            showVoteCastModal: false,
            college_id: '',
            error: '',
            loader: false

        };
        this.uploadDocument = this.uploadDocument.bind(this);
        this.onImageChange = this.onImageChange.bind(this);
    }

    isShowModal = () => {
        this.handleClose();
    }

    handleClose = () => {
        this.props.onPopupCloseMain();
    }

    onImageChange(e) {
        this.setState({
            college_id: e.target.files[0],
        });
    }

    uploadDocument() {
        if (this.state.college_id === "") {
            this.setState({
                error: "Please select image"
            });
        } else {
            this.setState({
                loader: true,
                error: '',
            });
            let formDataObj = new FormData();
            formDataObj.append("yin_id", this.props.yin_id);
            formDataObj.append("college_id", this.state.college_id);
            // axios.post(process.env.REACT_APP_ALL_API+'/v1/candidate/upload/document',formData, {
            axios.post(process.env.REACT_APP_APIURL + `/users/update/document/web`, formDataObj, {
                headers: {
                    "Content-type": "multipart/form-data",
                },
            }).then(res => {
                this.setState({
                    loader: false,
                });
                // this.props.imageUrl="https://foxberry-images.s3.amazonaws.com/yin/MHSAT00004359/8630profile.png";
                this.props.onPopupCloseMain();
            }).catch(error => {
                this.setState({
                    loader: false,
                    error: "image not uploaded!!!"
                });
                this.props.onPopupCloseMain();
            });
        }

    }

    render() {
        return (
            <>
                <div className="DisputePopup" >
                    <Modal show={this.props.showimageIdPopup} onHide={this.handleClose}
                        centered>
                        <Modal.Header closeButton><b>{this.props.collegeId_card}</b></Modal.Header>
                        <Modal.Body >
                            <div>
                                <label htmlFor="goverment_ID" id="goverment_ID" className="control-label">College Id</label>
                                <input type="file" id="form_photo" name="form_photo" className="form-control-file" onChange={this.onImageChange} />
                                {/* <img src={this.props.imageUrl} width="200px" height="200px" alt="collegeId"/> */}

                            </div>
                        </Modal.Body>
                        <Modal.Footer >
                            <div className="errorMsg"><b>{this.state.error}</b></div>
                            <div>{this.state.loader ? <LoaderRoundedWithText loadingText="Please wait image uploading..." /> : ""}</div>
                            <Button className="btn btn-sm rounded-pill border  DisputebtnCancel mb-2" onClick={this.uploadDocument}>Upload</Button>
                            <Button onClick={this.props.onPopupCloseMain} className="btn btn-sm rounded-pill DisputebtnCancel border mb-2">Close</Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </>
        )
    }
}

export default withRouter(ImageIdPopup);