import React, { useEffect, useState } from 'react'
import Sidebar from '../Sidebar'
import Navbar from '../Navbar';
import BackAndBreadCrmOneParent from "../BackAndBreadCumOneParent";
import axios from 'axios';

const ElectionResult = () => {
  const college_code = localStorage.getItem("collegeCode")
  const [collegeResult, setCollegeResult] = useState([])

  useEffect(() => {
    getElectionResult()
  }, [])

  const getElectionResult = () => {
    axios.get(process.env.REACT_APP_VOTINGAPI + `/voting/resultDetailsByCollegeCode/${college_code}`)
      .then((response) => {
        setCollegeResult(response?.data)
      })
      .catch((error) => {
        console.log("error", error);
      })
  }

  return (
    <>
      <Sidebar />
      <div className="main">
        <div className="topbar">
          <Navbar />
        </div>

        <div className="container">
          <BackAndBreadCrmOneParent
            backLink="/view-card"
            backName="View card"
            currentTabName="Election Result"
          />

          <h2 className="text-center">Election Result</h2>

          <div className="row mx-3 mt-4">
            <div className="col-12">
              <table className="table table-bordered table-responsive">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Full Name</th>
                    <th>College Name</th>
                    <th>Election Date and Time</th>
                    <th>City</th>
                    {/* <th>Status</th> */}
                    {/* <th>Actions</th> */}
                  </tr>
                </thead>
                <tbody>
                  {
                    collegeResult !== null && collegeResult !== undefined && collegeResult.length > 0 ?
                      collegeResult.map((itm, index) => {
                        const dateObject = new Date(itm?.vote_time);
                        const formattedDate = dateObject.toLocaleDateString('en-GB', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric'
                        });
                        const formattedTime = dateObject.toLocaleTimeString('en-US', {
                          hour12: false,
                          hour: '2-digit',
                          minute: '2-digit',
                          second: '2-digit'
                        });
                        const combinedDateTime = `${formattedDate} ${formattedTime}`;
                        return <tr>
                          <td>{index + 1}</td>
                          <td>{itm?.first_name + " " + itm?.last_name}</td>
                          <td>{itm?.college_name}</td>
                          <td>{combinedDateTime}</td>
                          <td>{itm?.city}</td>
                        </tr>
                      }) : "No Data Found"
                  }
                </tbody>
              </table>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default ElectionResult
