// import { configureStore } from "@reduxjs/toolkit";
import UserDataReducer from "../Slice/UserDataSlice";
import awsCredReducer from "../Slice/awsCredSlice";

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import persistReducer from "redux-persist/es/persistReducer";

import { setupListeners } from "@reduxjs/toolkit/query";
import persistStore from "redux-persist/es/persistStore";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage: storage,
};
const combinedReducer = combineReducers({
  UserData: UserDataReducer,
  aws:awsCredReducer
});

const rootReducer = (state, action) => {
  if (action.type === "login/setLogout") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

const PersistReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: { PersistReducer },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
setupListeners(store.dispatch);
export const persistor = persistStore(store);
