import React from 'react'
import Sidebar from '../../../Sidebar'
import Navbar from '../../../Navbar'

const ElectionVoter = () => {
  return (
      <>
      <Sidebar/>
        <div className="main">
          <div className="topbar">
            <Navbar title="Election voter for all colleges"/>
          </div>
        </div>
    </>
  )
}

export default ElectionVoter
