
import React, { Component } from "react";
import axios from "axios";
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import LoaderRounded from "./LoaderRounded";

export default class EditLocation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            event_address: "",
            event_address2: "",
            State: "",
            cities: "",
            event: localStorage.getItem('Eventid'),
            event_pincode: "",
            event_latitude: "",
            event_longitude: "",
            latitude: "",
            longitude: "",
            event_id: "",
            is_navigation_added: "",
            errors: {}

        };


        this.handleFormChange = this.handleFormChange.bind(this);


    }
    handleFormChange(event) {

        this.setState({
            [event.target.name]: event.target.value

        });
    }


    //code for to get states lists
    componentDidMount() {
        this.getDatabyeventid();
        this.updatedata();
    }

    getDatabyeventid() {
        let eventid = this.state.event
        alert(eventid)

        let currentComponent = this;


        axios.get(process.env.REACT_APP_TALK_API + '/event/list/event/location/' + eventid).then(res => {
            let responses = res.data.data;

            currentComponent.setState({
                // redirect:true,
                ...responses
                // event_title:responses[0].event_title,

            });
        }
        );

    }

    updatedata() {
        const updateevent = {
            event_id: localStorage.getItem('Eventid'),

        };
        let currentComponent = this;
        axios.post(process.env.REACT_APP_TALK_API + '/event/edit/event/location', updateevent).then(res => {
            let data = res.data.data;
            currentComponent.setState({
                // redirect:true,
                ...data[0],

            });
        }
        );

    }

    // handleSubmitforupdate = async event => {
    //     event.preventDefault();
    //     // var id = localStorage.getItem("_id")
    //     const updateevent = {
    //         event_id:localStorage.getItem('Eventid'),
    //         event_address: this.state.event_address,
    //         event_address2: this.state.event_address2,
    //         event_pincode: this.state.event_pincode,
    //         event_latitude: this.state.event_latitude,
    //         event_longitude: this.state.event_longitude,
    //         is_navigation_added: false
    //     };

    //     let statesSet = this;
    //     await axios.post("", +updateevent).then(res => {


    //         if (res.status === 200) {
    //             alert(' Event Updated successfully');

    //             statesSet.setState({
    //                 changeToView: true,
    //                 redirect: true,
    //             })
    //         }

    //     })
    // }





    render() {
        if (this.state.is_navigation_added === false) {
            this.props.history.push("/Add-Location")

        }

        return (
            <>
                <Sidebar />
                <div className="main">
                    <div className="topbar">
                        <Navbar />
                    </div>
                    <div className="container">
                        <BackAndBreadCrmOneParent backLink="/View-Location" backName="ViewLocation" currentTabName="ViewLocation" />
                        <div className="row mt-4">
                            <div
                                style={{
                                    heigh: "800vh",
                                    overflow: "hidden",
                                }}
                                className="col-lg-8 col-md-8 col-12 paddingAll10 mx-auto ">

                                <div className="card rounded shadow shadow-sm " >
                                    <div className="card-header">
                                        <h3 className="mb-0 center">Edit Location</h3>
                                    </div>
                                    <div className="card-body">
                                        {/* <center>
                                        <img src={this.state.profile_image} onChange={this.handleFormChange} width="100px" height="100px" alt="avtar" className="rounded-circle" />
                                    </center> */}
                                        <form id="candidate" method="post" onSubmit={this.handleSubmit} encType="multipart/form-data">

                                            <div className="form-group">
                                                <label htmlFor="Address2" className="control-label">Address Lane 1</label>
                                                <input type="text" id="Address2" name="event_address" value={this.state.event_address} onChange={this.handleFormChange} className="form-control" placeholder="Type here" required ></input>
                                                {/* <div className="errorMsg">{this.state.errors.event_title}</div> */}
                                            </div>
                                            <br />
                                            <hr className="mx-n3" />

                                            <div className="form-group">
                                                <label htmlFor="Address2" className="control-label">Address Lane 2</label>
                                                <input type="text" id="Address2" name="event_address2" value={this.state.event_address2} onChange={this.handleFormChange} className="form-control" placeholder="Type here" required ></input>
                                                {/* <div className="errorMsg">{this.state.errors.event_title}</div> */}
                                            </div>
                                            <br />
                                            <hr className="mx-n3" />

                                            <div className="form-group">
                                                <label htmlFor="Eventcity" className="control-label">Event State</label>
                                                <input type="text" id="Eventstate" name="event_title" value={this.state.state} readOnly onChange={this.handleFormChange} className="form-control" placeholder="Type here" required ></input>
                                                {/* <div className="errorMsg">{this.state.errors.event_title}</div> */}
                                            </div>
                                            <br />
                                            <hr className="mx-n3" />

                                            <div className="form-group">
                                                <label htmlFor="Eventcity" className="control-label">Event City</label>
                                                <input type="text" id="Eventcity" name="event_title" value={this.state.cities} readOnly onChange={this.handleFormChange} className="form-control" placeholder="Type here" required  ></input>
                                                {/* <div className="errorMsg">{this.state.errors.event_title}</div> */}
                                            </div>
                                            <br />
                                            <hr className="mx-n3" />

                                            <div className="form-group">
                                                <label htmlFor="pincode" className="control-label">Pincode</label>
                                                <input type="text" id="Pincode" name="event_pincode" value={this.state.event_pincode} onChange={this.handleFormChange} className="form-control" placeholder="Type here" required  ></input>
                                                {/* <div className="errorMsg">{this.state.errors.event_title}</div> */}
                                            </div>
                                            <br />
                                            <hr className="mx-n3" />

                                            <div className="form-group">
                                                <label htmlFor="latitude" className="control-label">Latitude</label>
                                                <input type="text" id="latitude" name="event_latitude" value={this.state.event_latitude} onChange={this.handleFormChange} className="form-control" placeholder="Type here" required ></input>
                                                {/* <div className="errorMsg">{this.state.errors.event_title}</div> */}
                                            </div>
                                            <br />
                                            <hr className="mx-n3" />

                                            <div className="form-group">
                                                <label htmlFor="logitude" className="control-label">longitude</label>
                                                <input type="text" id="logitude" name="event_longitude" value={this.state.event_longitude} onChange={this.handleFormChange} className="form-control" placeholder="Type here" required ></input>
                                                {/* <div className="errorMsg">{this.state.errors.event_title}</div> */}
                                            </div>
                                            <br />
                                            <hr className="mx-n3" />



                                            <div className="form-group">
                                                {/* <button type="button" onClick={this.handleSubmit}  className="btn btn-primary btn-lg btn-block mb-2 mt-4" style={{ margin: "4px" }}>Update</button> */}
                                                <button onClick={this.updatedata} id="btnSubmitSignup" className="btn btn-success btn-lg btn-block mb-2 mt-4" style={{ margin: "4px" }}>{this.state.loader ? <LoaderRounded /> : "Update"}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}



