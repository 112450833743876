import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import Navbar from "./Navbar";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Add } from "@material-ui/icons";
import axios from "axios";
import LoaderRounded from "./LoaderRounded";
import ReactPaginate from "react-paginate";
import profileImage from "../Assets/images/noimahe.png";
import "../Assets/css/Dashboard.css";
import LoaderRoundedWithText from "./LoaderRoundedWithText";
import moment from "moment";

const NotificationList = () => {
  const [data, setData] = useState([]);
  const [selectedPage, setSelectedPage] = useState(0);
  const [renderPageLimit, setRenderPageLimit] = useState(10);
  const [district, setDistrict] = useState();
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [year, setYear] = useState("")
  const [years, setYears] = useState([])

  const getAllNotificationData = async () => {
    setLoader(true);
    let data = {
      prevCnt: 0,
      isNotification: true,
      year: year
    };
    await axios.post(process.env.REACT_APP_APIURL + "/notificationCtrl/getAllUserNotification", data)
      .then((res) => {
        setData(res.data.result);
        setLoader(false);
      })
      .catch((err) => {
        console.log("error", err);
        setLoader(false);

      });
  };

  const handlePageChange = (page) => {
    setSelectedPage(page.selected);
  };

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
    getAllNotificationData();

    // years code 
    const currentYear = new Date().getFullYear();
    const initialYears = generateYears(2021, currentYear);
    setYears(initialYears);
  }, [year]);

  const getDistrictByUserType = () => {
    const getDistData = localStorage.getItem("districts");
    const districtData = JSON.parse(getDistData)
    const getNewDistData = districtData?.map((itm, index) => {
      return itm
    })
    setDistrict(getNewDistData)
  }

  const generateYears = (start, end) => {
    const yearsArray = [];
    for (let year = end; year >= start; year--) {
      yearsArray.push(year);
    }
    return yearsArray;
  };

  return (
    <div>
      {loading ? (
        <LoaderRoundedWithText />
      ) : (
        <div className="payments">
          <Sidebar />
          <div className="main">
            <div className="topbar">
              <Navbar title="Notification List" />
            </div>
            <div className="mt-2">
              <BackAndBreadCrmOneParent
                backLink="dashboard"
                currentTabName="Trasaction List"
              />
            </div>

            <Row md={12} className="mx-4 mb-4 mt-2 justify-content-end">
              <Col md={3}>
                <select
                  name="year"
                  className="btn dropdown-toggle mb-2 w-100"
                  style={{ backgroundColor: "#003147", color: "white" }}
                  onChange={(e) => {
                    setYear(e.target.value)
                  }}
                  value={year}>
                  <option value="">All Years</option>
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </Col>

              <Col md={3} className="">
                <Link
                  to="/sendnotification"
                  className="btn w-100"
                  style={{ backgroundColor: "#003147", color: "white" }}>
                  <Add className="me-1" />
                  Add Notification
                </Link>
                {/* </Col>
            </Row> */}

              </Col>

            </Row>

            <div className="container text-center mt-3">
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive-sm">
                    <table className="table table-bordered table-responsive">
                      <thead>
                        <tr className="text-center">
                          <th>No</th>
                          <th>Image</th>
                          <th>Notification Title</th>
                          <th>Notification Description</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loader ? (
                          <LoaderRounded />
                        ) :
                          (data?.length > 0 ?
                            data?.slice(
                              selectedPage * renderPageLimit,
                              renderPageLimit + selectedPage * renderPageLimit
                            ).map((item, i) => {
                              return (
                                <>
                                  <tr>
                                    <td>
                                      {selectedPage * renderPageLimit + i + 1}
                                    </td>
                                    <td>
                                      {
                                        item?.image !== null &&
                                          item?.image?.length > 0
                                          ? (
                                            <img
                                              src={item.image}
                                              width="50"
                                              height="50"
                                              style={{ borderRadius: "50%" }}
                                              alt="profilephoto"
                                            />
                                          ) : (
                                            <img
                                              src={profileImage}
                                              width="50"
                                              height="50"
                                              style={{ borderRadius: "50%" }}
                                              alt="noprofile"
                                            />
                                          )
                                      }
                                    </td>
                                    <td>{item.title}</td>
                                    <td>{item.message}</td>
                                    <td>
                                      {item.createdAt
                                        ? moment(item.createdAt).format(
                                          "DD MMM YYYY hh:mm a"
                                        )
                                        : "-"}
                                    </td>
                                  </tr>
                                </>
                              );
                            }) : (
                              <tr><td colSpan="6" className="text-center">No Data Found....</td></tr>
                            )
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="overflow-auto mt-2">
                  <ReactPaginate
                    nextLabel="Next"
                    previousLabel="Previous"
                    pageCount={data?.length / renderPageLimit}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={10}
                    containerClassName="pagination"
                    activeClassName="btn btn-primary"
                    pageClassName="btn"
                    previousClassName="btn"
                    nextClassName="btn"
                    onPageChange={handlePageChange}
                  />
                </div>

              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationList;
