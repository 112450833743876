import React, { Component } from "react";

import ReactLoading from "react-loading";
import "../Assets/css/FrontLoader.css";

class LoaderRounded extends Component {
  render() {
    return (
      <div>
        <div className="roundSpinner"></div>
      </div>
    );
  }
}

export default LoaderRounded;
