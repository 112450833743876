import React, { Component } from "react";
import axios from "axios";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { Redirect } from "react-router-dom";
import Select from "react-select";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import ImagePopup from "./ImagePopup";
import LoaderRounded from "./LoaderRounded";
import { Col, Row } from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';
import BackAndBreadCumOneParent from "./BackAndBreadCumOneParent";

class EditUserList extends Component {
  constructor(pops) {
    super(pops);

    this.state = {
      errors: [],
      address_line_1: "",
      images: "",
      address_line_2: "",
      application_status: "",
      city: "",
      college_code: [],
      collegeCodes: [],
      college_year: "",
      approved_user_form: "",
      college_id: [""],
      college_name: "",
      country: "",
      created_at: "",
      description: "",
      dob: "",
      document: "",
      email: "",
      email_is_verified: false,
      first_name: "",
      gender: "",
      last_name: "",
      middle_name: null,
      mobile: "",
      pincode: "",
      profile_completion: 0,
      profile_image: "",
      responsibility: [],
      roles: [],
      state: "",
      status: false,
      taluka: "",
      user_type: "",
      verified: false,
      yin_id: "",
      dropDownOptCollegeList: [],
      redirect: false,
      year: this?.props?.state?.year,
      StateData: [],
      CityData: [],
      showModalPopup: false,
      loader: false,
      cities: this.props.location.state?.city || null,
      year: this.props.location.state?.year || null,
    };
    this.initialState = this.state;
    this.handleReject = this.handleReject.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
    this.getCollege = this.getCollege.bind(this);
    this.ChangeCity = this.ChangeCity.bind(this);
    this.showCity = this.showCity.bind(this);
    this.handleFormValidation = this.handleFormValidation.bind(this);
    //this.onCVChange = this.onCVChange.bind(this);
  }

  isShowPopup = (status) => {
    this.setState({ showModalPopup: status, disabled: true });
  };

  isClosePopup = () => {
    this.setState({ showModalPopup: false });
  };

  UNSAFE_componentWillMount() {
    const { location } = this.props;
    this.getData();
    this.getCollege();
    this.citylist();
  }

  getData() {
    this.setState({
      loader: true,
    });
    let currentComponent = this;
    let yinID = localStorage.getItem("yin_id");
    axios.get(process.env.REACT_APP_APIURL + `/candidate/yin/` + yinID)
      .then((res) => {
        this.setState({
          loader: false,
        });
        let responses = res.data;
        currentComponent.setState({
          address_line_1: responses[0]?.address_line_1.trim(),
          address_line_2: responses[0]?.address_line_2.trim(),
          application_status: responses[0]?.application_status.trim(),
          city: responses[0]?.city.trim(),
          college_code: responses[0]?.college_code,
          college_id: responses[0]?.college_id,
          college_name: responses[0]?.college_name,
          college_year: responses[0]?.college_year,
          country: responses[0]?.country,
          description: responses[0]?.description,
          dob: responses[0]?.dob,
          document: responses[0]?.document,
          email: responses[0]?.email.trim(),
          email_is_verified: responses[0]?.email_is_verified,
          first_name: responses[0]?.first_name.trim(),
          gender: responses[0]?.gender.trim(),
          last_name: responses[0]?.last_name.trim(),
          middle_name: responses[0]?.middle_name,
          mobile: responses[0]?.mobile,
          pincode: responses[0]?.pincode,
          profile_completion: responses[0]?.profile_completion,
          profile_image: responses[0]?.profile_image,
          responsibility: responses[0]?.responsibility,
          roles: responses[0]?.role,
          state: responses[0]?.state,
          status: responses[0]?.status,
          taluka: responses[0]?.taluka,
          user_type: responses[0]?.user_type,
          verified: responses[0]?.verified,
          yin_id: responses[0]?.yin_id,
        });
      })
      .catch((err) => {
        this.setState({
          loader: false,
        });
      });
  }

  ChangeCity = (e) => {
    e.preventDefault();
    axios.get(process.env.REACT_APP_APIURL + "/address/city/" + e.target.value)
      .then((response) => {
        this.setState({
          CityData: response.data,
          state: e.target.value,
        });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  citylist() {
    let districts = JSON.parse(localStorage.getItem("districts"));
    if (districts[0] !== "*") {
      this.setState({
        CityData: districts,
      });
    } else {
      axios
        .get(process.env.REACT_APP_APIURL + `/address/city/MH`)
        .then((response) => {
          this.setState({
            CityData: response.data,
          });
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }
  }

  showCity = (e) => {
    e.preventDefault();
    let index = e.target.selectedIndex;
    this.setState({ city: e.target[index].innerText });
  };

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value.trim(),
    });
  }

  onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];

      this.setState({
        images: URL.createObjectURL(img),
        college_Id_photo: img,
      });
    }
  };

  onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      this.setState({
        images: event.target.files[0],
      });
    } else {
      console.log("images not selected");
    }
  };

  getCollege = async () => {
    this.setState({
      loader: true,
    });
    // let city = localStorage.getItem("city_name");
    let districts = JSON.parse(localStorage.getItem("districts"));
    if (districts.length) {
      await axios
        .post(process.env.REACT_APP_APIURL + `/coordinator/college/all/city`, {
          districts,
        })
        .then((API) => {
          this.setState({
            loader: false,
          });
          const serverResponse = API.data;
          const dropDownValue = serverResponse.map((response) => ({
            value: response.college_code,
            label: response.college_name,
          }));
          this.setState({
            dropDownOptCollegeList: dropDownValue,
          });
        })
        .catch((err) =>
          this.setState({
            loader: false,
          })
        );
    }
  };

  //on change event for college list dropdown
  onChangeForCollegeListDropDown(event) {
    this.setState({
      college_code: event.value,
      college_name: event.label,
      isSelect: true,
    });
    localStorage.setItem("collegeCode", event.value);
    localStorage.setItem("collegeName", event.label);
  }

  handleFormValidation = () => {
    const {
      first_name,
      last_name,
      mobile,
      college_name,
      state_name,
      city_name,
      pincode,
      address_line_1,
      address_line_2,
      gender,
      dob,
    } = this.state;
    let errors = {};
    let formIsValid = true;
    let arr = [];
    //empty user name
    const regName = /^[a-zA-Z\s]+$/;
    if (!first_name) {
      formIsValid = false;
      errors["first_name"] = "*Please enter user name.";
      arr.push({ field: "first_name", msg: "*Please enter user name." });
    }
    if (!regName.test(first_name)) {
      formIsValid = false;
      errors["first_name"] = "*Please enter alphabet characters only.";
      arr.push({
        field: "first_name",
        msg: "*Please enter alphabet characters only.",
      });
    }

    if (!last_name) {
      formIsValid = false;
      errors["last_name"] = "*Please enter user name.";
      arr.push({ field: "last_name", msg: "*Please enter user name." });
    } else if (!regName.test(last_name)) {
      formIsValid = false;
      errors["last_name"] = "*Please enter alphabet characters only.";
      arr.push({
        field: "last_name",
        msg: "*Please enter alphabet characters only.",
      });
    }
    //empty mobile no
    if (!mobile) {
      formIsValid = false;
      errors["mobile"] = "*Please enter mobile no.";
      arr.push({ field: "mobile", msg: "*Please enter mobile no." });
    }
    if (!gender) {
      formIsValid = false;
      errors["gender"] = "*Please enter gender name.";
      arr.push({ field: "gender", msg: "*Please enter college name." });
    }
    if (!dob) {
      formIsValid = false;
      errors["dob"] = "*Please enter dob name.";
      arr.push({ field: "dob", msg: "*Please enter college name." });
    }
    if (!college_name) {
      formIsValid = false;
      errors["college_name"] = "*Please enter college name.";
      arr.push({ field: "college_name", msg: "*Please enter college name." });
    }
    //empty Address1
    if (!address_line_1) {
      formIsValid = false;
      errors["Address1"] = "*Please enter address.";
      arr.push({ field: "Address1", msg: "*Please enter address." });
    }
    //empty Address2
    if (!address_line_2) {
      formIsValid = false;
      errors["Address2"] = "*Please enter address.";
      arr.push({ field: "Address2", msg: "*Please enter address." });
    } else {
      console.log("out", address_line_2);
    }

    //empty state
    if (state_name === "" || state_name === "--Choose State--") {
      formIsValid = false;
      errors["state_name"] = "Please Choose State.";
      arr.push({ field: "state_name", msg: "Please Choose State." });
    }
    //empty city
    if (city_name === "" || state_name === "--Choose City--") {
      formIsValid = false;
      errors["city_name"] = "Please Choose State.";
      arr.push({ field: "city_name", msg: "Please Choose State." });
    }

    //empty pincode
    if (!pincode) {
      formIsValid = false;
      errors["pincode"] = "*Please enter pincode.";
      arr.push({ field: "pincode", msg: "*Please enter pincode." });
    }

    this.setState({
      errors: errors,
    });
    if (arr.length > 0) {
      const firstErrorField = document.querySelector(
        `[name="${arr[0].field}"]`
      );
      if (firstErrorField && firstErrorField.focus) {
        firstErrorField.focus();
        //alert(arr[0].message); // Display error message
      }
    }
    return formIsValid;
  };

  handleReject() {
    this.setState({ redirect: true });
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.handleFormValidation()) {

      const edit = {
        yin_id: this.state?.yin_id,
        first_name: this.state?.first_name,
        last_name: this.state?.last_name,
        email: this.state?.email,
        dob: this.state?.dob,
        gender: this.state?.gender,
        address_line_1: this.state?.address_line_1,
        address_line_2: this.state?.address_line_2,
        city: this.state?.city,
        pincode: this.state?.pincode,
        collegeName: this.state?.college_name,
        collegeCode: this.state?.college_code,
      };
      axios.post(process.env.REACT_APP_APIURL + `/coordinator/user/update/data`, edit)
        .then((res) => {
          if (res.status === 200) {
            alert("User Updated successfully.");

            this.setState({ redirect: true, loader: false });
          }
        });
    }
  }

  sendDataToParent = () => {
    const data = 'Hello from child!';
    // Call the callback function passed from the parent
    this.props.onDataFromChild(data);
  };

  render() {
    const { location } = this.props;
    // const year = location.state?.year;

    const { year } = this.state;
    const { cities } = this.state;

    if (this.state.redirect) {
      return <Redirect to="/view-user" />;
      // return <Redirect to={{ pathname: '/edit/user', state: { year, cities } }} />;

    }

    return (
      <>
        <div>
          <Sidebar />
          <div className="main">
            <div className="topbar">
              <Navbar />
            </div>
            <div className="container">
              {/* <BackAndBreadCrmOneParent
                backLink="/view-user"
                backName="All user list"
                currentTabName="Edit user"
              /> */}
              <BackAndBreadCumOneParent
                backLink="/view-user"
                backName="All user list"
                currentTabName="Edit user"
                param1={year}
                param2={cities}
              />

              {this.state.loader ? (
                <LoaderRounded />
              ) : (
                <div className="row mt-5">
                  <div className="col-12 col-md-8 m-auto">
                    <div className="card rounded shadow shadow-sm">
                      <div className="card-header">
                        <h3 className="mb-0">Edit user</h3>
                      </div>
                      <div className="card-body">
                        <form id="formEdituser">

                          <Row>
                            <Col md={6} className="mb-1">
                              <div className="form-group">
                                <label
                                  htmlFor="first_name"
                                  className="control-label"
                                >
                                  User Name
                                </label>
                                <input
                                  type="text"
                                  id="first_name"
                                  name="first_name"
                                  className={`form-control rounded-1 ${this.state.errors.first_name ? "error" : ""
                                    }`}
                                  value={
                                    this.state.first_name
                                      ? this.state.first_name
                                      : ""
                                  }
                                  onChange={this.handleChange}
                                  placeholder="Enter candidate first name"
                                />
                                <div className="errorMsg">
                                  {this.state.errors.first_name}
                                </div>
                              </div>
                            </Col>

                            {this.state.middle_name ? (
                              <Col md={6} className="mb-1">
                                <div className="form-group">
                                  <label
                                    htmlFor="middle_name"
                                    className="control-label"
                                  >
                                    User Middle Name
                                  </label>
                                  <input
                                    type="text"
                                    id="middle_name"
                                    name="middle_name"
                                    className={`form-control rounded-1 ${this.state.errors.middle_name ? "error" : ""
                                      }`}
                                    value={
                                      this.state.middle_name
                                        ? this.state.middle_name
                                        : ""
                                    }
                                    onChange={this.handleChange}
                                    placeholder="Enter User middle name"
                                  />
                                  <div className="errorMsg">
                                    {this.state.errors.middle_name}
                                  </div>
                                </div>
                              </Col>
                            ) : (
                              ""
                            )}

                            <Col md={6} className="mb-1">
                              <div className="form-group">
                                <label
                                  htmlFor="last_name"
                                  className="control-label"
                                >
                                  User last Name
                                </label>
                                <input
                                  type="text"
                                  id="last_name"
                                  name="last_name"
                                  className={`form-control rounded-1 ${this.state.errors.last_name ? "error" : ""
                                    }`}
                                  value={
                                    this.state.last_name ? this.state.last_name : ""
                                  }
                                  onChange={this.handleChange}
                                  placeholder="Enter User last name"
                                />
                                <div className="errorMsg">
                                  {this.state.errors.last_name}
                                </div>
                              </div>
                            </Col>

                            <Col md={6} className="mb-1">
                              <div className="form-group">
                                <label htmlFor="mobile" className="control-label">
                                  Mobile Number
                                </label>
                                <input
                                  type="tel"
                                  id="mobile"
                                  name="mobile"
                                  className={`form-control rounded-1 ${this.state.errors.mobile ? "error" : ""
                                    }`}
                                  value={this.state.mobile ? this.state.mobile : ""}
                                  onChange={this.handleChange}
                                  placeholder="Enter mobile numner"
                                  readOnly={true}
                                />
                                <div className="errorMsg">
                                  {this.state.errors.mobile}
                                </div>
                              </div>
                            </Col>

                            <Col md={6}>
                              <div id="Email" className="form-group">
                                <label
                                  htmlFor="Email"
                                  id="currentadd"
                                  className="control-label"
                                >
                                  Email Address
                                </label>
                                <input
                                  type="text"
                                  id="Email"
                                  name="Email"
                                  value={this.state.email ? this.state.email : ""}
                                  onChange={this.handleChange}
                                  className={`form-control rounded-1 ${this.state.errors.email ? "error" : ""
                                    }`}
                                  placeholder="abc@gmail.com"
                                />
                                <div className="errorMsg">
                                  {this.state.errors.email}
                                </div>
                              </div>
                            </Col>

                            <Col md={6}>
                              <div className="form-group">
                                <label
                                  htmlFor="dob"
                                  id="dob"
                                  className="control-label"
                                >
                                  Date Of Birth{" "}
                                </label>

                                <input
                                  type="text"
                                  name="dob"
                                  value={this.state.dob ? this.state.dob : ""}
                                  onChange={this.handleChange}
                                  className={`form-control ${this.state.errors.dob ? "error" : ""
                                    }`}
                                />
                              </div>
                            </Col>

                            <Col md={6}>
                              <div id="gender" className="form-group">
                                <label
                                  htmlFor="gender"
                                  id="gender"
                                  className="control-label"
                                >
                                  Gender{" "}
                                </label>
                                <select
                                  id="gender"
                                  className="form-select"
                                  name="gender"
                                  onChange={this.handleChange}
                                  required={true}
                                >
                                  <option value={this.state.gender}>{this.state.gender}</option>
                                  <option value="male">Male</option>
                                  <option value="female">Female</option>
                                  <option value="Other">Other</option>
                                </select>
                                <div className="errorMsg">
                                  {this.state.errors.gender}
                                </div>
                              </div>
                            </Col>

                            <Col md={6}>
                              <div id="stateDiv" className="form-group">
                                <label htmlFor="state" className="control-label">
                                  Select State
                                </label>
                                <select
                                  id="state"
                                  name="state"
                                  onChange={this.ChangeCity}
                                  className={`form-control  ${this.state.errors.state_name ? "error" : ""
                                    }`}
                                >
                                  <option value={this.state.state}>
                                    {this.state.state === "MH"
                                      ? "Maharashtra"
                                      : "Maharashtra"}
                                  </option>
                                  <option value="MH">Maharashtra</option>
                                </select>
                                <div className="errorMsg">
                                  {this.state.errors.state_name}
                                </div>
                              </div>
                            </Col>

                            <Col md={6} className="mb-3">
                              <div id="cityDiv" className="form-group">
                                <label
                                  htmlFor="citys"
                                  id="cityLabel"
                                  className="control-label"
                                >
                                  Select City
                                </label>
                                <select
                                  id="citys"
                                  name="city"
                                  className={`form-select  ${this.state.errors.city_name ? "error" : ""
                                    }`}
                                  onChange={this.showCity}
                                  required={true}
                                >
                                  <option value={this.state.city}>
                                    {this.state.city
                                      ? this.state.city
                                      : "--choose city--"}
                                  </option>
                                  {this.state.CityData.map((e, key) => {
                                    return (
                                      <option key={key} value={e.countryCode}>
                                        {e.name}
                                      </option>
                                    );
                                  })}
                                </select>
                                <div className="errorMsg">
                                  {this.state.errors.city_name}
                                </div>
                              </div>
                            </Col>

                            <Col md={12} className="mb-3">
                              <div className="form-group">
                                <label
                                  htmlFor="candidateCollege"
                                  className="control-label"
                                >
                                  <span style={{ color: "#ff0000" }}>*</span>
                                  User college{" "}
                                </label>
                                <input
                                  type="text"
                                  id="candidateCollege"
                                  value={
                                    this.state.college_name
                                      ? this.state.college_name
                                      : ""
                                  }
                                  className="form-control"
                                  readOnly={true}
                                />

                                <label
                                  htmlFor="college_name"
                                  className="control-label mt-2"
                                >
                                  {" "}
                                  Change College
                                </label>

                                <Select
                                  options={this.state.dropDownOptCollegeList}
                                  onChange={this.onChangeForCollegeListDropDown.bind(
                                    this
                                  )}
                                  Redirect="/view-card"
                                />
                              </div>
                            </Col>

                            <div id="Address1" className="form-group">
                              <label
                                htmlFor="Address1"
                                id="Address1"
                                className="control-label"
                              >
                                Address line 1
                              </label>
                              <input
                                type="text"
                                name="address_line_1"
                                value={
                                  this.state.address_line_1
                                    ? this.state.address_line_1
                                    : ""
                                }
                                onChange={this.handleChange}
                                className={`form-control  ${this.state.errors.Address1 ? "error" : ""
                                  }`}
                              ></input>
                              <div className="errorMsg">
                                {this.state.errors.Address1}
                              </div>
                            </div>

                            <div id="Address2" className="form-group">
                              <label
                                htmlFor="Address2"
                                id="Address1"
                                className={`control-label  ${this.state.errors.Address2 ? "error" : ""
                                  }`}
                              >
                                Address line 2
                              </label>
                              <input
                                type="text"
                                name="address_line_2"
                                value={
                                  this.state.address_line_2
                                    ? this.state.address_line_2
                                    : ""
                                }
                                onChange={this.handleChange}
                                className="form-control"
                              ></input>
                              <div className="errorMsg">
                                {this.state.errors.Address2}
                              </div>
                            </div>

                            <Col md={6}>
                              <div id="pincode" className="form-group">
                                <label
                                  htmlFor="pincode"
                                  id="pincode"
                                  className="control-label"
                                >
                                  Pincode
                                </label>
                                <input
                                  type="number"
                                  name="pincode"
                                  value={
                                    this.state?.pincode ? this.state?.pincode : ""
                                  }
                                  onChange={this.handleChange}
                                  className={`form-control  ${this.state.errors.pincode ? "error" : ""
                                    }`}
                                  placeholder="411035"
                                ></input>
                                <div className="errorMsg">
                                  {this.state.errors.pincode}
                                </div>
                              </div>
                            </Col>

                            <Col md={6}>
                              <div id="YIN_ID" className="form-group">
                                <label
                                  htmlFor="YIN_ID"
                                  id="YIN_ID"
                                  className="control-label"
                                >
                                  YIN ID
                                </label>
                                <input
                                  type="text"
                                  name="YIN_ID"
                                  value={this.state?.yin_id ? this.state?.yin_id : ""}
                                  className="form-control"
                                  disabled
                                ></input>
                              </div>
                            </Col>


                            {this.state?.college_id ? (
                              <div id="college_Id_photo" className="form-group">
                                <label htmlFor="college_Id_photo">
                                  College ID Photo{" "}
                                </label>
                                <img
                                  src={this.state?.college_id[0]}
                                  width="100"
                                  height="60"
                                  alt=""
                                  onClick={() => this.isShowPopup(true)}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            <br />

                            <Col md={4} className="d-flex justify-content-between mx-auto">
                              <button
                                type="button"
                                id="btnSubmitSignup"
                                className="btn btn-danger btn-block "
                                onClick={this.handleReject}
                              >
                                <Icon.ArrowLeft className="me-1" /> Back
                              </button>

                              <button
                                type="button"
                                id="btnSubmitSignup"
                                className="btn btn-success  btn-block"
                                onClick={this.handleSubmit}
                              >
                                {this.state.loader ? <LoaderRounded /> : <><Icon.Save className="me-2" />Save</>}
                              </button>


                            </Col>



                          </Row>

                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <ImagePopup
          showModalPopup={this.state.showModalPopup}
          collegeId_card="College id card"
          imageUrl={this.state.college_id[0]}
          onPopupCloseMain={this.isClosePopup.bind(this)}
        ></ImagePopup>
      </>
    );
  }
}

export default EditUserList;
