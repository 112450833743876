import React from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";

class ElectionStartedCollege extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            indexno: '',
            college_name: '',
            currentFilter: null,
            college: '',
            currentPage: '',
            counts: '',
            totalPages: '',
            previousLimits: 100,
            isChecked: false,
            college_code: [],
            collegeCodes: [],
            college_key: [],
            //for dropdown purpose
            state_code: '',
            city_code: '',
            state: '',
            city: '',
            StateData: [],
            CityData: [],
            errors: {},

        }
        this.applyFiter = this.applyFilter.bind(this);
        this.setFilter = this.setFilter.bind(this);
        this.getDataWithPagination = this.getDataWithPagination.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.pageNumber = this.pageNumber.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.showCity = this.showCity.bind(this);
        this.handleCheckboxForView = this.handleCheckboxForView.bind(this)


    }
    applyFilter(arr) {

        if (!this.state.currentFilter) {
            return arr;
        }
        return arr.filter((x) => x.approver_status === this.state.currentFilter)

    }

    applyFilterCity(arr) {

        if (!this.state.currentFilter) {
            return arr;
        }
        return arr.filter((x) => x.city === this.state.currentFilter)

    }

    setFilter(e) {
        e.preventDefault();
        this.setState({
            currentFilter: e.target.value
        })
    }

    showCity() {
        axios.get(process.env.REACT_APP_APIURL + `/address/city/MH`).then(response => {
            this.setState({
                CityData: response.data,
            })
        }).catch(error => {
            console.log("Error", error);
        });

    }


    UNSAFE_componentWillMount() {
        this.getData();
        this.showCity();

    }

    getData() {
        let currentComponent = this;
        axios.get(process.env.REACT_APP_APIURL + `/college/election/list`)
            .then(res => {
                let responses = res.data;
                let con = Math.ceil(parseInt(responses.counts) / 100);

                currentComponent.setState({
                    college: responses.response,
                    currentPage: responses.currentPage,
                    counts: responses.counts,
                    totalPages: con,
                    previousLimits: 100,
                });

            });
    }

    getDataWithPagination(previous, currPage) {
        let currentComponent = this;
        axios.get(process.env.REACT_APP_APIURL + `/college/election/list?previous=` + previous + `&currentPage=` + currPage + `&limits=` + 100)
            .then(res => {
                let responses = res.data;
                let con = Math.ceil(parseInt(responses.counts) / 100);
                currentComponent.setState({
                    college: responses.response,
                    currentPage: responses.currentPage,
                    counts: responses.counts,
                    totalPages: con,
                });

            });
    }

    nextPage() {
        let previousD = parseInt(this.state.previousLimits) + 100;
        let nextPages = parseInt(this.state.currentPage) + 1;

        this.setState({
            currentPage: parseInt(nextPages) + 1,
            previousLimits: previousD,
        });

        this.getDataWithPagination(this.state.previousLimits, this.state.currentPage);
    }

    previousPage() {

        let previousD = parseInt(this.state.previousLimits) - 100;
        let currentPages = parseInt(this.state.currentPage) - 1;
        this.setState({
            currentPage: currentPages,
            previousLimits: previousD,
        });
        if (currentPages > 0) {
            this.getDataWithPagination(previousD, currentPages);
        } else {
            console.log("previous page 00000");
        }
    }

    pageNumber(event) {
        event.preventDefault();
        var previousD = 0;
        var currentPAGES = 0;
        if (event.target.value === 1) {
            previousD = 0;
            currentPAGES = 0;
        } else {
            previousD = (parseInt(event.target.value) + 1) * 100;
            currentPAGES = parseInt(event.target.value);
        }

        this.setState({
            currentPage: currentPAGES,
            previousLimits: previousD,
        });
        this.getDataWithPagination(previousD, this.state.currentPage);

    }

    handleCheckboxForView() {
        localStorage.setItem("collegeCode", this.state.collegeCodes);
    }

    handleCheckbox(event) {
        const target = event.target;
        var value = target.value;
        this.state.collegeCodes.push(value);
        //  if (target.checked) {
        //     this.state.college_code[value] = value;
        //     this.state.college_key[value]=value;
        //    console.log("college code",this.state.college_code);
        // } else {
        // this.state.hobbies.splice(value, 1);
    }

    render() {
        let pages = [];
        for (var i = 0; i < this.state.totalPages; i++) {
            pages.push(<li className="border paddingAll10" onClick={(event) => this.pageNumber(event)} value={i + 1}> {i + 1} </li>);
        }

        if (!localStorage.getItem("mobile")) {
            return <Redirect to="/login" />
        }


        return (
            <>
                <Sidebar />
                <div className="main">
                    <div className="topbar">
                        <Navbar />
                    </div>

                    <div className="container">
                        <BackAndBreadCrmOneParent backLink="/dashboard" currentTabName="Election List" />

                        <div className="row">
                            <div className="col-12">
                                <h2 className="text-center">Election List</h2>
                                <br></br>
                            </div>
                            <div className="col-12">
                                {/* <Link to='/election' onClick={this.handleCheckboxForView} className="btn btn-primary btn-sm">Stop Election</Link> */}

                                {/* <button onClick={() => { this.setState({ candidate: null }); }} type="button" className="mx-2 btn btn-secondary btn-sm" style={{ float: "right" }} >Clear</button>
                                <select type="button" className="mx-2 btn btn-secondary btn-md dropdown-toggle" id="dropdown" style={{ float: "right" }} onChange={this.setFilter}>
                                    <option value={null}> -- Select-- </option>
                                    <option value="APPROVED">Approved</option>
                                    <option value="PENDING">Pending</option>
                                    <option value="REJECTED">Rejected</option>
                                </select>
                                <select type="button" className="mx-2 btn btn-secondary btn-md dropdown-toggle" onChange={this.setFilter} id="district" style={{ float: "right", width: "120px" }}>
                                    <option value={null}> --District-- </option>
                                    <option value="Amravati"> Amravati</option>
                                    <option value="Akola"> Akola</option>

                                    {this.state.CityData.map((e, key) => {
                                        return <option key={key} value={e.city}> {e.name}  </option>;
                                    })}
                                </select>

                                <Link to="/add-college" className="btn btn-secondary btn-sm" style={{ float: "right" }}><Add />Add New</Link> */}
                            </div>

                        </div>
                    </div>
                    <br></br>
                    <div className="container">
                        <div>Current Filter: {this.state.currentFilter ?? "NO Filter"} </div>
                        <input type="checkbox" className="form-check-input" onChange={this.handleCheckbox} />
                        <label className="form-check-label ms-2">Select All</label>
                        <div className="row">
                            <div className="col-12">
                                <div className="table-responsive-sm">
                                    <table className="table table-bordered table-responsive">
                                        <thead>
                                            <tr>
                                                <th>No.</th>
                                                <th>ID</th>
                                                <th>Collegename</th>
                                                <th>City</th>
                                                <th>Type</th>
                                                <th>Status</th>
                                                {/* <th>Actions</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {this.state.college && this.applyFilterCity(this.state.college).map((colleges, index) => (
                                                <tr key={index} >
                                                    <td><input type="checkbox" className="form-check-input" onChange={this.handleCheckbox} value={colleges.college_code} /></td>
                                                    <td>{index + 1}</td>
                                                    <td>{colleges.college_name}</td>
                                                    <td>{colleges.city}</td>
                                                    <td>{colleges.type}</td>
                                                    <td>{colleges.approver_status}</td>
                                                    {/* <td>

                                                        <Link to={{
                                                            pathname: "/edit-college/" + colleges.college_name, query: { college_name: colleges.college_name }
                                                        }} ><Edit /></Link>
                                                        <Link to=""><Delete /></Link>
                                                    </td> */}

                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>

                                </div>

                            </div>
                            <div className="col-12 right">
                                <ul class="pagination floatRight">
                                    <li className="border paddingAll10" onClick={this.previousPage}>Previous</li>
                                    {pages}
                                    <li className="border paddingAll10" onClick={this.nextPage}>Next</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default ElectionStartedCollege;