import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Edit, Add, Visibility } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import Select from "react-select";
import { Redirect } from "react-router-dom";
import ReactPaginate from "react-paginate";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import LoaderRounded from "./LoaderRounded";
import LoaderRoundedWithText from "./LoaderRoundedWithText";
import { CgLayoutGrid } from "react-icons/cg";
import { Button, Container, Row, Table, Col, Spinner, Form, } from "react-bootstrap";
import { connect } from "react-redux";
import { setSelectedCollege } from "../Redux/Slice/UserDataSlice";
import * as Icon from 'react-bootstrap-icons';
class CollegeList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      indexno: "",
      renderPageLimit: 20,
      selectedPage: 0,
      college_name: "",
      currentFilter: null,
      currentFilterBy: "",
      college: "",
      currentPage: "",
      counts: "",
      totalPages: "",
      previousLimits: 100,
      isChecked: false,
      mergeCollegeDetails: [],
      college_code: [],
      collegeCodes: [],
      college_key: [],
      state_code: "",
      city_code: "",
      state: "",
      city: "",
      searchdata: "",
      StateData: [],
      CityData: [],
      errors: {},
      districtsLocal: JSON.parse(localStorage.getItem("districts")),
      subCityName: localStorage.getItem("sub_city_name") ?
        localStorage.getItem("sub_city_name") : "",
      dropDownOptCollegeList: [],
      isSelect: false,
      role: "",
      mergeError: "",
      isMerge: false,
      limitVal: 10,
      skip_value: 0,
      page_selected: 0,
      allpage: "",
      year: this.props?.location?.state?.param1 || "",
      selected_city: this.props?.location?.state?.param2 || JSON.parse(localStorage.getItem("districts")),
      years: [],
      loader: false,
      loading: true,
    };
    this.applyFiter = this.applyFilter.bind(this);
    this.setFilter = this.setFilter.bind(this);
    // this.getDataWithPagination = this.getDataWithPagination.bind(this);

    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.showCity = this.showCity.bind(this);
    this.handleCheckboxForView = this.handleCheckboxForView.bind(this);
    this.getCollegeWithCity = this.getCollegeWithCity.bind(this);
    this.getCollege = this.getCollege.bind(this);
    this.storeCollegeCode = this.storeCollegeCode.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.searchFromList = this.searchFromList.bind(this);
    this.getCollegedatapagination = this.getCollegedatapagination.bind(this);
    this.getNextCollges = this.getNextCollges.bind(this);
    this.SearchCollege = this.SearchCollege.bind(this);
    this.hanldeRowchange = this.hanldeRowchange.bind(this);
    this.getCollegeWithYear = this.getCollegeWithYear.bind(this);
  }

  handleClear = () => {
    this.getCollege(null, this.state.limitVal, this.state.skip_value);
  };


  handlePageChange(page) {
    const getDistData = localStorage.getItem("districts");
    const districtData = JSON.parse(getDistData)
    const getNewDistData = districtData?.map((itm, index) => {
      return itm
    })

    let val = page.selected === 0 ? 0 : page.selected * this.state.limitVal;

    this.setState({
      skip_value: val,
      page_selected: page.selected,
      // selected_city:getNewDistData
    });
    this.getCollege(this.state.selected_city, this.state.limitVal, val, this.state.year);
  }

  async getNextCollges(skipVal, limitVal) {
    let districts = JSON.parse(localStorage.getItem("districts"));
    let result = await this.getCollegedatapagination(
      districts,
      skipVal,
      limitVal
    );

    const serverResponse = result.data;
    const dropDownValue = serverResponse.map((response) => ({
      value: response.college_code,
      label: response.college_name,
    }));
    let con = Math.ceil(parseInt(result.count) / 20);

    this.setState({
      dropDownOptCollegeList: [],
      college: result.data,
      counts: result.count,
      totalPages: con,
    });
  }

  hanldeRowchange(event) {
    const getDistData = localStorage.getItem("districts");
    const districtData = JSON.parse(getDistData)
    const getNewDistData = districtData?.map((itm, index) => {
      return itm
    })
    this.setState({
      selected_city: getNewDistData,
      limitVal: event,
      selectedPage: 0,
    });
    this.getCollege(this.state.selected_city, event, this.state.skip_value);
  }

  componentWillMount() {
    setTimeout(() => this.setState({ loading: false }), 1000);
  }

  applyFilter(arr) {
    if (!this.state.currentFilter) {
      return arr;
    }
    let filteron = this.state.currentFilterBy;
    if (filteron === "city") {
      if (this.state.currentFilter === "Pune") {
        let subcity = localStorage.getItem("sub_city_name");
        if (subcity) {
          return arr.filter((x) => x.sub_city === subcity);
        } else {
          return arr.filter((x) => x.city === this.state.currentFilter);
        }
      } else {
        return arr.filter((x) => x.city === this.state.currentFilter);
      }
    } else {
      let a = this.state.currentFilter.toLowerCase();
      var condition = new RegExp(a);

      var result = arr.filter(function (el) {
        return condition.test(el.college_name.toLowerCase());
      });

      return result;
    }
  }

  // Search College API
  SearchCollege = async (e) => {
    let responsedata;
    await axios.get(process.env.REACT_APP_APIURL + "/college/collegeData/" + e.target.value)
      .then((res) => {
        responsedata = res.data;

        this.setState({
          college: responsedata,
        });
      })
      .catch((error) => {
        console.log("error", error);
      })
  };

  setFilter(e) {
    e.preventDefault();
    this.setState({
      currentFilter: e.target.value,
    });
  }

  showCity() {
    let districts = JSON.parse(localStorage.getItem("districts"));
    if (districts[0] !== "*") {
      this.setState({
        CityData: districts,
      });
    } else {
      axios.get(process.env.REACT_APP_APIURL + `/address/city/MH`)
        .then((response) => {
          if (Array.isArray(response.data)) {
            this.setState({
              CityData: response.data,
            });
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }
  }

  // Search College API
  SearchCollege = async (e) => {
    let responsedata;
    await axios.get(process.env.REACT_APP_APIURL + "/college/collegeData/" + e.target.value)
      .then((res) => {
        responsedata = res.data;
        if (!res.data?.message && res.data?.length > 0) {
          this.setState({
            college: responsedata,
            allpage: 0,
          });
        }
        if (res.data?.message) {
          this.getCollege(
            this.state.selected_city,
            this.state.limitVal,
            this.state.skip_value
          );
        }
      })
      .catch((error) => console.log(error))
  };


  UNSAFE_componentWillMount() {
    const getDistData = localStorage.getItem("districts");
    const districtData = JSON.parse(getDistData)
    const getNewDistData = districtData?.map((itm, index) => {
      return itm
    })
    this.getCollege(this.state.selected_city, this.state.limitVal, this.state.skip_value, this.state.year);
    this.showCity();
    this.setState({ role: localStorage.getItem("role") });
    localStorage.removeItem("collegeCode");
    localStorage.removeItem("mergeCollegeDetails");
    localStorage.removeItem("multipleElection");
  }

  // get College API
  getCollegedatapagination = async (districts, skipVal, limitVal) => {
    const payload = {
      districts: districts,
      skipValue: skipVal,
      limitValue: limitVal,
    };
    let responsedata;
    await axios.post(process.env.REACT_APP_APIURL + "/coordinator/college/all/city/pegination", payload)
      .then((res) => {
        responsedata = res.data;
      })
      .catch((error) => {
        console.log("error", error);
      })

    return responsedata;
  };

  // year dropdown code
  componentDidMount() {
    const currentYear = new Date().getFullYear();
    const initialYears = this.generateYears(2021, currentYear);
    this.setState({ years: initialYears });
  }

  generateYears(start, end) {
    const yearsArray = [];
    for (let year = end; year >= start; year--) {
      yearsArray.push(year);
    }
    return yearsArray;
  }

  handleCompleteYear = () => {
    const currentYear = new Date().getFullYear();
    this.setState((prevState) => ({
      years: [...prevState.years, currentYear + 1],
    }));
  };

  // handle year onChange
  getCollegeWithYear = (event) => {
    this.setState({
      year: event.target.value,
    });
    this.getCollege(this.state.selected_city, this.state.limitVal, this.state.view_count, event.target.value)
  }


  // SETs College
  getCollege = async (city, limitVal, skip_value, selectedYear) => {
    this.setState({
      loader: true,
    });

    await axios.get(process.env.REACT_APP_APIURL + `/college/list?limits=${limitVal ? limitVal : ""}&previous=${skip_value ? skip_value : ""
      }&currentPage=1&city=${city}&year=${selectedYear ? selectedYear : ""}`
    )
      .then((res) => {
        const serverResponse = res.data.response;
        if (Array.isArray(serverResponse)) {
          let con = Math.ceil(parseInt(res.data.counts) / this.state.limitVal);

          this.setState({
            dropDownOptCollegeList: [],
            college: res.data.response,
            counts: res.data.count,
            allpage: con,
            loader: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          loader: false,
        });
        console.log("error", err);
      });
  };

  //on change event for college list dropdown
  onChangeForCollegeListDropDown(event) {
    this.setState({
      college_code: event.value,
      college_name: event.label,
      isSelect: true,
    });
    localStorage.setItem("collegeCode", event.value);
    localStorage.setItem("collegeName", event.label);
    localStorage.setItem("college_code", event.value);
    localStorage.setItem("college_name", event.label);
  }

  getCollegeWithCity(event) {
    this.setState({
      selected_city: event.target.value
      // event.target.value === "All" ? this.state.selected_city : event.target.value,
    });
    // let selectedcity =
    //   event.target.value === "All" ? this.state.selected_city : event.target.value;
    this.getCollege(event.target.value, this.state.limitVal, this.state.skip_value, this.state.year);
  }

  getDataWithPagination(previous, currPage) {
    // let currentComponent = this;
    // axios.get(process.env.REACT_APP_APIURL + `/college/list?previous=` + previous + `&currentPage=` + currPage + `&limits=` + 100)
    //   .then((res) => {
    //     let responses = res.data;
    //     let con = Math.ceil(parseInt(responses.counts) / 100);
    //     currentComponent.setState({
    //       college: responses.response,
    //       currentPage: responses.currentPage,
    //       counts: responses.counts,
    //       totalPages: con,
    //     });
    //   });
  }

  handleCheckboxForView() {
    if (this.state.collegeCodes.length > 1) {
      localStorage.setItem("collegeCode", this.state.collegeCodes);
      localStorage.setItem(
        "mergeCollegeDetails",
        JSON.stringify(this.state.mergeCollegeDetails)
      );
      // /MergeCollegeList
      localStorage.setItem("multipleElection", 1);
      this.setState({ isMerge: true });
    } else if (this.state.collegeCodes.length > 0) {
      this.setState({ mergeError: "*Please select second college for merge" });
    } else {
      this.setState({ mergeError: "*Please select college for merge" });
    }
  }

  handleCheckbox(event) {
    const target = event.target;
    var value = target.value;
    let mergeDetails = {
      college_name: event.target.name,
      college_code: event.target.value,
      city: event.target.placeholder,
    };

    let checkExist = this.state.collegeCodes.includes(event.target.value);
    if (!checkExist) {
      this.state.collegeCodes.push(value);
      this.state.mergeCollegeDetails.push(mergeDetails);
    } else {
      let indexForDelete = this.state.collegeCodes.indexOf(event.target.value);
      let afterDeleteArray = this.state.collegeCodes.splice(indexForDelete, 1);
    }
  }

  getCollegeWithCityPegination(previous, currPage, city) {
    let currentComponent = this;
    axios.get(process.env.REACT_APP_APIURL + `/college/list?previous=` + previous + `&currentPage=` + currPage + `&limits=` + 100 + `city=` + city)
      .then((res) => {
        let responses = res.data;
        let con = Math.ceil(parseInt(responses.counts) / 100);
        currentComponent.setState({
          college: responses.response,
          currentPage: responses.currentPage,
          counts: responses.counts,
          totalPages: con,
        });
      })
      .catch((error) => {
        console.group("error", error);
      });
  }

  storeCollegeCode(collegeCode, collegeName) {
    localStorage.setItem("collegeCode", collegeCode);
    localStorage.setItem("college_code", collegeCode);
    localStorage.setItem("college_name", collegeName);
  }

  searchFromList(event) {
    this.setState({
      currentFilter: event.target.value,
      currentFilterBy: event.target.name,
    });
  }

  render() {
    const backlink = localStorage.getItem("setbacklink");

    if (!localStorage.getItem("mobile")) {
      return <Redirect to="/login" />;
    }

    if (this.state.isMerge) {
      return <Redirect to="/merge/colleges" />;
    }

    let pages = [];
    for (var i = 0; i < this.state.totalPages; i++) {
      pages.push(
        <li
          className="border paddingAll10"
          onClick={(event) => this.pageNumber(event)}
          value={i + 1}
        >
          {" "}
          {i + 1}{" "}
        </li>
      );
    }

    if (this.state.isSelect) {
      return <Redirect to="/view-card" />;
    }
    let collegeList = "";
    if (this.state.college.length > 0) {
      collegeList =
        this.state.college &&
        this.applyFilter(this.state.college).map((colleges, index) => (
          <tr key={index}>
            <td>
              <input
                type="checkbox"
                className="form-check-input"
                onChange={this.handleCheckbox}
                value={colleges.college_code}
                name={colleges.college_name}
                placeholder={colleges.city}
              />
            </td>
            <td>
              {this.state.page_selected * this.state.limitVal + index + 1}
            </td>
            <td>{colleges.college_code}</td>
            <td>{colleges.college_name}</td>
            <td>{colleges.city}</td>
            <td>{colleges.type}</td>
            <td>{colleges.approver_status}</td>
            <td className="icanostyle" style={{ minWidth: "180px" }}>
              <Link
                to={{
                  pathname: "/view-card", state: {
                    year: this.state?.year,
                    city: this?.state?.selected_city === JSON.parse(localStorage.getItem("districts")) ?
                      JSON.parse(localStorage.getItem("districts")) : this?.state?.selected_city
                  }
                }}
                onClick={() => {
                  this.storeCollegeCode(
                    colleges.college_code,
                    colleges.college_name
                  );
                  localStorage.setItem("ldpstart", colleges.ldp_ended);
                }}
              >
                <button className="btn btn-outline-success btn-sm me-2">
                  {" "}
                  <Visibility />
                </button>
              </Link>
              <Link
                to={{
                  pathname: "/edit-college/" + colleges.college_name,
                  query: { college_name: colleges.college_name },
                  state: {
                    year: this.state?.year,
                    city: this?.state?.selected_city === JSON.parse(localStorage.getItem("districts")) ?
                      JSON.parse(localStorage.getItem("districts")) : this?.state?.selected_city
                  },
                }}
                onClick={() =>
                  this.storeCollegeCode(
                    colleges.college_code,
                    colleges.college_name
                  )
                }
              >
                <button className="btn btn-outline-info btn-sm me-2">
                  <Edit />
                </button>
              </Link>


              <Link
                to={{ pathname: "/Add-Forum", data: colleges.college_code }}
                onClick={() => {
                  this.storeCollegeCode(colleges.college_code);
                  this.props.setCollegeData(colleges);
                }}
              >
                <Tooltip title="Add Forum" style={{}}>
                  <button className="btn btn-sm btn-outline-primary me-2">
                    {" "}
                    <Add />
                  </button>
                </Tooltip>
              </Link>
            </td>
          </tr>
        ));
    } else {
      collegeList = (
        <tr>
          <td colSpan="8" className="text-center">No Data Found....</td>
        </tr>
      );
    }

    let cities;
    if (
      this.state.districtsLocal[0] !== "*" &&
      this.state.CityData.length > 0
    ) {
      cities = this.state.CityData.map((e, key) => {
        return (
          <option key={key} value={e}>
            {e}
          </option>
        );
      });
    } else if (this.state.CityData.length > 0) {
      cities = this.state.CityData.map((e, key) => {
        return (
          <option key={key} value={e.name}>
            {e.name}
          </option>
        );
      });
    }

    // get users district data
    const getDistData = localStorage.getItem("districts");
    const districtData = JSON.parse(getDistData)
    const getNewDistData = districtData?.map((itm, index) => {
      return itm
    })

    return (
      <>
        {this.state.loading ? (
          <LoaderRoundedWithText />
        ) : (
          <div>
            <Sidebar />
            <div className="main">
              <div className="topbar">
                <Navbar title="College List" />
              </div>

              <div className="container mt-4">
                <Row className="mx-3" md={12}>
                  <Col md={5} className="mb-2 mb-sm-0">
                    <input
                      id="drsrgs"
                      type="text"
                      placeholder="Search here"
                      name="search"
                      onChange={(e) => this.SearchCollege(e)}
                      className="form-control w-75"
                    ></input>
                  </Col>

                  <Col md={7}>
                    <Row md={12}>
                      <Col md={3} className="mb-2 mb-sm-0">
                        <select
                          name="year"
                          className="btn dropdown-toggle mb-2 w-100"
                          style={{ backgroundColor: "#003147", color: "white" }}
                          onChange={this.getCollegeWithYear}
                          value={this.state.year}
                        >
                          <option value="">All Years</option>
                          {this.state.years.map((year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))}
                        </select>
                      </Col>

                      <Col md={3} className="mb-2 mb-sm-0">
                        <select
                          type="button"
                          name="city"
                          value={this.state.selected_city}
                          className="btn btn-md dropdown-toggle w-100"
                          style={{ backgroundColor: "#003147", color: "white" }}
                          onChange={this.getCollegeWithCity}
                          id="district">
                          <option value={getNewDistData}>All</option>
                          {cities}
                        </select>
                      </Col>

                      <Col md={3} className="mb-2 mb-sm-0">
                        <span
                          onClick={this.handleCheckboxForView}
                          className="btn btn-secondary btn-md w-100"
                          style={{ backgroundColor: "#003147", color: "white" }}>
                          Merge College
                        </span>
                      </Col>

                      <Col md={3} className="mb-2 mb-sm-0">
                        <Link
                          to="/add-college"
                          className="btn btn-secondary w-100"
                          style={{ backgroundColor: "#003147", color: "white" }}>
                          <Add className="me-1" />
                          Add New
                        </Link>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {/* custom pagination  */}
                <Row className="mx-4 mb-5 mt-4 justify-content-end" md={12}>
                  <Col md={6} className="d-flex justify-content-end align-items-center ">
                    <span className="cordintorText fs-6">Records Per Page:</span>
                    <Form.Control
                      className="w-50 ms-1"
                      id="pageselect"
                      as="select"
                      onChange={(event) => this.hanldeRowchange(event.target.value)}
                    >
                      <option value="10">10</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                      <option value="300">300</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                    </Form.Control>
                  </Col>
                </Row>

                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div
                        className="table-responsive-sm"
                      >
                        <table className="table table-bordered table-responsive">
                          <thead>
                            <tr>
                              <th>No.</th>
                              <th>ID</th>
                              <th>College code</th>
                              <th>College name</th>
                              <th>City</th>
                              <th>Type</th>
                              <th>Status</th>
                              <th style={{ width: "120px" }}>Actions</th>
                            </tr>
                          </thead>
                          <tbody>{this.state.loader ? <LoaderRounded /> : collegeList}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="overflow-auto mt-2">
                  <ReactPaginate
                    nextLabel="Next"
                    previousLabel="Previous"
                    pageCount={this.state.allpage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    containerClassName="pagination"
                    activeClassName="btn btn-primary"
                    pageClassName="btn"
                    previousClassName="btn"
                    nextClassName="btn"
                    onPageChange={this.handlePageChange}
                  />
                </div>

                <button
                  className="reaload btn btn-success btn-sm mt-3 mb-3"
                  onClick={() => window.location.reload(true)}
                >
                  <Icon.ArrowClockwise size={18} className="me-2" />
                  Refresh
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCollegeData: (data) => dispatch(setSelectedCollege(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CollegeList);
