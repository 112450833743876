module.exports = [
    {
        ID: 1,
        Name: 'Sherya',
        College:'JSPM',
        Email:'abcd@gmail.com',
        Mobile: 9876543221,
        Address:'Moshi' ,
        DOB: '11/8/1998',
        College_id: 1064,
        Status:'Pending'
    },
    
    
    {
        ID: 4,
        Name: 'shivani',
        College:'PCCOE',
        Email:'defg@gmail.com',
        Mobile: 7841938341,
        Address:'Nigdi' ,
        DOB: '31/12/2000',
        College_id: 2274,
        Status:'Pending'
    },
    

    {
     ID: 9,
     Name: 'Deep',
     College:'MIT',
     Email:'ijkl@gmail.com',
     Mobile: 90423764301,
     Address:'Alandi' ,
     DOB: '20/6/1999',
     College_id: 1041,
     Status:'Pending'
    }
]