import React from 'react';
// import { Link } from 'react-router-dom';
import axios from 'axios';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import '../Assets/css/Dashboard.css';
// import ReactPaginate from 'react-paginate';
import BackAndBreadCumOneParent from './BackAndBreadCumOneParent';
import LoaderRounded from "./LoaderRounded";

class Eventlist extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            coordinatorlist: [],
            loader:true,
        };
        this.getData = this.getData.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.getData();
    }

    getData() {
        let currentComponent = this;
        axios.get(process.env.REACT_APP_TALK_API+"/event/get/event/details/coordinator/city").then(res => {
                let Coordinatorlist = res.data.data;

                currentComponent.setState({
                    coordinatorlist:Coordinatorlist,
                });
            });
         }

    render() {
      
        let Coordinator_list = '';
        if (this.state.coordinatorlist) {
           Coordinator_list = this.state.coordinatorlist.map(
                (coordinatorlist,index)=>(
                    <tr key={index}>
                        <td className="text-center"> {index+1} </td>
                        <td className="text-center"> {coordinatorlist.name} </td>
                        <td className="text-center">{coordinatorlist.city}</td>
                        <td>
                            <span className="circle CoordinatorCount" >
                            {coordinatorlist.count}
                            </span>
                        </td>
                    </tr>
            ))
        }
        else {
           Coordinator_list =
                <tr>
                    <td>No Data Found....</td>
                </tr>
        }

        return (
            <>
                <Sidebar />
                <div className="main">
                    <div className="topbar">
                        <Navbar/>
                    </div>

                    <div className="container">
                        <BackAndBreadCumOneParent currentTabName="Coordinator List" />
                        <div className="row">
                            <div className="col-12">
                                <h2 className="text-center">Coordinator List</h2>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <div className="container">

                        <div className="row">
                            <div className="col-12">
                                <div className="table-responsive-md">
                                    <table className="table table-bordered table-responsive">
                                        <thead>
                                            <tr className="text-center">
                                                <th>No.</th>
                                                <th>Coordinator Name</th>
                                                <th>City</th>
                                                <th>Count</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                              this.state.loader?<LoaderRounded/>: Coordinator_list
                                            }
                                        </tbody>
                                    </table>

                                </div>

                            </div>
                            <button className=" reaload btn  btn-success btn-sm " onClick={() => window.location.reload(true)} >Click to reload!</button>
                           
                        </div>
                    </div>
                </div>

            </>
        )
    }
}

export default Eventlist;


